import { Row, Typography, Col, Slider } from "antd";
import React, { useEffect, useState } from "react";

const { Text } = Typography;

const handleEllipse = (title) => {
    const ellipse = title.split(" ");
    let arr = ellipse.map((word, index) => {
        return word.length > 10 ? word.slice(0, 10) + "..." : word;
    });
    if (arr.length > 5) {
        return arr.slice(0, 5).join(" ") + "...";
    }
    return arr.join(" ");
};

const SongsList = (songs) => {
    const perChunk = 5; // items per chunk

    const result = songs.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(
            <Col span={24} key={index}>
                <Text className={`game-songs-played-text`}>{handleEllipse(item.title)}</Text>
            </Col>
        );

        return resultArray;
    }, []);
    return result.map((item) => (
        <Row style={{ minWidth: "50%", height: 120, display: 'block' }} align='top'>
            {item}
        </Row>
    ));
};
// split list into rows of 5

const GameSongsList = ({ songs }) => {
    const [index, setIndex] = useState(1);
    const [songsList, setSongsList] = useState([]);

    useEffect(() => {
        setSongsList(SongsList(songs));
    }, [songs]);
    return (
        <Row className='game-songs-list-container' justify='center'>
            <Col span={24}>
                <Col span={20} offset={2}>
                    {songsList.length > 2 && (
                        <Slider
                            min={1}
                            max={songsList.length - 1}
                            onChange={setIndex}
                            tool={{open: false}}
                            className="game-songs-list-slider"
                        />
                    )}
                </Col>
                <Row className='game-songs-list'>
                    <Col span={24} className='game-songs-played'>
                        {songsList[index - 1]}
                        {songsList[index]}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default GameSongsList;
