import React from "react";
import { Form, Input, Typography, Row, Col, Space, Button } from "antd";
import { OpenLockIcon, JindoWhiteLogo } from "../../utils/Icons";
import { Link } from "react-router-dom";
import PhoneNumberInput from "../common/PhoneNumberInput";

const { Text } = Typography;

const SignupForm = (props) => {
    const { formRef, handleSubmit, onChangeUserName } = props;

    return (
        <Space
            size={0}
            direction='vertical'
            style={{ justifyContent: "center" }}
        >
            <Row
                justify='center'
                align='middle'
                gutter={{ xs: [0, 22], lg: 0 }}
            >
                <Col style={{ position: "relative" }}>
                    <Space direction='vertical' align='center' size={0}>
                        <Col style={{ marginBottom: 32 }}>
                            <Link to='/'>
                                <JindoWhiteLogo
                                    style={{ width: 130, height: 94 }}
                                />
                            </Link>
                        </Col>
                        <Col
                            className='auth-form-sub-text'
                            style={{ margin: "12px 0" }}
                        >
                            <Text>
                                FILL OUT THE FORM BELOW TO CREATE YOUR HOST
                                ACCOUNT AND GET YOUR GAME STARTED.
                            </Text>
                        </Col>
                    </Space>
                </Col>
                <Col span={21}>
                    <Form
                        ref={formRef}
                        name='signup'
                        onFinish={handleSubmit}
                        className='signup-form'
                    >
                        <Row justify='center' align='middle'>
                            <Col span={24}>
                                <Form.Item
                                    name='name'
                                    className='custom-input'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Name",
                                        },
                                    ]}
                                >
                                    <Input placeholder='Name' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name='userName'
                                    className='custom-input'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter User Name",
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9_]*$/,
                                            message:
                                                "Username can only contain letters, numbers, and underscores",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='User Name'
                                        onChange={(event) =>
                                            onChangeUserName(event.target.value)
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name='email'
                                    className='custom-input'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Email",
                                        },
                                        {
                                            type: "email",
                                            message:
                                                "The input is not a valid E-mail!",
                                        },
                                    ]}
                                >
                                    <Input placeholder='Email' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name='phoneNumber'
                                    className='custom-input'
                                >
                                    <PhoneNumberInput
                                        onChange={(value) =>
                                            formRef.current.setFieldsValue({
                                                phoneNumber: value,
                                            })
                                        }
                                        value={formRef.current?.getFieldValue(
                                            "phoneNumber"
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <OpenLockIcon
                                    style={{
                                        width: 30,
                                        height: 35,
                                        fill: "white",
                                        marginTop: 14,
                                    }}
                                />
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name='password'
                                    className='custom-input'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Password",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Password'
                                        type='password'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{ marginTop: 15 }}>
                                <Button
                                    className='high-priority-btn'
                                    htmlType='submit'
                                >
                                    CREATE YOUR ACCOUNT
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row justify='center' align='middle'>
                <Space direction='vertical' align='center' size={25}>
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Text className='termcondition-link'>
                            By Signing up you agree to our{" "}
                            <Link to='/signup'>
                                <Text className='termcondition-link' underline>
                                    Terms & Conditions
                                </Text>
                            </Link>
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Text className='copyright-text'>
                            ©Copyright 2022 Jindo LLC
                        </Text>
                    </Col>
                </Space>
            </Row>
        </Space>
    );
};

export default SignupForm;
