import React from "react";
import { Row, Col, Table, Typography, Switch, Button } from "antd";
import moment from "moment";

const { Text } = Typography;

const columns = (onChangeAdmin, onDeleteHost, isSuperUser, onActivateHost) => {
    const tableColumns = [
        {
            title: <Text className="common-table-header-txt">Name</Text>,
            dataIndex: "firstName",
            key: "firstName",
            sorter: (a, b) => a.userName.localeCompare(b.userName),
            render: (firstName, record) => (
                <Text className="common-table-cell-text">{`${firstName} ${record.lastName}`}</Text>
            ),
        },
        {
            title: <Text className="common-table-header-txt">USER</Text>,
            dataIndex: "userName",
            key: "userName",

            sorter: (a, b) => a.userName.localeCompare(b.userName),
            render: (userName, record) => (
                <Text className="common-table-cell-text">{userName}</Text>
            ),
        },
        {
            title: <Text className="common-table-header-txt">Email</Text>,
            dataIndex: "email",
            key: "email",
            render: (email, record) => (
                <Text className="common-table-cell-text">{email}</Text>
            ),
        },
        {
            title: <Text className="common-table-header-txt">Phone</Text>,
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            render: (phoneNumber, record) => (
                <Text className="common-table-cell-text">{phoneNumber}</Text>
            ),
        },
        {
            title: <Text className="common-table-header-txt">JOIN DATE</Text>,
            dataIndex: "createdDate",
            key: "createdDate",
            align: "center",
            sorter: (a, b) =>
                new Date(
                    moment(a.createdDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                ) -
                new Date(
                    moment(b.createdDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                ),
            width: 140,
            render: (text) => {
                const [day, month, year] = text.split("-");
                return (
                    <Text className="common-table-cell-text">
                        {`${month}/${day}/${year}`}
                    </Text>
                );
            },
        },
        {
            title: (
                <Text className="account-activity-table-header-txt center-items">
                    ADMIN
                </Text>
            ),
            dataIndex: "isAdmin",
            key: "isAdmin",
            width: 120,
            sorter: (a, b) => a.isAdmin - b.isAdmin,
            render: (isAdmin, record) => (
                <Row justify="center">
                    <Col>
                        <Text className="account-activity-table-cell-txt">
                            {
                                <Switch
                                    onChange={() => onChangeAdmin(record.key)}
                                    disabled={record.deletedAt}
                                    checked={isAdmin}
                                />
                            }
                        </Text>
                    </Col>
                </Row>
            ),
        },
    ];

    if (isSuperUser) {
        tableColumns.push({
            key: "action",
            render: (isAdmin, record) =>
                (
                    <Row justify="center">
                        <Col>
                            <Button
                                className="users-manager-activate-btn"
                                onClick={() => onDeleteHost(record.userId)}
                            >
                                delete
                            </Button>
                        </Col>
                    </Row>
                ),
        });
    }
    return tableColumns;
};

const UsersManager = ({
    users,
    onChangeAdmin,
    onDeleteHost,
    isSuperUser,
    onActivateHost,
}) => {
    return (
        <Row className="common-playlist-table-container">
            <Col span={24}>
                <Table
                    className="common-playlist-table"
                    columns={columns(
                        onChangeAdmin,
                        onDeleteHost,
                        isSuperUser,
                        onActivateHost
                    )}
                    dataSource={users}
                    pagination={false}
                    sticky={true}
                />
            </Col>
        </Row>
    );
};

export default UsersManager;
