import React from "react";
import Icon from "@ant-design/icons";

import { Button, Row, Col, Typography, Spin } from "antd";

const { Text } = Typography;

const ActionButton = (props) => {
    const {
        title,
        onClick,
        isLoading = false,
        prefixIconSVG: PreFixIconSVG,
        iconSize,
        buttonPadding = '10px 28px',
        justifyRow = 'space-between',
        buttonText = null,
        textTransform = 'uppercase',
        ...rest
    } = props;
    return (
        <Spin spinning={isLoading} style={{ width: "100%" }}>
            <Button {...rest} block onClick={onClick} style={{padding: buttonPadding, height: 'auto', borderRadius: 0, textTransform}}>
                <Row justify={justifyRow}>
                    <Col className="vertical-center-items">
                        {PreFixIconSVG && (
                            <Icon
                                component={() => (
                                    <PreFixIconSVG style={iconSize} />
                                )}
                            />
                        )}
                    </Col>
                    {buttonText !== null ? buttonText : 
                        <Col className="center-items">
                            <Text className="action-btn-text">{title}</Text>
                        </Col>
                    }
                </Row>
            </Button>
        </Spin>
    );
};

export default ActionButton;
