import { useJamProvider } from '../context/collaborationContext';

/**
 * @param {string} roundId 
 */
export default function useRound(roundId) {
  const { document } = useJamProvider()

  /**
   * Sets a field in round
   * @param {string} field - The field to update in the round
   * @param {any} value  - The value to set the field with
   */
  const setFieldInRound = (field, value) => {
    const rounds = document.getMap('rounds');
    const round = rounds.get(roundId);
    rounds.set(roundId, { ...round, [field]: value });
  };

  /**
   * Gets the value of a field from a round
   * @param {string} field - The field to get from a round
   */
  const getFieldFromRound = (field) => {
    const fieldResult = document.getMap('rounds').get(roundId)
    if(fieldResult) {
      return fieldResult[field];
    }
  }

  return { setFieldInRound, getFieldFromRound };
}