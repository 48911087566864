import React from "react";
import { Col, Row, Form, Input, Button, Typography } from "antd";

const { Text } = Typography;
const UserUpdateLinks = ({ userData, handleUpdateLinks }) => {
    const [form] = Form.useForm();

    const init = {
        venmoLink: userData.venmoLink,
        googleReviewLink: userData.googleReviewLink,
        websiteLink: userData.websiteLink,
    };
    return (
        <Row>
            <Col span={24}>
                <Form
                    form={form}
                    name='update-links'
                    onFinish={handleUpdateLinks}
                    initialValues={init}
                >
                    <Row align='middle' justify='center'>
                        <Col span={24}>
                            <Text
                                className='profile-accordian-heading'
                                style={{ margin: "7px 3px", fontSize: 15 }}
                                strong
                            >
                                Link to Collect tips
                            </Text>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='venmoLink' style={{ margin: 0 }}>
                                <Input className='personal-form-input-field' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Text
                                className='profile-accordian-heading'
                                style={{ margin: "7px 3px", fontSize: 15 }}
                                strong
                            >
                                Link To Collect Reviews
                            </Text>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='googleReviewLink'
                                style={{ margin: 0 }}
                            >
                                <Input className='personal-form-input-field' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Text
                                className='profile-accordian-heading'
                                style={{ margin: "7px 3px", fontSize: 15 }}
                                strong
                            >
                                Website
                            </Text>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='websiteLink' style={{ margin: 0 }}>
                                <Input className='personal-form-input-field' />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Button
                                className='high-priority-btn sm-btn'
                                htmlType='submit'
                                style={{
                                    fontSize: 13,
                                    borderRadius: 5,
                                    marginTop: 12,
                                }}
                                block
                            >
                                save info
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

export default UserUpdateLinks;
