import {
    GAME_STARTED,
    PLAYER_JOINED,
    CARD_CLICKED,
    CHECK_FOR_WINNER,
    ADD_JINDO_CALL_LIST,
    REMOVE_JINDO_CALL_LIST,
    GAME_FINISHED,
    RESET_STATE_HOST_EVENTS,
} from "../actionTypes/hostEvents";

const initialState = {
    playersCalledJindo: null,
    gameFinished: false,
    winnerName: "",
};

const hostEvents = (state = initialState, action) => {
    switch (action.type) {
        case ADD_JINDO_CALL_LIST:
            return {
                ...state,
                playersCalledJindo: action.payload,
            };
        case REMOVE_JINDO_CALL_LIST:
            return {
                ...state,
                playersCalledJindo: null,
            };
        case GAME_FINISHED:
            return {
                ...state,
                gameFinished: action.payload.isGameFinished,
                winnerName: action.payload.playerName,
            };
        case RESET_STATE_HOST_EVENTS:
            return initialState;
        default:
            return state;
    }
};

export default hostEvents;
