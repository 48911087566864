import React from "react";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../redux/actions/authUser";
import ResendForgetPassword from "../../components/auth/ResendForgetPassword";

const ResendForgetPasswordContainer = () => {
    const dispatch = useDispatch();
    const email = window.location.search.split("=")[1];

    const onFormSubmit = () => {
        try {
            if (email) {
                dispatch(resetPassword({ email }));
                ShowToast('Email Sent!', "success");
            } else throw new Error("Email is required");
        } catch (error) {
            ShowToast(error.message, "error");
        }
    };

    return (
        <Row
            justify='center'
            style={{ marginTop: 80 }}
            className='action-container-wrapper'
        >
            <Col span={23}>
                <ResendForgetPassword onSubmit={onFormSubmit} />
            </Col>
        </Row>
    );
};

export default ResendForgetPasswordContainer;
