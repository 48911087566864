import * as Sentry from "@sentry/react";
import {
    INITIALIZE_PLAYER_REQUREST,
    FAILED_PLAYER_REQUEST,
    REQUEST_PLAYER_INFO,
    RESET_PLAYER_INFO,
    FETCHED_PLAYER_INFO
} from "../actionTypes/player";

import PlayerService from "../../services/PlayerService";
import GameService from "../../services/GameService";

const initializePlayerRequest = () => {
    return {
        type: INITIALIZE_PLAYER_REQUREST,
    };
};

const failedPlayerRequest = (payload) => {
    return {
        type: FAILED_PLAYER_REQUEST,
        payload,
    };
};

export const resetPlayer = ()=>{
    return {
        type: RESET_PLAYER_INFO
    }
}

const requestPlayerInfo = () => {
    return {
        type: REQUEST_PLAYER_INFO,
    }
}

const successPlayerInfo = (payload) => {
    return {
        type: FETCHED_PLAYER_INFO,
        payload
    }
}

export const playerCreate = (playerInputData) => {
    return async (dispatch) => {
        try {
            dispatch(initializePlayerRequest());
            const { data } = await PlayerService.playerCreate(playerInputData);
            localStorage.setItem("player-data", JSON.stringify(data.player));
            return true
        } catch (error) {
            dispatch(failedPlayerRequest());
            Sentry.captureException(error);
        }
    };
}

export const fetchPlayerInfo = (payload) => {
    return async (dispatch) => {
        try{
            dispatch(requestPlayerInfo());
            const { data } = await GameService.fetchGamePlayer(payload);
            dispatch(successPlayerInfo(data));
        } catch (error) {
            Sentry.captureException(error);
        }
    }
}