import { Table, Typography } from "antd";
import React from "react";
import { JamIcon, PlayersIcon, ViewIcon } from "../../utils/Icons";

const { Text } = Typography;
const dummyData = [
    {
        key: "1",
        jams: 38,
        numberOfPlayers: 1920,
        averageTime: "36:30",
    },
];

const UserStatsTable = () => {
    const columns = [
        {
            title: <JamIcon className="stats-table-icon" />,
            key: "jams",
            render: (text) => {
                return <Text className="stats-table-text">{text.jams}</Text>;
            },
        },
        {
            title: <PlayersIcon className="stats-table-icon" />,
            key: "numberOfPlayers",
            render: (text) => <Text className="stats-table-text">{"34"}</Text>,
        },
        {
            title: (
                <Text className="stats-table-header-text">AVG JAM TIME</Text>
            ),
            key: "averageTime",
            render: (text) => <Text className="stats-table-text">{"34"}</Text>,
        },
        {
            title: <ViewIcon className="stats-table-icon" />,
            key: "action",
            render: () => (
                <ViewIcon
                    className="button-icon"
                    onClick={() => console.log("wokring")}
                />
            ),
        },
    ];
    return (
        <Table
            className="common-playlist-table user-stats-table"
            dataSource={dummyData}
            columns={columns}
            pagination={false}
        />
    );
};

export default UserStatsTable;
