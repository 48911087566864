import {useEffect, useState} from "react";
import useRounds from './useRounds';
import {useJamProvider} from "../context/collaborationContext";
import jsonPatterns from '../assets/winningPatterns';
import GameService from '../services/GameService';

const { winningPatterns } = jsonPatterns

export default function useActiveGame() {
    const { rounds } = useRounds()
    const jamProvider = useJamProvider()
    const [activeGame, _setActiveGame] = useState(null)

    useEffect(() => {

      const updateActiveGame = () => {
        
        const rounds = Array.from(jamProvider.document.getMap('rounds').values())
        
        // find the `STARTED` round
        const startedRound = rounds.find((round) => round.status === 'STARTED')
        if (startedRound) {
            startedRound.winningPattern.pattern = winningPatterns
                    .filter((pattern) => pattern.name === startedRound.winningPattern?.name)
                    .map((item) => item.pattern)
            _setActiveGame(startedRound)
        } else {
            const startingSoonRound = rounds.find((round) => round.status === 'STARTING_SOON') 
            if (startingSoonRound) {
                _setActiveGame(startingSoonRound)
            }
        }
      }

      // update active game when rounds are updated
      updateActiveGame()
    }, [rounds])

    const nextSong = (playlist) => {
        const roundsMap = jamProvider.document.getMap('rounds')
        if(activeGame?.playedSongs?.currentSong?.hasOwnProperty("show") && !activeGame.playedSongs.currentSong.show) {
            return activeGame.playedSongs.currentSong;
        }
        const playedSongIds = activeGame.playedSongs.playedSongs.map(playedSong => playedSong.songId);
        const availableSongs = playlist.filter(playlistSong => !playedSongIds.includes(playlistSong.songId));
        if(availableSongs.length > 0) {
            const availableSong = {...availableSongs[0], show: false}
            activeGame.playedSongs.playedSongs.unshift(availableSong);
            activeGame.playedSongs.currentSong = availableSong;
            const gameWinner = isWinnerExists(activeGame?.gameId);
            if (!gameWinner) {
                roundsMap.set(activeGame.key.toString(), activeGame)
                return availableSong;
            }
        } else {
            setGameWinner({isFinished: true, name: "", winnerId: "", gameId: activeGame?.gameId})
            roundsMap.set(activeGame.key.toString(), activeGame)
        }
    }

    const isWinnerExists = (gameId) => {
        const gameWinnerMap = jamProvider.document.getMap('gameWinner');
        const gameWinner = Object.fromEntries(gameWinnerMap.entries());
        if(gameWinner?.winner?.name && gameWinner?.winner?.gameId === gameId) {
            return gameWinner?.winner;
        }
        return false;
    }

    const displaySong = () => {
        const roundsMap = jamProvider.document.getMap('rounds')
        const currentSong = {...activeGame.playedSongs.currentSong, show: true};
        const playedSongs = activeGame.playedSongs.playedSongs.map(song => {
            song.show = true;
            return song;
        });
        activeGame.playedSongs = {currentSong, playedSongs};
        roundsMap.set(activeGame.key.toString(), activeGame)
    }

    const nextRound = async (gameWinner, onlyApi = false) => {
        const roundsMap = jamProvider.document.getMap('rounds')
        activeGame.status = 'ENDED';
        activeGame.winnerName = gameWinner?.name;
        activeGame.isFinished = gameWinner?.isFinished || false;
        activeGame.winnerId = gameWinner?.winnerId;

        await GameService.updateGame(activeGame);

        if (!onlyApi) {
            roundsMap.set(activeGame.key.toString(), activeGame)
        }
        resetGameWinner({})
    }

    const resetGameWinner = (value) => {
        const jamMap = jamProvider.document.getMap('gameWinner')
        jamMap.set('winner', value)
    }

    const setGameWinner = (value) => {
        const jamMap = jamProvider.document.getMap('gameWinner')
        jamMap.set('winner', value)
    }

    return { activeGame, nextSong, displaySong, nextRound }
}