import React from "react";
import { Col, Row, Typography } from "antd";

import { AuthIcon } from "../../utils/Icons";
import BackButton from "../common/BackButton";
import UserStatsTable from "./UserStatsTable";

const { Text } = Typography;

const AdminHome = ({ onLogout }) => {
    return (
        <Row justify="space-between" className="admin-home-container">
            <Col>
                <BackButton />
            </Col>
            <Col>
                <AuthIcon
                    onClick={onLogout}
                    className="app-close-header-icon"
                />
            </Col>
            <Col span={24}>
                <Row justify="space-between" className="home-stats">
                    <Col span={10}>
                        <Row
                            justify="center"
                            className="stats-home-heading-container"
                        >
                            <Col>
                                <Text className="stats-home-heading">
                                    Active
                                </Text>
                            </Col>
                        </Row>
                        <UserStatsTable />
                    </Col>
                    <Col span={10}>
                        <Row
                            justify="center"
                            className="stats-home-heading-container"
                        >
                            <Col>
                                <Text className="stats-home-heading">
                                    STATS
                                </Text>
                            </Col>
                        </Row>
                        <UserStatsTable />
                        <UserStatsTable />
                        <UserStatsTable />
                        <UserStatsTable />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default AdminHome;
