import React from "react";
import { Row, Col, Typography, Input, Space, Button } from "antd";
import ReactCodeInput from "react-code-input";

import { JindoWhiteLogo } from "../../utils/Icons";

const { Text } = Typography;

const ConfirmCodeForm = ({ onCodeInput, onCodeSubmit, handleResendCode }) => {
    return (
        <Space
            size={78}
            direction='vertical'
            style={{ justifyContent: "center" }}
        >
            <Row justify='center' align='middle' gutter={[0, 33]}>
                <Col>
                    <Space direction='vertical' align='center' size={74}>
                        <Col>
                            <JindoWhiteLogo
                                style={{ width: 130, height: 94 }}
                            />
                        </Col>
                        <Col className='code-form-sub-text'>
                            <Text>Thank you for signing up</Text>
                        </Col>
                    </Space>
                </Col>
                <Col style={{ marginTop: 25 }}>
                    <Row justify='center'>
                        <Col className='code-form-helper-text'>
                            <Text>
                                Enter the confirmation code sent to your phone
                                to verify your account and start jamming!
                            </Text>
                        </Col>
                        <Col className='code-input-container'>
                            <ReactCodeInput
                                type='text'
                                fields={4}
                                onChange={onCodeInput}
                                inputStyle={{
                                    width: 46,
                                    height: 46,
                                    borderRadius: 5,
                                    border: "none",
                                    margin: "12px 8px",
                                    fontSize: 21,
                                    textAlign: "center",
                                    color: "#FF14BD",
                                    outline: "none",
                                }}
                            />
                        </Col>
                        <Col span={24} className='confirm-code-btn-container'>
                            <Space>
                                <Button
                                    style={{ fontSize: 12 }}
                                    className='transparent-btn'
                                    onClick={handleResendCode}
                                >
                                    resend code
                                </Button>
                                <Button
                                    onClick={onCodeSubmit}
                                    style={{
                                        fontSize: "12.1636px",
                                        borderRadius: "5.52891px",
                                    }}
                                    className='high-priority-btn sm-btn'
                                >
                                    Verify code
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Space>
    );
};

export default ConfirmCodeForm;
