import React from "react";
import {
    Col,
    Row,
    Form,
    Input,
    Button,
    Typography,
    Divider,
    DatePicker,
    InputNumber,
} from "antd";

const { Text } = Typography;

const UserPersonalForm = ({ userPersonalForm, onSaveUser }) => {

    return (
        <Row>
            <Col span={24}>
                <Form
                    form={userPersonalForm}
                    name='personal-info'
                    autoComplete='off'
                    onFinish={onSaveUser}
                >
                    <Row align='middle' justify='center'>
                        <Col span={8}>
                            <Text className='profile-accordian-heading'>
                                User Name
                            </Text>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                name='userName'
                                style={{margin: 0}}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter User Name",
                                    },
                                    {
                                        pattern: /^[a-zA-Z0-9_]*$/,
                                        message: "Username can only contain letters, numbers, and underscores",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='User Name'
                                    className='personal-form-input-field'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Divider
                                style={{
                                    margin: 0,
                                    borderColor: "#ffffff80",
                                }}
                            />
                        </Col>

                        <Col span={8}>
                            <Text className='profile-accordian-heading'>
                                Email
                            </Text>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                name='email'
                                style={{margin: 0}}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Email",
                                    },
                                    {
                                        type: "email",
                                        message:
                                            "The input is not a valid E-mail!",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Email'
                                    type='email'
                                    className='personal-form-input-field'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Divider
                                style={{
                                    margin: 0,
                                    borderColor: "#ffffff80",
                                }}
                            />
                        </Col>

                        <Col span={8}>
                            <Text className='profile-accordian-heading'>
                                Date of Birth
                            </Text>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                name='dob'
                                style={{margin: 0}}
                            >
                                <DatePicker
                                    placeholder='Date of Birth'
                                    className='personal-form-input-field'
                                    suffixIcon={null}
                                    format='MM / DD / YYYY'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Divider
                                style={{
                                    margin: 0,
                                    borderColor: "#ffffff80",
                                }}
                            />
                        </Col>

                        <Col span={8}>
                            <Text className='profile-accordian-heading'>
                                Country/Region
                            </Text>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                name='country'
                                style={{margin: 0}}
                            >
                                <Input
                                    placeholder='Country / Region'
                                    className='personal-form-input-field'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Divider
                                style={{
                                    margin: 0,
                                    borderColor: "#ffffff80",
                                }}
                            />
                        </Col>

                        <Col span={8}>
                            <Text className='profile-accordian-heading'>
                                City/State/ZIP
                            </Text>
                        </Col>
                        <Col span={16}>
                            <Row align='middle' gutter={[2]}>
                                <Col span={12}>
                                    <Form.Item
                                        name='city'
                                        style={{margin: 0}}
                                    >
                                        <Input
                                            placeholder='City'
                                            className='personal-form-input-field'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name='state'
                                        style={{margin: 0}}
                                    >
                                        <Input
                                            placeholder='State'
                                            className='personal-form-input-field'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name='zipCode'
                                        style={{margin: 0}}
                                    >
                                        <InputNumber
                                            placeholder='ZIP'
                                            className='personal-form-input-field'
                                            controls={false}
                                            maxLength={5}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={10}>
                            <Button
                                className='high-priority-btn sm-btn'
                                htmlType='submit'
                                style={{
                                    fontSize: 13,
                                    borderRadius: 5,
                                }}
                                block
                            >
                                save info
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

export default UserPersonalForm;
