import React from "react";
import { Row, Col, Typography, Space, Divider, Button } from "antd";
import {
    JindoWhiteWithSplashIcon,
    PlayersJoinedIcon,
    Speed15sIcon,
    Speed30sIcon,
    TimeIcon,
} from "../../../utils/Icons";
import TilePatternGrid from "../../common/TilePatternGrid";

const { Text } = Typography;

const getSuffix = (num) => {
    if (num > 3) {
        return "th";
    } else if (num === 1) {
        return "st";
    } else if (num === 2) {
        return "nd";
    } else if (num === 3) {
        return "rd";
    } else {
        return "";
    }
};

const preGame = (props) => {
    const { gameInfo, title, numOfPlayers, handleInviteMoreFriend } = props;
    return (
        <Row justify='center' align='middle'>
            {/* <Col span={24} style={{ textAlign: "center" }}>
                <JindoWhiteWithSplashIcon style={{ width: 200, height: 175 }} />
            </Col> */}
            <Col span={22} style={{ marginTop: 45 }}>
                <Text className='game-header-text'>
                    THE NEXT ROUND IS ABOUT TO BEGIN!
                </Text>
            </Col>
            <Col span={24} style={{ marginTop: 20 }}>
                <Row justify='center'>
                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-category'>
                            players joined
                        </Text>
                    </Col>
                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-content'>
                            <PlayersJoinedIcon
                                style={{ width: 40, height: 40 }}
                            />
                            <Text 
                                className='game-info-players-count' 
                                style={{fontSize: numOfPlayers < 10 ? 23 : numOfPlayers > 99 ? 13 : 18}}
                            >
                                {numOfPlayers || 0}
                            </Text>
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Divider className='game-info-section-divider' />
                    </Col>

                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-category'>
                            EST. ROUND TIME
                        </Text>
                    </Col>
                    <Col span={10} className='game-info-section'>
                        <Space>
                            <Text className='game-info-content'>
                                <TimeIcon
                                    className='game-info-content-icon'
                                    style={{ width: 30, height: 30 }}
                                />
                            </Text>
                            <Text
                                className='game-info-content'
                                style={{
                                    textTransform: "lowercase",
                                    fontSize: 18,
                                    fontStyle: "normal",
                                }}
                            >
                                {gameInfo?.estGameTime}m
                            </Text>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Divider className='game-info-section-divider' />
                    </Col>

                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-category'>
                            SONGS PLAY FOR
                        </Text>
                    </Col>
                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-content'>
                            {gameInfo?.pace === 15 ? (
                                <Speed15sIcon className='game-info-content-icon' />
                            ) : (
                                <Speed30sIcon className='game-info-content-icon' />
                            )}
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Divider className='game-info-section-divider' />
                    </Col>

                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-category'>
                            SONG TITLES WILL
                        </Text>
                    </Col>
                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-content'>
                            {gameInfo?.displaySong ? "display" : "hide"}
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Divider className='game-info-section-divider' />
                    </Col>

                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-category'>
                            MAX # OF SONGS
                        </Text>
                    </Col>
                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-content'>
                            {gameInfo?.numberOfSongs}
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Divider className='game-info-section-divider' />
                    </Col>

                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-category'>
                            RD {gameInfo?.key + 1} PATTERN TO WIN IS:
                        </Text>
                    </Col>
                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-content'>
                            <TilePatternGrid
                                pattern={gameInfo?.pattern}
                                color='white'
                            />
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Divider className='game-info-section-divider' />
                    </Col>

                    <Col span={10} className='game-info-section'>
                        <Text className='game-info-category'>
                            {gameInfo?.key != null ? gameInfo?.key + 1 : 0}
                            {gameInfo?.key != null ? getSuffix(gameInfo?.key + 1) : getSuffix(0)} ROUND PRIZE
                        </Text>
                    </Col>
                    <Col span={10} className='game-info-section'>
                        <Text
                            className='game-info-content'
                            style={{
                                textTransform: "lowercase",
                                fontSize: 15,
                            }}
                        >
                            {gameInfo?.prize}
                        </Text>
                    </Col>
                    <Col span={22}>
                        <Button
                            className='game-info-secondary-btn'
                            block
                            onClick={handleInviteMoreFriend}
                            disabled={Object.keys(gameInfo).length === 0}
                        >
                            INVITE MORE PLAYERS
                        </Button>
                    </Col>
                    <Col span={20}>
                        <Text className='game-info-footer-text'>
                            Once all players have joined the countdown will
                            begin and you will see your game card.
                        </Text>
                    </Col>
                    <Col>
                        <Text
                            className='game-info-footer-text'
                            style={{ fontWeight: 800, marginTop: 5 }}
                        >
                            Don’t Forget The Free Space!
                        </Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default preGame;
