import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Typography, Space } from "antd";
import { BigRedSplashIcon } from "../../utils/Icons";
import MUSIC_QUOTES from "../../utils/musicQuotes";

const { Text } = Typography;

const CountDownModal = ({ visible, updateCDModalState, isHost }) => {
    const [counter, setCounter] = useState(10);
    const [modalVisible, setIsModalVisibile] = useState(visible);
    const [musicQuote, setMusicQuote] = useState(MUSIC_QUOTES[0].split("/atn")[0]);
    const [authorName, setAuthorName] = useState(MUSIC_QUOTES[0].split("/atn")[1]);

    useEffect(() => {
        let quote =
            MUSIC_QUOTES[Math.floor(Math.random() * MUSIC_QUOTES.length)].split("/atn");
        setMusicQuote(quote[0]);
        setAuthorName(quote[1])
    }, []);

    useEffect(() => {
        let timer;
        let timer1;
        if (counter > 1 && visible) {
            timer = setTimeout(() => setCounter(counter - 1), 1900);
            
            setIsModalVisibile(true);
        } else {
            localStorage.setItem("countdownModal", "false");
            if(isHost) {
                timer1 = setTimeout(() => {
                    setCounter(10);
                }, 4900);
            } else {
                updateCDModalState();
                setCounter(10);
            }
            
            setIsModalVisibile(false);
        }
        return () => {
            clearTimeout(timer);
            clearTimeout(timer1);
        };
    }, [counter, visible]);

    return (
        <Modal
            className='game-splash-modal'
            open={modalVisible}
            closable={false}
            footer={false}
            mask={counter === 1 ? false : true}
            destroyOnClose={true}
        >
            <Row justify='center' align='bottom'>
                <Col
                    span={24}
                    className='game-splash-title-container'
                    style={{ top: 0 }}
                >
                    <Row
                        justify='center'
                        align='middle'
                        className='game-play-splash-component'
                    >
                        <Col span={24}>
                            <BigRedSplashIcon
                                className='message-splash'
                                style={{
                                    opacity:
                                        counter > 0
                                            ? counter === 1
                                                ? 0
                                                : counter * 0.1
                                            : 1,
                                }}
                            />
                        </Col>
                        <Col className='game-play-splash-container' span={20}>
                            <Space
                                direction='vertical'
                                style={{ textAlign: "center" }}
                                size={8}
                            >
                                <Text className={"message-splash-header-text"}>
                                    GET READY!
                                </Text>
                                <Text className={"message-splash-header-text"}>
                                    THE JAM IS ABOUT
                                </Text>
                                <Text className={"message-splash-header-text"}>
                                    TO BEGIN IN...
                                </Text>
                                <Text
                                    className={"message-splash-text"}
                                    style={{ fontSize: 168 }}
                                >
                                    {counter}
                                </Text>
                                <Text className={"message-splash-helper-text"}>
                                    {musicQuote}
                                </Text>
                                <Text className={"message-splash-helper-text"}>
                                    {authorName}
                                </Text>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default CountDownModal;
