import Http from "./HttpService";

export default class GameService {
    static async fetchGames(jamId) {
        return await Http.post("/game/jamGames", {jamId}, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async updateGames(gamesInputData) {
        return await Http.post("/game/add", gamesInputData, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async updateGame(gameInputData){
        return await Http.put("/game/update", gameInputData, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }


    static async fetchActiveGame(activeGameInput) {
        return await Http.post("/game/active", activeGameInput, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async fetchGamePlayersInfo(gameId) {
        return await Http.post(`/game/players`, {gameId},{
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async fetchGamePlayer(payload) {
        return await Http.post(`/game/player`, payload, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async fetchGamePlayerInfo(payload) {
        return await Http.post(`/game/playerInfo`, payload, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async gameWinner(data) {
        return await Http.post("game/gameWinner", data, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async verifyWinner(data) {
        return await Http.post("game/verifyWinner", data);
    }

    static async numOfPlayers(gameId) {
        return await Http.post("game/numOfPlayers", gameId, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }
}