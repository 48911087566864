import React, { Suspense, Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Row } from "antd";

import GuestRoute from "./routes/GuestRoute";
import AuthenticatedRoute from "./routes/AuthenticatedRoute";

//Auth Screens
import LoginContainer from "./container/auth/login/LoginContainer";
import SignupContainer from "./container/auth/signup/SignupContainer";
import ConfirmCodeContainer from "./container/auth/signup/ConfirmCodeContainer";

import ForgetPasswordFormContainer from "./container/auth/ForgetPasswordFormContainer";
import ResendForgetPasswordContainer from "./container/auth/ResendForgetPasswordContainer";
import UpdatePasswordFormContainer from "./container/auth/UpdatePasswordFormContainer";
import LogoutContainer from "./container/auth/LogoutContainer";

//Host Screens
import JamDeckProvider from "./container/host/jamDeck/JamDeckProvider";
import JamEndContainer from "./container/host/jamEnd/JamEndContainer";

//Players Screens
import GameCodeContainer from "./container/player/gameCode/GameCodeContainer";
import GameJamEndContainer from "./container/player/gameJamEnd/GameJamEndContainer";
import InviteFriendsContainer from "./container/player/inviteFriends/InviteFriendsContainer";
import JamCodeContainer from "./container/player/jamCode/JamCodeContainer";
import PostGameContainer from "./container/player/postGame/PostGameContainer";
import GameProvider from "./container/player/game/GameProvider";

// Admin screens
import AdminContainer from "./container/admin/AdminContainer";
import PlaylistCreateContainer from "./container/admin/playlist/PlaylistCreateContainer";
import PlaylistTableContainer from "./container/admin/playlist/PlaylistTableContainer";
import PlaylistEditContainer from "./container/admin/playlist/PlaylistEditContainer";

//Profile Screens
import UserHomeContainer from "./container/userHome/UserHomeContainer";
import UserProfileHomeContainer from "./container/userHome/UserProfileHomeContainer";
import ContactusSentContainer from "./container/contactUs/ContactUsSentContainer";

class App extends Component {
    render() {
        return (
            <Router>
                <Suspense fallback={() => <Row>loading...</Row>}>
                    <Routes>
                        <Route
                            path='/'
                            exact
                            element={<GuestRoute component={LoginContainer} />}
                        />
                        <Route
                            path='/login'
                            exact
                            element={<GuestRoute component={LoginContainer} />}
                        />
                        <Route
                            path='/signup'
                            exact
                            element={<GuestRoute component={SignupContainer} />}
                        />
                        <Route
                            path='/confirmCode'
                            exact
                            element={
                                <GuestRoute component={ConfirmCodeContainer} />
                            }
                        />
                        <Route
                            path='/forgetPassword'
                            exact
                            element={
                                <GuestRoute
                                    component={ForgetPasswordFormContainer}
                                />
                            }
                        />
                        <Route
                            path='/resendForgetPassword'
                            exact
                            element={
                                <GuestRoute
                                    component={ResendForgetPasswordContainer}
                                />
                            }
                        />
                        <Route
                            path='/updatePasswordForm/verify'
                            exact
                            element={
                                <GuestRoute
                                    component={UpdatePasswordFormContainer}
                                />
                            }
                        />
                        <Route
                            path='/logout'
                            exact
                            element={
                                <AuthenticatedRoute
                                    component={LogoutContainer}
                                />
                            }
                        />
                        <Route
                            path='/jamDeckEnd'
                            exact
                            element={
                                <GuestRoute
                                    component={JamEndContainer}
                                    playerRoute={true}
                                />
                            }
                        />
                        <Route
                            path='/jamDeck'
                            exact
                            element={
                                <AuthenticatedRoute
                                    component={JamDeckProvider}
                                    isJamDeckRoute={true}
                                />
                            }
                        />
                        {/* <Route
                            path='/jamType'
                            exact
                            element={
                                <AuthenticatedRoute
                                    component={JamTypeContainer}
                                />
                            }
                        /> */}
                        <Route
                            path='/jamcode'
                            exact
                            element={
                                <GuestRoute
                                    component={GameCodeContainer}
                                    playerRoute={true}
                                />
                            }
                        />
                        <Route
                            path='/:userName'
                            exact
                            element={
                                <GuestRoute component={GameProvider} playerRoute={true} />
                            }
                        />
                        {/* <Route
                            path='/gamePlay'
                            exact
                            element={
                                <GuestRoute
                                    component={GamePlayContainer}
                                    playerRoute={true}
                                />
                            }
                        /> */}
                        <Route
                            path='/inviteFriends'
                            exact
                            element={
                                <GuestRoute
                                    component={InviteFriendsContainer}
                                    playerRoute={true}
                                />
                            }
                        />
                        <Route
                            path='/playerSetup'
                            exact
                            element={
                                <GuestRoute component={JamCodeContainer} playerRoute={true} />
                            }
                        />
                        <Route
                            path='/jamEnd'
                            exact
                            element={
                                <GuestRoute component={GameJamEndContainer} playerRoute={true} />
                            }
                        />
                        <Route
                            path='/postGame'
                            exact
                            element={
                                <GuestRoute component={PostGameContainer} playerRoute={true} />
                            }
                        />
                        <Route
                            path='/userHome'
                            exact
                            element={
                                <AuthenticatedRoute
                                    component={UserHomeContainer}
                                />
                            }
                        />
                        <Route
                            path='/profile'
                            exact
                            element={
                                <AuthenticatedRoute
                                    component={UserProfileHomeContainer}
                                />
                            }
                        />
                        <Route
                            path='/contactus/sent'
                            exact
                            element={
                                <AuthenticatedRoute
                                    component={ContactusSentContainer}
                                />
                            }
                        />
                        <Route
                            path='/admin'
                            exact
                            element={
                                <AuthenticatedRoute
                                    component={AdminContainer}
                                />
                            }
                        />
                        {/* <Route
                            path='/admin/playlist/create'
                            exact
                            element={
                                <AuthenticatedRoute
                                    component={PlaylistCreateContainer}
                                />
                            }
                        /> */}

                        {/* <Route
                            path='/admin/playlist'
                            exact
                            element={
                                <AuthenticatedRoute
                                    component={PlaylistTableContainer}
                                />
                            }
                        /> */}

                        {/* <Route
                            path='/admin/playlist/edit'
                            exact
                            element={
                                <AuthenticatedRoute
                                    component={PlaylistEditContainer}
                                />
                            }
                        /> */}
                    </Routes>
                </Suspense>
            </Router>
        );
    }
}

export default App;
