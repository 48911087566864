import React from "react";
import { Row, Col, Typography, Space } from "antd";
import ANIMATED_CLASSES from "../../../utils/animatedClasses";

const { Text } = Typography;

const getVisibility = () => {
    // if (time === 0) {
    //     return "game-tile-grid-hidden";
    // } else if (time === 1 || time === 2) {
    //     return "game-tile-grid game-tile-grid-low-opacity";
    // } else 
    return "game-tile-grid";
};

const getRandomSplash = (index) => {
    if (index) {
        const randNumber = (index % 11) + 1;
        return `url(/src/assets/media/sploch-${randNumber}.svg)`;
    }
};

const handleEllipse = (title) => {
    const ellipse = title.split(" ");
    let arr = ellipse.map((word, index) => {
        return word.length > 10 ? word.slice(0, 10) + "..." : word;
    });
    if (arr.length > 5) {
        return arr.slice(0, 5).join(" ") + "...";
    }
    return arr.join(" ");
};

const renderSongsGrid = (songsList, handleTileSelect, animate) => {
    let selectSoundEffect = new Audio(
        `${process.env.SOUND_EFFECTS_URL}card-select.mp3`
    );
    let unSelectSoundEffect = new Audio(
        `${process.env.SOUND_EFFECTS_URL}card-deselect.mp3`
    );

    let songsGrid = [];

    const onClickCard = (selected, songId) => {
        selected ? unSelectSoundEffect.play() : selectSoundEffect.play();
        handleTileSelect(songId);
    }

    for (let index = 0; index < songsList.length; ) {
        songsGrid.push(
            <Col key={index}>
                <Row
                    key={index}
                    // style={{ overflow: "hidden" }}
                    // justify="space-between"
                    align='top'
                    gutter={[8, 0]}
                >
                    {Array.from({ length: 5 }, (_, k) => {
                        // console.log(index, songsList[index], songsList[index].title);
                        const { title, selected, countClicked, songId } = songsList[index++];
                        if (k === 2 && index === 13)
                            return (
                                <Col
                                    key={k}
                                    onClick={() => onClickCard(selected, songId)}
                                >
                                    <Space
                                        className={`game-tile ${
                                            selected
                                                ? "game-tile-selected animate__animated animate__bounceIn"
                                                : "animate__animated animate__zoomIn"
                                        }`}
                                        style={{
                                            backgroundImage:
                                                index &&
                                                selected &&
                                                getRandomSplash(
                                                    index + countClicked
                                                ),
                                        }}
                                    >
                                        <Text
                                            className={`game-tile-text game-tile-free ${
                                                selected
                                                    ? "game-tile-free-selected"
                                                    : ""
                                            }`}
                                        >
                                            {"FREE"}
                                        </Text>
                                    </Space>
                                </Col>
                            );
                        return (
                            <Col
                                key={k}
                                onClick={() => onClickCard(selected, songId)}
                            >
                                <Space
                                    className={
                                        selected
                                            ? `game-tile game-tile-selected animate__animated ${
                                                  !animate
                                                      ? "animate__bounceIn"
                                                      : ""
                                              } ${
                                                  !animate
                                                      ? ""
                                                      : ANIMATED_CLASSES[
                                                            Math.floor(
                                                                Math.random() *
                                                                    ANIMATED_CLASSES.length
                                                            )
                                                        ]
                                              }`
                                            : `game-tile animate__animated ${
                                                  !animate
                                                      ? "animate__wobble"
                                                      : ""
                                              } ${
                                                  !animate
                                                      ? ""
                                                      : ANIMATED_CLASSES[
                                                            Math.floor(
                                                                index %
                                                                    ANIMATED_CLASSES.length
                                                            )
                                                        ]
                                              }`
                                    }
                                    style={{
                                        backgroundImage:
                                            index &&
                                            selected &&
                                            getRandomSplash(
                                                index + countClicked
                                            ),
                                    }}
                                >
                                    <Text
                                        className={`game-tile-text ${
                                            selected
                                                ? "game-tile-text-selected"
                                                : ""
                                        }`}
                                    >
                                        {handleEllipse(title)}
                                    </Text>
                                </Space>
                            </Col>
                        );
                    })}
                </Row>
            </Col>
        );
    }
    return songsGrid;
};

const GameGrid = (props) => {
    const { songsList, handleTileSelect, animate } = props;

    return (
        <Row
            className={getVisibility()}
            justify='center'
            align='top'
            gutter={[0, 8]}
        >
            {renderSongsGrid(
                songsList,
                handleTileSelect,
                animate
            )}
        </Row>
    );
};

export default GameGrid;
