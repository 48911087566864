import React, { useEffect } from "react";
import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";

import UserHomeContent from "../../components/userHome/UserHomeContent";
import { fetchUser, updateUserImage } from "../../redux/actions/user";
import ShowToast from "../../utils/ShowToast";
import { fetchJamId } from "../../redux/actions/Jam";

const UserHomeContainer = ({ navigate, loggedIn }) => {
    const dispatch = useDispatch();

    const { userData, isLoading, error, isError, isUpdated } = useSelector(
        (state) => state.user
    );

    useEffect(() => {
        dispatch(fetchUser());
    }, []);

    useEffect(() => {
        if (isError) {
            ShowToast(
                typeof error === "string" ? error : "Update Failed!",
                "error",
                null,
                2
            );
        }
        if (isUpdated) {
            ShowToast("Updated successfully!", "success", null, 2);
        }
    }, [isError, isUpdated]);

    const handleUpdateUserImage = async (image) => {
        dispatch(updateUserImage(image));
    };

    const handleCreateJam = async () => {
        try {
            
            const response = await fetchJamId("IN-PERSON");
            if (response && response.hasOwnProperty("jamId")) {
                if (response.jamType != "STREAMING") {
                    window.location = `/jamDeck/#${response.jamId}`;
                    return;
                } else {
                    ShowToast(
                        `You Already Have STREAMING Active Jam`,
                        "error",
                        null,
                        2
                    );
                    return;
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Row
            justify='center'
            align='stretch'
            className='action-container-wrapper'
            style={{ width: 375 }}
        >
            <UserHomeContent
                onHost={handleCreateJam}
                onAdmin={() => navigate("/admin")}
                onPlay={() => navigate("/jamcode")}
                onProfile={() => navigate("/profile")}
                handleGetHelp={() =>
                    window.open("https://www.playjindo.com/contact")
                }
                isAdmin={loggedIn && loggedIn.isAdmin}
                profileImage={
                    userData?.profileImage ? userData?.profileImage : ""
                }
                handleUpdateUserImage={handleUpdateUserImage}
                isLoading={isLoading}
            />
        </Row>
    );
};

export default UserHomeContainer;
