import {
    INITIALIZE_GAME_REQUREST,
    FETCHED_GAMES,
    FAILED_GAME_REQUEST,
    FETCHED_ACTIVE_GAME,
    RESET_GAME,
    FETCHED_ACTIVE_GAME_PLAYERS,
    FETCHED_ACTIVE_GAME_PLAYER_INFO,
    GO_TO_NEXT_GAME,
    JAM_ENDED,
    NUMBER_OF_PLAYERS,
    RESET_ACTIVE_GAME,
    IS_GAME_PAUSED,
    IS_GAME_RESUMED,
    CURRENT_SONG_FINISHED,
} from "../actionTypes/game";

const initialState = {
    activeGame: {},
    games: [],
    isLoading: false,
    activePlayers: [],
    error: null,
    goToNextGame: false,
    endTheJam: false,
    numOfPlayers: 0,
    isPaused: false,
    isResumed: false,
    isCurrentSongFinished: false,
    numberOfFetchedGames: null,
};

const game = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_GAME_REQUREST:
            return {
                ...state,
                error: null,
                isLoading: true,
            };

        case FETCHED_GAMES:
            return {
                ...state,
                isLoading: false,
                games: action.payload,
                numberOfFetchedGames: action.payload ? action.payload.length : 0
            };

        case FETCHED_ACTIVE_GAME:
            return {
                ...state,
                isLoading: false,
                activeGame: action.payload,
            };

        case FETCHED_ACTIVE_GAME_PLAYERS:
            return {
                ...state,
                activePlayers: action.payload,
                goToNextGame: false,
            }
        case FETCHED_ACTIVE_GAME_PLAYER_INFO: 
            let next = [...state.activePlayers]
            const inx = next.findIndex((player) => player.playerId === action.payload.playerId)
            if(inx !== -1) next.splice(inx, 1, action.payload)
            else next.push(action.payload)
            return {
                ...state,
                activePlayers: [...next],
                goToNextGame: false,
            }
        case GO_TO_NEXT_GAME:
            return {
                ...state,
                goToNextGame: true,
                endTheJam: false,
            }
        case JAM_ENDED:
            return {
                ...state,
                goToNextGame: false,
                endTheJam: true,
            }
        case NUMBER_OF_PLAYERS:
            return {
                ...state,
                numOfPlayers: action.payload,
            }   
        case IS_GAME_PAUSED:
            return {
                ...state,
                isPaused: action.payload,
            }
        case IS_GAME_RESUMED:
            return {
                ...state,
                isResumed: action.payload,
            } 
        case CURRENT_SONG_FINISHED:
            return {
                ...state,
                isCurrentSongFinished: action.payload,
            }
        case FAILED_GAME_REQUEST:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case RESET_GAME:
            return initialState;
            
        case RESET_ACTIVE_GAME:
            return {
                ...state,
                activeGame: null,
                activePlayers: [],
                isLoading: false,
                error: null,
            };

        default:
            return state;
    }
};

export default game;
