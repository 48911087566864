import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import SignupForm from "../../../components/auth/SignupForm";
import { useDispatch } from "react-redux";
import { signup, sendConfirmCode } from "../../../redux/actions/authUser";
import ShowToast from "../../../utils/ShowToast";
import AuthService from "../../../services/AuthService";
import { useSelector } from "react-redux";

const SignupContainer = ({ navigate }) => {
    const dispatch = useDispatch();
    const formRef = React.createRef();

    const [validUserName, setValidUserName] = useState("");
    const { error, userData } = useSelector((state) => state.user);

    // useEffect(() => {
    //     window.location.href = "https://www.playjindo.com/";
    //     localStorage.clear();
    // }, []);

    useEffect(() => {
        if (!validUserName && validUserName != "") {
            ShowToast("UserName Already taken!", "error", null, 3);
        }
    }, [validUserName]);

    useEffect(() => {
        if (
            !error &&
            userData
        ) {
            navigate("/userHome");
        } else if (error) {
            if (error.includes("Email")) {
                ShowToast("Email Already Exists", "error", null, 3);
            } else {
                ShowToast(error, "error", null, 3);
            }
        }
    }, [error, userData]);

    const handleSubmit = async () => {
        try {
            const formValues = await formRef.current.validateFields();
            const [firstName, lastName] = formValues.name?.split(" ");
            new Promise((resolve, reject) => {
                resolve(
                    dispatch(signup({ ...formValues, firstName, lastName }))
                );
                reject(new Error("signup error"));
            })    
            .then(() => {
                window.location = `/userHome`;
            })
            .catch((error) => {
                console.log(error);
            });
                
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeUserName = async (value) => {
        try {
            const response = await AuthService.checkUserNameValid({
                userName: value,
            });
            if (response?.data.valid) {
                setValidUserName(true);
            }
        } catch (error) {
            setValidUserName(false);
            ShowToast("UserName Already taken!", "error", null, 3);
        }
    };

    return (
        <Row
            justify='center'
            align='stretch'
            className='action-container-wrapper'
        >
            <Col span={20} style={{ display: "flex" }}>
                <SignupForm
                    formRef={formRef}
                    handleSubmit={handleSubmit}
                    onChangeUserName={handleChangeUserName}
                />
            </Col>
        </Row>
    );
};

export default SignupContainer;
