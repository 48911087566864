import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Typography, Row, Col, Space, Button } from "antd";

import { OpenLockIcon, JindoWhiteLogo } from "../../utils/Icons";

const { Text } = Typography;

const LoginForm = (props) => {
    const { formRef, handleSubmit, handleChange } = props;

    return (
        <Space
            size={35}
            direction='vertical'
            style={{ justifyContent: "center" }}
        >
            <Row justify='center' align='middle'>
                <Space direction='vertical' align='center' size={47}>
                    <Col>
                        <a href='https://www.playjindo.com' target='_blank'>
                            <JindoWhiteLogo
                                style={{ width: 130, height: 94 }}
                            />
                        </a>
                    </Col>
                    <Col className='auth-form-sub-text'>
                        <Row justify='center'>
                            <Text>WELCOME BACK!</Text>
                        </Row>
                        <Row justify='center'>
                            <Text>ENTER YOUR INFO BELOW TO</Text>
                        </Row>
                        <Row justify='center'>
                            <Text>ACCESS YOUR DASHBOARD</Text>
                        </Row>
                    </Col>
                </Space>
            </Row>
            <Col span={24}>
                <Form
                    ref={formRef}
                    name='login'
                    className='form-container'
                    onFinish={handleSubmit}
                    onValuesChange={handleChange}
                >
                    <Space
                        direction='vertical'
                        align='center'
                        size={10}
                        style={{ width: "100%" }}
                    >
                        <Row justify='center' align='middle'>
                            <Col span={24}>
                                <Form.Item
                                    name='email'
                                    className='custom-input'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Email",
                                        },
                                        {
                                            type: "email",
                                            message:
                                                "The input is not a valid E-mail!",
                                        },
                                    ]}
                                >
                                    <Input placeholder='Email' />
                                </Form.Item>
                            </Col>
                            <Col>
                                <OpenLockIcon
                                    style={{
                                        width: 30,
                                        height: 35,
                                        marginTop: 14,
                                        fill: "white",
                                    }}
                                />
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name='password'
                                    className='custom-input'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Password",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Password'
                                        type='password'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{ marginTop: 27 }}>
                                <Button
                                    className='high-priority-btn'
                                    htmlType='submit'
                                    style={{ height: 46 }}
                                >
                                    Login To Your Account
                                </Button>
                            </Col>
                            <Col span={24}>
                                <Link
                                    to='/forgetPassword'
                                    className='forgot-password'
                                    style={{
                                        textShadow:
                                            "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                >
                                    Forgot Password?
                                </Link>
                            </Col>
                        </Row>
                    </Space>
                </Form>
            </Col>
            <Row justify='center' align='middle'>
                <Space direction='vertical' align='center' size={55}>
                    <Col
                        span={24}
                        style={{
                            textAlign: "center",
                            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                    >
                        <>
                            <Text className='need-account-text'>
                                What to Host your own games?
                            </Text>
                            <br />
                            <a
                                href='https://www.playjindo.com/store/p/vip-subscribe-monthly-jindo'
                                target='_blank'
                            >
                                <Text className='singup-link'>
                                    Learn More About Jindo Here
                                </Text>
                            </a>
                        </>
                    </Col>
                </Space>
                <Col span={24}>
                    <Text className='copyright-text'>
                        ©Copyright 2022 Jindo LLC
                    </Text>
                </Col>
            </Row>
        </Space>
    );
};

export default LoginForm;
