import React from "react";
import { Row, Col, Typography } from "antd";
import ActionButton from "../common/ActionButton";

import { ArrowIconSVG, HomeIconSVG } from "../../utils/Icons";

const { Text } = Typography;

const ContactusSent = ({ onHome }) => {
    return (
        <Row justify="center" className="contact-us-sent-container">
            <Col className="contact-us-heading-splash">
            </Col>
            <Col
                span={24}
                className="host-general-message-container contact-us-sent-message-container"
            >
                <Text className="host-general-message-text">
                    We Appreciate your comment!!
                </Text>
            </Col>
            <Col span={24}>
                <Row
                    justify="center"
                    className="contact-us-sent-action-container"
                >
                    <Col span={24} className="center-items">
                        <Text className="contact-us-sent-action-text">
                            Click Home to Play, Host and More!
                        </Text>
                    </Col>
                    <Col span={20} className="center-items">
                        <ActionButton
                            title="Home"
                            className="high-priority-btn"
                            onClick={onHome}
                            prefixIconSVG={HomeIconSVG}
                            postfixIconSVG={ArrowIconSVG}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ContactusSent;
