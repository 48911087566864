import React, { useEffect, useState } from "react";
import JamCode from "../../../components/player/jamCode/JamCode";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Row, Col, Form } from "antd";
import { playerCreate } from "../../../redux/actions/player";
import ShowToast from "../../../utils/ShowToast";

const JamCodeContainer = (props) => {
    const formRef = Form.useForm();
    const dispatch = useDispatch();

    const [playerData, setPlayerData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const userName = window.location.hash.substring("1");

    useEffect(() => {
        setPlayerData(JSON.parse(localStorage.getItem("player-data")));
    }, []);

    // useEffect(() => {
    //     if (activeGame && activeGame.hasOwnProperty("jamId")) {
    //         navigate(`/${activeGame.host.userName}`);
    //     }
    // }, [activeGame]);

    useEffect(() => {
        formRef.current.setFieldsValue({
            userName: playerData?.name,
        });
    }, [playerData]);

    const handleSubmit = (values) => {

        if(values.userName.trim().length === 0) {
            ShowToast('Enter valid Name', 'error')
            return;
        }
        setIsLoading(true);
        if (playerData?.name !== values.userName) {
            new Promise((resolve, rejct) => {
                resolve(
                    dispatch(
                        playerCreate({
                            name: values.userName.trim(),
                            email: "",
                            userId: "",
                            phoneNumber: null,
                        })
                    )
                );
            }).then(() => {
                navigate(`/${userName}`);
            });
        } else {
            navigate(`/${userName}`);
        }
        setIsLoading(false);
    };

    return (
        <Row justify='center' align='top' className='game-container'>
            <Col span={20}>
                <JamCode
                    formRef={formRef}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                />
            </Col>
        </Row>
    );
};

export default JamCodeContainer;
