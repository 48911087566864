import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";

import PostGame from "../../../components/player/postGame/PostGame";
import UserService from "../../../services/UserService";
import PlayerSideStreaming from "../../../components/streaming/PlayerSideStreaming";

const PostGameContainer = ({ navigate }) => {
    const hostId = window.location.hash.substring("1");
    const playerData = JSON.parse(localStorage.getItem("player-data"));
    const { jam } = useSelector((state) => state.jam);

    const [venmoLink, setVenmoLink] = useState("");
    const [googleReviewLink, setGoogleReviewLink] = useState("");


    useEffect(() => {
        (async () => {
            const { data, status } = await UserService.fetchUserById(hostId);
            if (status === 200) {
                setVenmoLink(data.venmoLink);
                setGoogleReviewLink(data.googleReviewLink);
            }
        })()
    }, []);

    return (
        <Row justify='center' align='top' className='game-container'>
            {jam.hasOwnProperty('jamId') && jam.jamType === 'STREAMING' && <PlayerSideStreaming jamId={jam.jamId} />}
            <Col span={22} style={{ height: "100%" }}>
                <PostGame
                    playerData={playerData}
                    venmoLink={venmoLink}
                    googleReviewLink={googleReviewLink}
                />
            </Col>
        </Row>
    );
};

export default PostGameContainer;
