import React from "react";
import { Row, Col, Form, Button, Typography, Input } from "antd";
import { JindoWhiteWithSplashIcon } from "../../../utils/Icons";
import ReactCodeInput from "react-code-input";
import { Link } from "react-router-dom";

const { Text } = Typography;

const GameCode = (props) => {
    const { formRef, handleSubmit, jamCode, isLoading } = props;
    return (
        <Row justify='center'>
            <Col span={24} style={{ textAlign: "center" }}>
                <a href="https://www.playjindo.com" target="_blank">
                    <JindoWhiteWithSplashIcon
                        style={{ width: 200, height: 175 }}
                    />
                </a>
            </Col>
            <Col>
                <Text className='game-header-text'>
                    looking to join a game?
                </Text>
                <Text className='game-header-subtext'>
                    Enter your name and verification code supplied by your host
                    to jump into their Jam.
                </Text>
            </Col>
            <Col span={24}>
                <Form name='jamCodeForm' ref={formRef} onFinish={handleSubmit}>
                    <Row justify='center' align='middle'>
                        <Col span={24}>
                            <Form.Item
                                name='userName'
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter User Name",
                                    },
                                ]}
                                style={{ margin: 0 }}
                            >
                                <Input
                                    className='game-input-field'
                                    placeholder='enter your name'
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name='jamCode'
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Game Code",
                                    },
                                ]}
                                style={{ margin: 0 }}
                                initialValue={jamCode}
                            >
                                <ReactCodeInput
                                    placeholder='code'
                                    type='text'
                                    inputMode='numeric'
                                    inputStyle={{
                                        width: 46,
                                        height: 46,
                                        borderRadius: 5,
                                        border: "none",
                                        margin: "12px 8px",
                                        fontSize: 21,
                                        textAlign: "center",
                                        color: "#FF14BD",
                                        outline: "none",
                                        "-moz-appearance": "textfield",
                                        "-webkit-appearance": "none",
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {/* <Col>
                            <Text className='game-helper-subtext'>
                                Don’t have a code? You can also sign up to
                                become a host and set up your very own Jindo
                                Jams!
                            </Text>
                            <a href='https://www.playjindo.com/store/p/vip-subscribe-monthly-jindo' target="_blank">
                                <Text className='game-helper-subtext' underline>
                                    Learn more and sign up today!
                                </Text>
                            </a>
                        </Col> */}
                        <Col style={{ marginBottom: 90 }} md={24}>
                            <Text className='game-footer-text'>
                                By Joining this game you agree to our{" "}
                                <a
                                    href='https://www.playjindo.com/privacy-policy-info'
                                    target='_blank'
                                    state={{ color: "inherit" }}
                                >
                                    Terms & Conditions
                                </a>
                            </Text>
                            <Text className='game-footer-text'>
                                ©Copyright 2022 Jindo LLC
                            </Text>
                        </Col>
                        <Col span={24} className='game-submit-btn-container'>
                            <Button
                                className='game-submit-btn'
                                block
                                htmlType='submit'
                                loading={isLoading}
                            >
                                JOIN GAME + START JAMMING!
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

export default GameCode;
