import React, { useEffect, useRef } from "react";
import { Col, Row } from "antd";
import { detect } from "detect-browser";

import PlaylistManagerHeader from "../../../components/admin/playlist/PlaylistManagerHeader";
import SongsTable from "../../../components/admin/playlist/SongsTable";
import { useDispatch } from "react-redux";
import {
    updateSong,
    removeSong,
    fetchPlaylist,
} from "../../../redux/actions/playlist";
import { searchedSongs } from "../../../utils/PlaylistUtils";
import { useSelector } from "react-redux";
import WarningModal from "../../../components/modals/WarningModal";
import { useState } from "react";
import ShowToast from "../../../utils/ShowToast";

const browser = detect();

const transformation = (songs) => {
    return songs.map((song) => {
        const { songId, title, artist, path, comment } = song;
        return {
            key: songId,
            songId: songId,
            title,
            artist,
            path,
            comment,
            song: {
                audio: new Audio(` ${ browser?.name === "safari" ? process.env.SONGS_SAFARI_URL : process.env.SONGS_URL}${songId}.mp3`),
                isPlaying: false,
            },
            isUpdated: false,
        };
    });
};

const PlaylistEditContainer = ({
    playlistName,
    setPlaylistName,
    playlistDuration,
    setPlaylistDuration,
    searchPlaylistTerm,
    songs,
    setSongs,
    playlistId,
}) => {
    const dispatch = useDispatch();

    const refCurrentSong = useRef(null);

    const [deleteSongId, setDeleteSongId] = useState(null);
    const [deleteSongLoading, setDeleteSongLoading] = useState(false);

    const { playlist, isLoading } = useSelector((state) => state.playlist);

    useEffect(() => {
        dispatch(fetchPlaylist(playlistId, 75));
    }, []);

    useEffect(() => {
        if (playlist) {
            setSongs(transformation(playlist.songs));
            setPlaylistName(playlist.name);
            setPlaylistDuration(playlist.speed);
        }
    }, [playlist]);

    const handleSongPlayAction = (songId) => {
        const updateSongs = [...songs];
        const index = updateSongs.findIndex((song) => song.songId === songId);
        updateSongs.forEach((element, position) => {
            if (position !== index) {
                element.song.audio.pause();
                element.song.audio.currentTime = 0;
                element.song.isPlaying = false;
            }
        });
        updateSongs[index].song.isPlaying = !updateSongs[index].song.isPlaying;
        updateSongs[index].song.isPlaying
            ? updateSongs[index].song.audio.play()
            : updateSongs[index].song.audio.pause();
        updateSongs[index].song.isPlaying
            ? (refCurrentSong.current = updateSongs[index].song.audio)
            : null;
        setSongs(updateSongs);
        updateSongs[index].song.audio.onended = () => {
            const updateSongs = [...songs];
            updateSongs[index].song.isPlaying = false;
            setSongs(updateSongs);
        };
    };

    const updateSongRecord = (value, songId, attr) => {
        const index = songs.findIndex((song) => song.songId === songId);
        const updateSongs = [...songs];
        updateSongs[index][attr] = value;
        const { title, artist, comment } = updateSongs[index];
        updateSongs[index].isUpdated = true;
        dispatch(updateSong(songId, { title, artist, comment }));
        setSongs(updateSongs);
    };

    const onDeleteSong = (songId) => {
        setDeleteSongId(songId);
    };

    const handleDeleteSong = () => {
        setDeleteSongLoading(true);
        const updateSongs = [...songs];
        const index = updateSongs.findIndex(
            (song) => song.songId === deleteSongId
        );
        updateSongs.splice(index, 1);
        setSongs(updateSongs);

        new Promise((resolve) =>
            resolve(dispatch(removeSong(deleteSongId)))
        ).then(() => {
            setDeleteSongLoading(false);
            setDeleteSongId(null);
            ShowToast("Deleted Song Successfully!","success",null,2)
        });
    };

    return (
        <Row className="playlist-manager-songs-container">
            <WarningModal
                onCancel={()=> setDeleteSongId(null)}
                visible={deleteSongId}
                onDelete={handleDeleteSong}
                isLoading={deleteSongLoading}
            />
            <Col span={24}>
                <PlaylistManagerHeader
                    playlistName={playlistName}
                    onChangePlaylistName={(value) => setPlaylistName(value)}
                    playlistDuration={playlistDuration}
                    onChangePlaylistDuration={setPlaylistDuration}
                    isEditMode={true}
                />
            </Col>
            <Col span={24}>
                <SongsTable
                    songs={searchedSongs(songs, searchPlaylistTerm)}
                    handleSongPlayAction={handleSongPlayAction}
                    handleDeleteSong={onDeleteSong}
                    updateSong={updateSongRecord}
                    isLoading={isLoading}
                />
            </Col>
        </Row>
    );
};

export default PlaylistEditContainer;
