import React from "react";
import { Row, Col, Table, Typography, Input } from "antd";

import { DeleteIcon, PauseIcon, PlayIcon } from "../../../utils/Icons";

const { Text } = Typography;
const SongsTable = ({
    songs,
    handleSongPlayAction,
    handleDeleteSong,
    updateSong,
    isLoading
}) => {
    const columns = [
        {
            title: <Text className="common-table-header-txt">Title</Text>,
            dataIndex: "title",
            key: "title",
            sorter: (a, b) => a.title.localeCompare(b.title),
            render: (title, record) => (
                <Row justify="start" align="middle">
                    <Col span={1}>
                        {record.song.isPlaying ? (
                            <PauseIcon
                                onClick={() =>
                                    handleSongPlayAction(record.songId)
                                }
                                className="playlist-song-play-icon"
                            />
                        ) : (
                            <PlayIcon
                                onClick={() =>
                                    handleSongPlayAction(record.songId)
                                }
                                className="playlist-song-play-icon"
                            />
                        )}
                    </Col>
                    <Col span={23}>
                        <Input
                            value={title}
                            className="songs-table-cell-input"
                            style={{ width: "100%" }}
                            onChange={(e) =>
                                updateSong(
                                    e.target.value,
                                    record.songId,
                                    "title"
                                )
                            }
                        />
                    </Col>
                </Row>
            ),
        },
        {
            title: <Text className="common-table-header-txt">ARTIST</Text>,
            dataIndex: "artist",
            key: "artist",
            sorter: (a, b) => a.artist.localeCompare(b.artist),
            render: (artist, record) => (
                <Input
                    value={artist}
                    className="songs-table-cell-input"
                    onChange={(e) =>
                        updateSong(e.target.value, record.songId, "artist")
                    }
                />
            ),
        },
        {
            title: <Text className="common-table-header-txt">SOUND BYTE</Text>,
            key: "comment",
            dataIndex: "comment",

            render: (comment, record) => (
                <Input
                    value={comment}
                    className="songs-table-cell-input"
                    onChange={(e) =>
                        updateSong(e.target.value, record.songId, "comment")
                    }
                />
            ),
        },
        {
            title: <DeleteIcon className="delete-song-column-icon" />,
            key: "action",
            width: 55,
            render: (text, record) => (
                <DeleteIcon
                    onClick={() => handleDeleteSong(record.songId)}
                    className="delete-song-icon"
                />
            ),
        },
    ];

    return (
        <Row className="common-playlist-table-container">
            <Col span={24}>
                <Table
                    className="common-playlist-table"
                    dataSource={songs}
                    columns={columns}
                    pagination={false}
                    sticky={true}
                    loading={isLoading}
                />
            </Col>
        </Row>
    );
};

export default SongsTable;
