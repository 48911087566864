import {
    INITIALIZE_PATTERNS_REQUREST,
    FAILED_PATTERNS_REQUEST,
    FETCHED_PATTERNS,
} from "../actionTypes/patterns";

const initialState = {
    patterns: [],
    isLoading: false,
    error: null,
};

const patterns = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_PATTERNS_REQUREST:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case FETCHED_PATTERNS:
            return {
                ...state,
                patterns: action.payload,
                isLoading: false,
            };
        case FAILED_PATTERNS_REQUEST:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default patterns;
