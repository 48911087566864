import {
    REQUEST_FETCH_USERS,
    FAILED_FETCH_USERS,
    FETCHED_USERS,
} from "../actionTypes/users";

const initialState = {
    users: [],
    isLoading: false,
    error: null,
};

const users = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_FETCH_USERS:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case FETCHED_USERS:
            return {
                ...state,
                isLoading: false,
                users:action.payload,
            };
        case FAILED_FETCH_USERS:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default users;
