import { useEffect, useState } from 'react';
import useRound from './useRound';

export default function useRoundPrize(roundId) {
    const [prize, _setPrize] = useState(null)
    const {setFieldInRound, getFieldFromRound} = useRound(roundId)

    useEffect(() => {
      // sync prize with the value in rounds map in document
      _setPrize(getFieldFromRound('prize'))
    }, [roundId])

    /**
     * Sets prize to a given value
     * @param {string} value - New value for the prize
     */
    const setPrize = (value) => {
      setFieldInRound('prize', value)
      _setPrize(value)
    }

    return {prize, setPrize}
}