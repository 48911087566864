import React from "react";
import { Row, Col, Typography, Button, Divider } from "antd";
import {
    FacebookIcon,
    ThreeNormalIcon,
    InstagramIcon,
    SoptifyIcon,
} from "../../../utils/Icons";
import { JindoWhiteWithSplashIcon } from "../../../utils/Icons";
import { Link } from "react-router-dom";

const { Text } = Typography;

const GameJamEnd = ({ navigate }) => {
    return (
        <Row justify='center' align='top'>
            <Col span={24} style={{ textAlign: "center" }}>
                <JindoWhiteWithSplashIcon style={{ width: 200, height: 175 }} />
            </Col>
            <Col>
                <Text className='game-header-text'>
                    YOUR JAM SESSION HAS CONCLUDED
                </Text>
            </Col>
            <Col span={22}>
                <Text
                    className='game-helper-subtext'
                    style={{ margin: 0, marginTop: 20 }}
                >
                    Wanna keep playing? You can sign up to become a host and set
                    up your very own Jindo Jams!
                </Text>
            </Col>
            <Col span={22} style={{ textAlign: "center" }}>
                <ThreeNormalIcon className='jam-end-extra-icon' />
                <Button
                    className='high-priority-btn'
                    block
                    style={{ height: 95 }}
                    onClick={() => window.open("https://www.playjindo.com/store/p/vip-subscribe-monthly-jindo")}
                >
                    SIGN UP TO BECOME A HOST
                </Button>
            </Col>
            <Col span={22}>
                <Button
                    className='game-info-secondary-btn'
                    block
                    onClick={() => navigate("/jamcode")}
                >
                    JOIN ANOTHER GAME
                </Button>
            </Col>
            <Col span={24}>
                <Divider style={{ borderTopColor: "#E5E431" }} />
            </Col>
            <Col span={24}>
                <Row justify='center' align='middle'>
                    <Col>
                        <Button
                            className='jam-end-social-btn jam-end-social-btn-1'
                            onClick={() =>
                                window.open(
                                    "https://www.facebook.com/playjindo"
                                )
                            }
                        >
                            <FacebookIcon style={{ fill: "#FF14BD" }} />
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className='jam-end-social-btn jam-end-social-btn-2'
                            onClick={() =>
                                window.open(
                                    "https://www.instagram.com/playjindo"
                                )
                            }
                        >
                            <InstagramIcon style={{ fill: "#FF14BD" }} />
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className='jam-end-social-btn jam-end-social-btn-3'
                            onClick={() =>
                                window.open(
                                    "https://open.spotify.com/user/mdfc3ryorbixu79hzt12wh5py?si=c2e859e62f024b8a&nd=1"
                                )
                            }
                        >
                            <SoptifyIcon style={{ fill: "#FF14BD" }} />
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Text className='jam-end-footer-text'>
                            Follow Jindo To Stay Connected
                        </Text>
                    </Col>
                </Row>
            </Col>
            {/* 
               
            {userData?.facebookLink && (
                <Col span={18}>
                    <ActionButton
                        title='facebook'
                        className='common-btn facebook-blue-btn'
                        onClick={() => {
                            if (userData.facebookLink.includes("https://")) {
                                window.open(userData.facebookLink);
                            } else {
                                window.open(`https://${userData.facebookLink}`);
                            }
                        }}
                        prefixIconSVG={FacebookWhiteIconSVG}
                        postfixIconSVG={ArrowIconSVG}
                        disabled={!userData?.facebookLink}
                    />
                </Col>
            )} */}
        </Row>
    );
};

export default GameJamEnd;
