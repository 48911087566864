import React, { useEffect } from "react";
import { Row, Col } from "antd";
import InviteFriends from "../../../components/player/inviteFriends/InviteFriends";
import ShowToast from "../../../utils/ShowToast";
import { fetchActiveGame } from "../../../redux/actions/game";
import { useSelector, useDispatch } from "react-redux";

const InviteFriendsContainer = ({ navigate }) => {
    const jamCode = window.location.hash.substring("1");
    const dispatch = useDispatch();

    const { activeGame, numOfPlayers } = useSelector((state) => state.game);

    useEffect(() => {
        if (jamCode) {
            dispatch(fetchActiveGame({ jamCode }));
        }
    }, []);

    useEffect(() => {
        if (
            activeGame.status === "STARTED" ||
            activeGame.status === "IN_PROGRESS"
        ) {
            ShowToast("The Game has been started - Ready to Play?", "success");
        }
    }, [activeGame]);

    const handleCopyJamLink = async () => {
        if (activeGame?.host?.userName) {
            await navigator.clipboard.writeText(
                `${window.location.origin}/${activeGame.host.userName}`
            );
            ShowToast("Link Copied!", "success");
        } else {
            ShowToast("Jam Not Available Yet", "error");
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    }
    return (
        <Row justify='center' align='middle' className='game-container'>
            <Col>
                <InviteFriends
                    handleCopyJamLink={handleCopyJamLink}
                    jamCode={jamCode}
                    numOfPlayers={numOfPlayers}
                    hostName={activeGame?.host?.userName}
                    handleGoBack={handleGoBack}
                />
            </Col>
        </Row>
    );
};

export default InviteFriendsContainer;
