import React from "react";
import { Row, Col, Form, Button, Typography, Input } from "antd";
import { JindoWhiteWithSplashIcon } from "../../../utils/Icons";
import ReactCodeInput from "react-code-input";
import { Link } from "react-router-dom";

const { Text } = Typography;

const JamCode = (props) => {
    const { formRef, handleSubmit, isLoading } = props;
    return (
        <Row justify='center' align=''>
            <Col span={24} style={{ textAlign: "center" }}>
                <JindoWhiteWithSplashIcon style={{ width: 200, height: 175 }} />
            </Col>
            <Col>
                <Text className='game-header-text'>JOIN YOUR HOST’S JAM</Text>
                <Text className='game-header-subtext'>
                    Enter your name to jump into your Host’s Jam.
                </Text>
            </Col>
            <Col span={24}>
                <Form name='jamCodeForm' ref={formRef} onFinish={handleSubmit}>
                    <Row justify='center' align='middle'>
                        <Col span={24}>
                            <Form.Item
                                name='userName'
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter User Name",
                                    },
                                ]}
                                style={{ margin: 0 }}
                            >
                                <Input
                                    className='game-input-field'
                                    placeholder='enter your player name'
                                />
                            </Form.Item>
                        </Col>
                        <Col style={{ marginTop: 60 }}>
                            <Text className='game-helper-subtext'>
                                You can also sign up to become a host and set up
                                your very own Jindo Jams!
                            </Text>
                            <a href='https://www.playjindo.com/store/p/vip-subscribe-monthly-jindo' target="_blank">
                                <Text className='game-helper-subtext' underline>
                                    Learn more and sign up today!
                                </Text>
                            </a>
                        </Col>
                        <Col style={{ marginBottom: 90 }}>
                            <Text className='game-footer-text'>
                                By Joining this game you agree to our{" "}
                                <a
                                    href='https://www.playjindo.com/privacy-policy-info'
                                    target='_blank'
                                    state={{ color: "inherit" }}
                                >
                                    Terms & Conditions
                                </a>
                            </Text>
                        </Col>
                        <Col span={24} className='game-submit-btn-container'>
                            <Button
                                className='game-submit-btn'
                                block
                                htmlType='submit'
                                loading={isLoading}
                            >
                                JOIN GAME + START JAMMING!
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

export default JamCode;
