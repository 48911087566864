import { notification } from "antd";

const ShowToast = (message, type = "info", description = "", duration = 5) => {
    notification[type]({
        message: message,
        description: description,
        placement: "topRight",
        duration: duration,
    });
};

export default ShowToast;
