import React from "react";
import { Row } from "antd";

import useActiveGame from "../../../hooks/useActiveGame";
import useJam from "../../../hooks/useJam";
import CreatePlayerGameContainer from "./CreatePlayerGameContainer";
import PreAndPlayGameContainer from "./PreAndPlayGameContainer";
import useGameWinner from "../../../hooks/useGameWinner";

const GameContainer = ({ navigate, playerId }) => {
    const { activeGame } = useActiveGame();
	const { gameWinner } = useGameWinner();
	const { jam } = useJam();

    return activeGame && activeGame?.hasOwnProperty('gameId') && (
        <Row justify='center' align='top' className='game-container'>
			<CreatePlayerGameContainer 
				game={activeGame}
				playerId={playerId}
			/>
			<PreAndPlayGameContainer 
				jam={jam}
				navigate={navigate}
				activeGame={activeGame}
				gameWinner={gameWinner?.winner}
			/>
        </Row>
    );
};

export default GameContainer;
