import { useEffect, useState } from "react"
import { useGameProvider, useJamProvider } from "../context/collaborationContext"
import { useUser } from "../context/userContext";
import GameService from "../services/GameService";

const NO_CALL = 'NO_CALL';
const VERIFYING = 'VERIFYING';
/**
 * Get selected keys from the list of songs list
 * @param {Array<SongListItem>} cardLayout - List of all song items/cards of a player
 * @returns 
 */
const getSelectedKeys = (cardLayout) => {
  return cardLayout.filter((song) => song.selected).map((song) => song.key)
}

export default function usePlayer(gameId) {
    const [player, setPlayer] = useState(null)
    const [activePlayers, setActivePlayers] = useState(null)
    const { playerId } = useUser()
    const { document } = useGameProvider(gameId);
    const jamProvider = useJamProvider()

    useEffect(() => {
        const playersMap = document.getMap('players')
        setActivePlayers(Array.from(playersMap.values()).length)

        const setCurrentPlayer = (data) => {
                if(data.changes.keys.get(playerId)) {
                setPlayer(Array.from(playersMap.values()).find(player => player.id === playerId))
            }
        }

        playersMap.observe(setCurrentPlayer)
        
        return () => {
            playersMap.unobserve(setCurrentPlayer)
        }
    }, [document])

    /**
     * Update player card layout and selected pattern
     * @param {Array<SongListItem>} cardLayout - List of all the song items/cards of a player 
     */
    const updateCardLayout = (cardLayout) => {
        const playersMap = document.getMap('players')
        playersMap.set(player.id, { 
            ...playersMap.get(player.id), 
            cardLayout, 
            callJindoStatus: NO_CALL, 
            leave: false, 
            selectedPattern: getSelectedKeys(cardLayout) 
        })
    }

    const updateCDModalState = () => {
        const playersMap = document.getMap('players')
        playersMap.set(player.id, { ...playersMap.get(player.id), cdModal: false, cardAnimate: false })
    }

    const leaveGame = () => {
        const playersMap = document.getMap('players')
        playersMap.set(player.id, { ...playersMap.get(player.id), leave: true})
    }

    const updateCallJindoStatus = (playerId, status) => {
        const playersMap = document.getMap('players')
        playersMap.set(playerId, { 
            ...playersMap.get(playerId), 
            callJindoStatus: status === 'verify' ? VERIFYING : status === 'tryAgain' ? 'TRY_AGAIN' : NO_CALL
        })
    }

    const checkForWinner = (gameId, player) => {
        addJindoCall(player?.name);
        setTimeout(() => {
            addJindoCall(null);
        }, 2000);

        verifyWinner(gameId, player);
    }
  
    const verifyWinner = (gameId, gamePlayer) => {
        const roundsMap = jamProvider.document.getMap('rounds');
        const activeGame = Array.from(roundsMap.values()).filter(round => round.gameId === gameId)[0];
        if(activeGame?.winnerName) {
            updateCallJindoStatus(gamePlayer.id, 'tryAgain')
            return;
        }
        GameService.verifyWinner({activeGame, gamePlayer}).then((resp) => {
            if(resp.status === 200) {
                const {winner} = resp.data;
                if (winner && !isWinnerExists(gameId)) {
                    setGameWinner({name: gamePlayer.name, winnerId: gamePlayer.id, gameId, isFinished: true})
                    activeGame.isFinished = true;
                    activeGame.winnerName = gamePlayer.name;
                    activeGame.winnerId = gamePlayer.id;
                    roundsMap.set(activeGame.key.toString(), activeGame)
                    updateCallJindoStatus(gamePlayer.id, 'winner')
                } else {
                    updateCallJindoStatus(gamePlayer.id, 'tryAgain')
                }
            }
            
        }).catch(() => {
            updateCallJindoStatus(gamePlayer.id, 'tryAgain')
        })
        
    }

    const isWinnerExists = (gameId) => {
        const gameWinnerMap = jamProvider.document.getMap('gameWinner');
        const gameWinner = Object.fromEntries(gameWinnerMap.entries());
        return !!(gameWinner?.winner?.name && gameWinner?.winner?.gameId === gameId);
    }

    const addJindoCall = (value) => {
        const jamMap = jamProvider.document.getMap('jindoCall')
        jamMap.set('playerCalledJindo', value)
    }

    const setGameWinner = (value) => {
        const jamMap = jamProvider.document.getMap('gameWinner')
        jamMap.set('winner', value)
    }

    return { 
        player, 
        activePlayers, 
        updateCardLayout,
        updateCDModalState, 
        leaveGame,
        checkForWinner,
        updateCallJindoStatus
    }
}