import {
    INITIALIZE_PATTERNS_REQUREST,
    FETCHED_PATTERNS,
    FAILED_PATTERNS_REQUEST,
} from "../actionTypes/patterns";
import PatternService from "../../services/PatternService";

const initializePatternRequest = () => {
    return {
        type: INITIALIZE_PATTERNS_REQUREST,
    };
};

const fetchedPatterns = (data) => {
    return {
        type: FETCHED_PATTERNS,
        payload: data,
    };
};

const failedPatternRequest = (error) => {
    return {
        type: FAILED_PATTERNS_REQUEST,
        payload: error,
    };
};

export const fetchPatterns = () => {
    return async (dispatch) => {
        try {
            dispatch(initializePatternRequest());
            const { data } = await PatternService.fetchPatterns();
            dispatch(fetchedPatterns(data.winningPatterns));
        } catch ({ response }) {
            dispatch(failedPatternRequest(response));
        }
    };
};
