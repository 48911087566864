import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "antd";

import JamEnd from "../../../components/host/jamEnd/JamEnd";
import { fetchGames } from "../../../redux/actions/game";
import { fetchJamId } from "../../../redux/actions/Jam";
import ShowToast from "../../../utils/ShowToast";

const JamEndContainer = ({ navigate }) => {
    const dispatch = useDispatch();
    const jamId = window.location.hash.substring("1");

    const { games } = useSelector((state) => state.game);

    useEffect(() => {
        dispatch(fetchGames(jamId));
    }, []);

    const handleGoToDashboard = () => {
        window.location = `/userHome`;
    };

    const handleCreateNewJam = async () => {
        try {
            const response = await fetchJamId("IN-PERSON");
            if (response && response.hasOwnProperty("jamId")) {
                if (response.jamType != "STREAMING") {
                    window.location = `/jamDeck/#${response.jamId}`;
                    return;
                } else {
                    ShowToast(
                        `You Already Have STREAMING Active Jam`,
                        "error",
                        null,
                        2
                    );
                    return;
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Row
            justify='center'
            align='stretch'
            className='action-container-wrapper'
            style={{ width: "100%", overflow: "hidden" }}
        >
            <JamEnd
                games={games}
                handleGoToDashboard={handleGoToDashboard}
                handleCreateNewJam={handleCreateNewJam}
            />
        </Row>
    );
};

export default JamEndContainer;
