import HttpService from "./HttpService";
import jwtDecode from "jwt-decode";

const AUTH_TOKEN_KEY = "auth-token";
const AUTH_USER = "auth-user";

export default class AuthService {
    static async setCurrentUser(user) {
        localStorage.setItem(AUTH_USER, JSON.stringify(user));
    }

    static async login(credentials) {
        const response = await HttpService.post("/auth/login", credentials);
        if (response?.data?.user.verified === "true") {
            this.setCurrentUser(response?.data.user);
        }
        localStorage.setItem(AUTH_TOKEN_KEY, response?.data.token);
        return response;
    }

    static async resetPassword(formData) {
        return await HttpService.post("/auth/resetPassword", formData);
    }

    static async updateNewPassword(data) {
        return await HttpService.post(`/auth/newPassword`, data);
    }

    static async signup(signupData) {
        const response = await HttpService.post("/auth/signup", signupData);
        // if (response.data.user.verified === "true") {
            this.setCurrentUser(response.data.user);
        // }
        localStorage.setItem(AUTH_TOKEN_KEY, response.data.token);
        return response;
    }

    static async logout() {
        const { status } = await HttpService.get("/auth/logout", {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
        localStorage.clear();
        return status;
    }

    static async verifyuser(verifyInput) {
        const { status } = await HttpService.post("/auth/verify", verifyInput);
        return status;
    }

    static getCurrentDecodeToken() {
        try {
            const jwt = localStorage.getItem(AUTH_TOKEN_KEY);
            return jwtDecode(jwt);
        } catch (ex) {
            return null;
        }
    }

    static getCurrentUser() {
        try {
            return JSON.parse(localStorage.getItem(AUTH_USER));
        } catch (ex) {
            return null;
        }
    }

    static async confirmGoogleLogin(token) {
        return await HttpService.post("/auth/google", { token });
    }

    static async confirmFacebookLogin(email) {
        return await HttpService.post("/auth/facebook", { email });
    }

    static async contactUs(contactUs) {
        return await HttpService.post("/auth/contactUs", contactUs);
    }

    static async sendConfirmCode(sendConfirmCodeInput) {
        return await HttpService.post(
            "/auth/confirmCode",
            sendConfirmCodeInput
        );
    }

    static async checkUserNameValid(userNameInput) {
        return await HttpService.post(
            "/auth/username/check",
            userNameInput
        );
    }
}
