import React, { useEffect, useState } from "react";
import {Modal, Row} from 'antd';
import { OpenVidu } from "openvidu-browser";
import MobileDetect from "mobile-detect";

import openViduService from "../../services/openViduService";

const PlayerSideStreaming = ({ jamId }) => {
  const mobileDevice = new MobileDetect(window.navigator.userAgent);
  const targetAppleDevices =
        mobileDevice.is("Iphone") || mobileDevice.is("iPad");
  const [iosModal, setIosModal] = useState(targetAppleDevices)
  const videoRef = React.createRef();
  const [sessionId, setSessionId] = useState(jamId);
  const [hostPublisher, setHostPublisher] = useState(null);

  useEffect(() => {
    const openVidu = new OpenVidu();
    const mySession = getSession(openVidu);
    mySession.on("streamCreated", (event) => {
      const subscriber = mySession.subscribe(event.stream, undefined);
      if (
        JSON.parse(subscriber.stream.connection.data).clientData.includes(
          "host"
        )
      ) {
        setHostPublisher(subscriber);
      }
    });

    mySession.on("streamDestroyed", (event) => {});
    mySession.on("exception", (exception) => console.log("exception", exception));

    (async () => {
      let sessionPublisher = await getPublisher(openVidu, mySession)
      await mySession.publish(sessionPublisher);
      
    })();
  }, []);

  useEffect(() => {
    if (hostPublisher && !!videoRef) {
      hostPublisher.addVideoElement(videoRef.current);
    }
  }, [hostPublisher]);

  const getSession = (openVidu) => {
    return openVidu.initSession();
  }

  const getPublisher = async (openVidu, mySession) => {
    const sessionResponse = await openViduService.createSession(sessionId);
    if (sessionResponse) {
      const token = await openViduService.createToken(sessionId);
      if (token) {
        await mySession.connect(token, { clientData: "player" });
        const publisher = openVidu.initPublisher(undefined, {
          audioSource: true,
          videoSource: false,
          publishVideo: false,
          publishAudio: false,
        });
        return publisher;
      }
    }
  }

  const closeModal = () => {
    if(hostPublisher) {
      videoRef.current.play();
      setIosModal(false)
    }
  }

  return <>
      <Modal
        className="ios-streaming-modal"
        visible={iosModal} 
        footer={false} 
        closable={false} 
        maskClosable={true} 
        bodyStyle={{padding: 0}} 
        onCancel={closeModal}
      >
        <Row justify="center" style={{color: '#fff'}} onClick={closeModal}>
          Tap anywhere to close start listnening host
        </Row>
      </Modal>
      {hostPublisher ? <audio autoPlay={!targetAppleDevices} ref={videoRef} /> : null}
  </> 

};

export default PlayerSideStreaming;