import Http from './HttpService';

const createSession = async (sessionId) => {
  const response = await Http.post('/openvidu/session/create', {sessionId});
  return response.status === 200;

}

const createToken = async (sessionId) => {
  const response = await Http.post('/openvidu/token/generate', {sessionId});
  if(response.status === 200) {
    return response?.data?.token;
  }
  return false;
}

export default {
  createSession,
  createToken,
}