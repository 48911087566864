import React from "react";
import { Row, Col, Table, Radio, Typography } from "antd";

import {
    Speed15sIcon,
    Speed30sIcon,
    DiskIcon,
    ViewIcon,
    DeleteIcon,
} from "../../../utils/Icons";
const { Text } = Typography;

const columns = (onDeletePlaylist, onEditPlaylist) => {
    const tableColumns = [
        {
            title: <Text className="common-table-header-txt"></Text>,
            dataIndex: "speed",
            key: "speed",
            align: "center",
            width: "5%",
            className: "common-table-icon-cell",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text) => {
                if (text === 15) {
                    return <Speed15sIcon />;
                } else if (text === 30) {
                    return <Speed30sIcon />;
                }
            },
        },
        {
            title: <Text className="common-table-header-txt">PLAYLIST</Text>,
            dataIndex: "name",
            key: "name",
            align: "center",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text) => (
                <Text className="common-table-cell-text">{text}</Text>
            ),
        },
        {
            title: <DiskIcon className="common-table-header-txt" />,
            dataIndex: "totalSongs",
            key: "totalSongs",
            align: "center",
            width: "7%",
            colSpan: 1,
            sorter: (a, b) => a.totalSongs - b.totalSongs,
            render: (totalSongs) => (
                <Row justify="center">
                    <Col>
                        <Text className="common-table-cell-text">
                            {totalSongs}
                        </Text>
                    </Col>
                </Row>
            ),
        },
        {
            title: <Text className="common-table-header-txt">CREATED BY</Text>,
            dataIndex: "createdBy",
            key: "createdBy",
            align: "center",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text) => (
                <Text className="common-table-cell-text">
                    {text ? text : "jindo"}
                </Text>
            ),
        },
        {
            title: (
                <Text className="common-table-header-txt">DATE CREATED</Text>
            ),
            dataIndex: "dateCreated",
            key: "dateCreated",
            align: "center",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text) => {
                const [day, month, year] = text.split("-");
                return (
                    <Text className="common-table-cell-text">
                        {`${month}/${day}/${year}`}
                    </Text>
                );
            },
        },
        {
            title: <ViewIcon className="common-table-header-txt" />,
            key: "view",
            width: 100,
            align: "center",
            width: "7%",
            render: (text, record) => (
                <ViewIcon
                    className="button-icon"
                    onClick={() => onEditPlaylist(record.key)}
                />
            ),
        },
        {
            title: <DeleteIcon className="common-table-header-txt" />,
            key: "delete",
            width: 100,
            align: "center",
            width: "7%",
            render: (text, record) => (
                <DeleteIcon
                    className="button-icon"
                    onClick={() => onDeletePlaylist(record.key)}
                />
            ),
        },
    ];
    return tableColumns;
};

const PlaylistTable = (props) => {
    const {
        handlePlaylistDurationChange,
        playlistDuration,
        playlists,
        onDeletePlaylist,
        isLoading,
        onEditPlaylist,
    } = props;

    return (
        <Row gutter={[0, 9]} className="common-playlist-table-container">
            <Col lg={12}>
                <Radio.Group
                    defaultValue="all"
                    optionType="button"
                    buttonStyle="outline"
                    onChange={handlePlaylistDurationChange}
                    value={playlistDuration}
                    className="custom-playlist-table-radio-group"
                >
                    <Radio.Button value={""}>
                        <Text className="custom-playlist-table-radio-button">
                            All
                        </Text>
                    </Radio.Button>
                    <Radio.Button value={"15"}>
                        <Text className="custom-playlist-table-radio-button">
                            <Speed15sIcon />
                            speed
                        </Text>
                    </Radio.Button>
                    <Radio.Button value={"30"}>
                        <Text className="custom-playlist-table-radio-button">
                            <Speed30sIcon />
                            standard
                        </Text>
                    </Radio.Button>
                </Radio.Group>
            </Col>
            <Col span={24}>
                <Table
                    className="common-playlist-table"
                    columns={columns(onDeletePlaylist, onEditPlaylist)}
                    dataSource={playlists}
                    pagination={false}
                    // scroll={{
                    //     y: '',
                    // }}
                    loading={isLoading}
                    sticky={true}
                />
            </Col>
        </Row>
    );
};

export default PlaylistTable;
