import { useEffect, useState} from 'react';
import { useJamProvider } from '../context/collaborationContext';

export default function useGameWinner() {
    const { document } = useJamProvider();
    const [gameWinner, _setGameWinner] = useState(null);

    useEffect(() => {
        const gameWinnerMap = document.getMap('gameWinner');

        const update = () => {
            _setGameWinner(Object.fromEntries(gameWinnerMap.entries()))
        };

        gameWinnerMap.observe(update);

        return () => {
            gameWinnerMap.unobserve(update);
        };
    }, [document]);

    return { gameWinner };
}