import React, { useEffect } from "react";
import PreGame from "../../../components/player/preGame/PreGame";
import { Row, Col } from "antd";
import usePlayers from "../../../hooks/usePlayers";

const PreGameContainer = ({jam, activeGame}) => {
    const { activePlayersCount } = usePlayers(activeGame?.gameId)

    useEffect(() => {
        if (jam?.endTheJam && jam?.jamCode) {
            window.location = `/gameJamEnd/#${jam?.userId}`;
        }
    }, [jam]);

    const handleInviteMoreFriend = () => {
        window.location = `/inviteFriends/#${jam?.jamCode}`;
    };

    return (
        <Row justify='center' align='top' className='game-container'>
            <Col span={20}>
                {activeGame && 
                    <PreGame
                        gameInfo={{...activeGame, pattern: activeGame.patternName, estGameTime: activeGame.gameTime }}
                        title={`Game ${activeGame?.key + 1}`}
                        numOfPlayers={activePlayersCount}
                        handleInviteMoreFriend={handleInviteMoreFriend}
                    />
                }
            </Col>
        </Row>
    );
};

export default PreGameContainer;
