import React from "react";
import { Row, Col, Typography, Button } from "antd";
import { JindoWhiteWithSplashIcon } from "../../../utils/Icons";

const { Text } = Typography;

const GameFooter = ({
    currentSong,
    onCallJamEnd,
    displaySong,
}) => {
    return (
        <Row
            className='game-footer-container'
            justify='space-between'
            align='middle'
            style={{ width: 375 }}
        >
            <Col span={21}>
                <Row align='middle'>
                    <Col>
                        <JindoWhiteWithSplashIcon
                            style={{ width: 64, height: 64 }}
                        />
                    </Col>
                    <Col style={{ overflowX: "hidden" }} span={18}>
                        {displaySong && (
                            <>
                                <Text
                                    className={`game-footer-song-title ${
                                        currentSong?.show &&
                                        currentSong?.title?.length > 25 &&
                                        "animate-left-right"
                                    }`}
                                >
                                    {currentSong?.show ? currentSong?.title : 'Now Playing...'}
                                </Text>
                                <Text
                                    className={`game-footer-song-meta ${
                                        currentSong?.show &&
                                        currentSong?.artist?.length > 25 &&
                                        "animate-left-right"
                                    }`}
                                >
                                    {currentSong?.show ? currentSong?.artist : ""}
                                </Text>
                            </>
                        )}
                    </Col>
                </Row>
            </Col>
            <Col>
                <Button
                    className='game-footer-jam-end-btn'
                    shape='circle'
                    onClick={onCallJamEnd}
                >
                    end
                </Button>
            </Col>
        </Row>
    );
};

export default GameFooter;
