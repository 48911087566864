import React from "react";
import {
    Col,
    Row,
    Form,
    Input,
    Button,
    Typography,
} from "antd";

const { Text } = Typography;

const UserUpdatePassword = ({ onPasswordChange }) => {
    const [form] = Form.useForm();
    return (
        <Row>
            <Col span={24}>
                <Form
                    form={form}
                    name='change-password'
                    onFinish={onPasswordChange}
                >
                    <Row align='middle' justify='center'>
                        <Col span={24}>
                            <Text
                                className='profile-accordian-heading'
                                style={{ margin: "7px 3px", fontSize: 15 }}
                                strong
                            >
                                Current Password
                            </Text>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='currentPassword'
                                style={{margin: 0}}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please Enter Current Password",
                                    },
                                ]}
                            >
                                <Input className='personal-form-input-field' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Text
                                className='profile-accordian-heading'
                                style={{ margin: "7px 3px", fontSize: 15 }}
                                strong
                            >
                                New Password
                            </Text>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='newPassword'
                                style={{margin: 0}}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter New Password",
                                    },
                                ]}
                            >
                                <Input className='personal-form-input-field' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Text
                                className='profile-accordian-heading'
                                style={{ margin: "7px 3px", fontSize: 15 }}
                                strong
                            >
                                Confirm New password
                            </Text>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='confirmPassword'
                                // noStyle
                                style={{margin: 0}}
                                dependencies={["newPassword"]}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please Enter Confirm New Password",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (
                                                !value ||
                                                getFieldValue("newPassword") ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                "The two passwords that you entered do not match!"
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input className='personal-form-input-field' />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Button
                                className='high-priority-btn sm-btn'
                                htmlType='submit'
                                style={{
                                    fontSize: 13,
                                    borderRadius: 5,
                                    marginTop: 12,
                                }}
                                block
                            >
                                save info
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

export default UserUpdatePassword;
