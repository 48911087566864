import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import { Row, Col, Typography } from "antd";
import ActionButton from "../common/ActionButton";
import {
    HomeIcon,
    ArrowIconSVG,
    PlayJamIconSVG,
    HostIconSVG,
    PlayersIcon,
} from "../../utils/Icons";

import { fetchUser } from "../../redux/actions/user";

const { Text } = Typography;

const AdminHeader = ({ onHost, isLoading, showTotalUsers }) => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.user);
    const { users } = useSelector((state) => state.users);

    useEffect(() => {
        dispatch(fetchUser());
    }, []);

    return (
        <Row className="playlist-table-sidebar-container">
            <Col span={24}>
                <Row justify="center">
                    <Col>
                    </Col>
                    <Col span={20}>
                        <Text className="game-info-section-header">
                            Welcome Back {userData && userData.userName}!
                        </Text>
                    </Col>
                </Row>
                {showTotalUsers && (
                    <Row justify="center" style={{ marginTop: "20px" }}>
                        <Col span={12}>
                            <Row className="songs-count-container">
                                <Col
                                    span={12}
                                    className="songs-count-icon-container"
                                >
                                    <PlayersIcon className="songs-count-icon" />
                                </Col>
                                <Col
                                    span={12}
                                    className="songs-count-text-container"
                                >
                                    <Text className="songs-count-text">
                                        {users && users.length}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </Col>

            <Col span={24}>
                <Row justify="center" gutter={[30, 30]}>
                    <Col span={18} className="center-items">
                        <ActionButton
                            title="user"
                            className="high-priority-btn"
                            onClick={() => console.log("save")}
                            prefixIconSVG={PlayJamIconSVG}
                            postfixIconSVG={ArrowIconSVG}
                            disabled={true}
                        />
                    </Col>
                    <Col span={18}>
                        <ActionButton
                            title="host"
                            className="medium-priority-btn"
                            onClick={onHost}
                            isLoading={isLoading}
                            prefixIconSVG={HostIconSVG}
                            postfixIconSVG={ArrowIconSVG}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default AdminHeader;
