import { createContext, useContext } from "react";

export const CollaborationContext = createContext(undefined)

export function useJamProvider() {
  const context = useContext(CollaborationContext)

  if(!context) {
    throw new Error('useJamProvider must be used within a CollaborationProvider')
  }

  return context.getProvider('jam')
}

export function useGameProvider(gameId) {
  const context = useContext(CollaborationContext)
  
  if(!context) {
    throw new Error('useGameProvider must be used within a CollaborationProvider')
  }
  
  return context.getProvider('game', gameId);
}