import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

import usePlayer from "../../../hooks/usePlayer";
import PreGameContainer from "../preGame/PreGameContainer";
import GamePlayContainer from "../gamePlay/GamePlayContainer";
import FallBackErrorMessage from "../../../components/common/FallBackErrorMessage";

const PreAndPlayGameContainer = ({ jam, navigate, activeGame, gameWinner }) => {
    const { 
        player,
        activePlayers, 
        updateCardLayout,
        updateCDModalState, 
        leaveGame,
        updateCallJindoStatus,
        checkForWinner
    } = usePlayer(activeGame.gameId);
    const [winnerModalPending, setWinnerModalPending] = useState(false);
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        window.addEventListener('online', () => setIsOnline(true));
        window.addEventListener('offline', () => setIsOnline(false));

        return () => {
            window.removeEventListener('online', () => setIsOnline(true));
            window.removeEventListener('offline', () => setIsOnline(false));
        }
    }, [])

    useEffect(() => {
        if (jam?.isFinished && activeGame?.winnerId !== player.id) {
            window.location = `/postGame/#${jam?.host?.id}`;
            return;
        }
        if (jam?.isFinished && activeGame?.winnerId === player.id && !winnerModalPending) {
            window.location = `/postGame/#${jam?.host?.id}`;
        }
    }, [jam, winnerModalPending])

    return activeGame && activeGame?.hasOwnProperty('gameId') && (
        <Row justify='center' align='top' className='game-container'>
            {!isOnline && <FallBackErrorMessage message={'Your Connection is disconnected.'}/>}
			{activeGame?.status !== 'STARTING_SOON' || winnerModalPending ? (
				<GamePlayContainer
					jam={jam}
					player={player}
                    leaveGame={leaveGame}
                    updateCardLayout={updateCardLayout}
                    updateCDModalState={updateCDModalState}
					navigate={navigate}
                    activeGame={activeGame}
                    gameWinner={gameWinner}
                    checkForWinner={checkForWinner}
					onWinnerSubmission={(val) => setWinnerModalPending(val)}
                    updateCallJindoStatus={updateCallJindoStatus}
				/>
			) : (
				<Col span={20}>
					{jam?.jamCode && 
                        <PreGameContainer 
                            jam={jam}
                            activeGame={activeGame}
                            activePlayersCount={activePlayers} 
                        />
                    }
				</Col>
			)}
        </Row>
    );
};

export default PreAndPlayGameContainer;
