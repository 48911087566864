import AuthService from "./AuthService";
import Http from "./HttpService";

export default class JamService {
    static async createJam(jamType) {
        const { userId } = AuthService.getCurrentUser();

        return await Http.post(
            "/jam/create",
            { userId, jamType },
            {
                headers: { authorization: localStorage.getItem("auth-token") },
            }
        );
    }

    static async updateJam(data) {
        const { userId } = AuthService.getCurrentUser();
        data.userId = userId;
        return await Http.put(`/jam/update/${data.jamId}`, data, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async fetchJamByJamId(jamId) {
        return await Http.post(`jam/fetch`, jamId, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async fetchByDateRange(range) {
        return await Http.post(
            `jam/fetchByDateRange?currentPage=${range.currentPage}&perPage=${range.perPage}&search=${range.search}`,
            range,
            {
                headers: { authorization: localStorage.getItem("auth-token") },
            }
        );
    }
}
