import {
    REQUEST_JAM_REPORT,
    FAILED_JAM_REPORT,
    SUCCESS_JAM_REPORT,
    RESET_JAM_REPORT,
} from "../actionTypes/reports";

const initialState = {
    reports: [],
    error: null,
    isLoading: false,
    errorMessage: null,
    perPage: 10,
    currentPage: 1,
    totalItems: 0,
    gamesCount: 0,
};

const reports = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_JAM_REPORT:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case FAILED_JAM_REPORT:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SUCCESS_JAM_REPORT:
            return {
                ...state,
                isLoading: false,
                reports: action.payload?.data?.jams,
                gamesCount: action.payload?.data?.gamesCount,
                perPage: action.payload?.paginationInfo?.perPage,
                currentPage: action.payload?.paginationInfo?.currentPage,
                totalItems: action.payload?.paginationInfo?.totalItems,
                error: null,
            };
        case RESET_JAM_REPORT:
            return {
                ...state,
                isLoading: false,
                reports: [],
                gamesCount: 0,
                error: null,
            };
        default:
            return state;
    }
};

export default reports;