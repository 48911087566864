import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import { Row, Col, Typography, Button } from "antd";
import {
    JindoWhiteLogo,
    PlayersJoinedIcon,
    CopyIcon,
} from "../../../utils/Icons";
import { Link } from "react-router-dom";

const { Text } = Typography;

const InviteFriends = ({
    handleCopyJamLink,
    jamCode,
    fromJamDeck,
    numOfPlayers,
    hostName,
    downLoadQR,
    handleGoBack,
}) => {
    const URL = `jamcode/#${jamCode}`;
    return (
        <Row justify='center'>
            <Col span={24}>
                <Text className='game-header-text'>INVITE PLAYERS</Text>
                <Text className='game-info-footer-text'>
                    Have them scan the QR code below
                </Text>
            </Col>
            <Col
                span={24}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 13,
                }}
            >
                <QRCodeCanvas
                    size={220}
                    id='qr-gen'
                    includeMargin={true}
                    value={`${window.location.origin}/${hostName}`}
                    fgColor='rgb(255,122,0)'
                    imageSettings={{
                        src: "/src/assets/media/shortcutIcon.png",
                        height: 50,
                        width: 50,
                        excavate: false,
                    }}
                    className='qr-code-canvas'
                />
            </Col>
            <Col span={14} style={{ display: "flex" }}>
                <Button
                    className='game-info-secondary-btn sm-btn'
                    block
                    onClick={handleCopyJamLink}
                    style={fromJamDeck && { padding: 0, marginRight: 5 }}
                >
                    COPY LINK
                    <CopyIcon style={{ height: 13, width: 13 }} />
                </Button>
                {fromJamDeck && (
                    <Button
                        className='game-info-secondary-btn sm-btn'
                        block
                        onClick={downLoadQR}
                        style={{ padding: 0, marginLeft: 5 }}
                    >
                        DOWNLOAD QR
                    </Button>
                )}
            </Col>
            <Col span={13}>
                <Text className='game-info-footer-text' strong>
                    or visit
                    <br />
                    <Text style={{color: '#FF14BD'}}>Jindo.Live/jamcode</Text>
                    <br />
                    and enter
                </Text>
                <Text className='game-info-jam-code'>{jamCode || '0000'}</Text>
            </Col>
            <Col span={24} style={{ marginTop: 30 }}>
                <Text className='game-info-content'>
                    <PlayersJoinedIcon style={{ width: 60, height: 60 }} />
                    <Text
                        className='game-info-players-count'
                        style={{ fontSize: 27 }}
                    >
                        {numOfPlayers || 0}
                    </Text>
                </Text>
                <Text
                    className='game-header-text'
                    style={{
                        fontSize: 25,
                        textTransform: "capitalize",
                        margin: 0,
                    }}
                >
                    Players Joined
                </Text>
            </Col>
            {!fromJamDeck && (
                <Col span={8}>
                    <Button className='game-info-secondary-btn' block onClick={handleGoBack} >
                        go back
                    </Button>
                </Col>
            )}
        </Row>
    );
};

export default InviteFriends;
