export function Song(key, title, artist, path, songId, comment) {
    this.key = key;
    this.title = title;
    this.artist = artist;
    this.path = path;
    this.songId = songId;
    this.comment = comment;
    this.song = {
        audio: new Audio("data:audio/x-wav;base64," + this.path),
        isPlaying: false,
    };
    this.isUpdated = false;
}

export const searchedSongs = (songs, searchTerm) => {
    return songs.filter(
        (song) =>
            song.title?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            song.artist?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            song.comment?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
};
