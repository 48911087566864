import React from "react";
import { Row, Col, Typography, Input } from "antd";
import { DiskIcon, PlaylistIcon } from "../../../utils/Icons";
import ActionButton from "../../common/ActionButton";
import { ArrowIconSVG, PlayJamIconSVG, SearchIcon } from "../../../utils/Icons";
import BackButton from "../../common/BackButton";

const { Text } = Typography;

const PlaylistTableSidebar = ({
    searchPlaylistTerm,
    setSearchPlaylistTerm,
    onCreatePlaylist,
    playlistCount,
}) => {
    return (
        <Row className="playlist-table-sidebar-container">
            <Col span={24}>
                <Row justify="center">
                    <Col
                        span={24}
                        className="playlist-table-sidebar-back-btn-container"
                    >
                        <BackButton />
                    </Col>
                    <Col>
                    </Col>
                    <Col span={20}>
                        <Text className="game-info-section-header">
                            Playlists
                        </Text>
                    </Col>
                    <Col span={12} className="playlist-table-count-container">
                        <Row className="songs-count-container">
                            <Col
                                span={12}
                                className="songs-count-icon-container"
                            >
                                <DiskIcon className="songs-count-icon" />
                            </Col>
                            <Col
                                span={12}
                                className="songs-count-text-container"
                            >
                                <Text className="songs-count-text">
                                    {playlistCount}
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Col span={24}>
                <Row justify="center" gutter={[20, 20]}>
                    <Col span={18}>
                        <Input
                            className="custom-playlist-table-search-input"
                            placeholder="Search"
                            value={searchPlaylistTerm}
                            onChange={(e) =>
                                setSearchPlaylistTerm(e.target.value)
                            }
                            prefix={<SearchIcon />}
                        />
                    </Col>
                    <Col span={18}>
                        <ActionButton
                            title="NEW PLAYLIST"
                            className="high-priority-btn"
                            onClick={onCreatePlaylist}
                            prefixIconSVG={PlayJamIconSVG}
                            postfixIconSVG={ArrowIconSVG}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default PlaylistTableSidebar;
