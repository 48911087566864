import { combineReducers } from "redux";

import authUser from "./authUser";
import playlist from "./playlist";
import patterns from "./patterns";
import jam from "./jam";
import game from "./game";
import playedSongs from "./playedSongs";
import player from "./player";
import gameWinner from "./gameWinner";
import hostEvents from "./hostEvents";
import users from "./users";
import reports from "./reports";


export default combineReducers({
    user: authUser,
    playlist,
    patterns,
    jam,
    game,
    playedSongs,
    player,
    gameWinner,
    hostEvents,
    users,
    reports
});
