import React, { useState, useEffect } from "react";
import { Row, Col, Button, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const { Text } = Typography;
const patternsArr = [
    {
        name: "Single",
        pattern: [1, 2, 3, 4, 5],
    },
    {
        name: "Double",
        pattern: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
        row: 2,
        column: 2,
        name: "Postage Stamp",
        pattern: [1, 2, 6, 7],
    },
    {
        name: "4 Corners",
        pattern: [1, 5, 21, 25],
    },
    {
        name: "X",
        pattern: [1, 7, 13, 19, 25, 5, 9, 17, 21],
    },
    {
        name: "Blackout",
        pattern: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25,
        ],
    },
];

const renderGridLines = (index, pattern, gameId) => {
    let row = [];
    for (const col of Array(5).keys()) {
        if (pattern?.includes(index++)) {
            row.push(
                <Col key={index} className={gameId ? 'jam-deck-selected-active-box':'jam-deck-pattern-active-box'}></Col>
            );
        } else {
            row.push(
                <Col key={index} className={gameId ? 'jam-deck-pattern-selected-normal-box' : 'jam-deck-pattern-normal-box'}></Col>
            );
        }
    }
    return (
        <Row key={index} justify='center'>
            {row}
        </Row>
    );
};

const renderPatternGrid = (patternName = "", gameId) => {
    const patternGrid = [];
    const pattern = patternsArr.filter(
        (pattern) => pattern?.name === patternName
    )[0];
    for (let index of [1, 6, 11, 16, 21]) {
        patternGrid.push(renderGridLines(index, pattern?.pattern || [], gameId));
    }
    return patternGrid;
};

const JamPattern = ({ handlePatternChange, patterns, activePattern, gameId }) => {
    const [patternIndex, setPatternIndex] = useState(0);
    const [currentPattern, setCurrentPattern] = useState({name: activePattern, pattern: []});

    useEffect(() => {
        let ptr = patternsArr.findIndex(
            (pattern) => pattern.name === activePattern
        );
        setPatternIndex(ptr);
    }, []);

    useEffect(() => {
        if(activePattern) {
            setCurrentPattern(patternsArr.filter(ptr => ptr.name === activePattern)[0])
        }
        
    }, [activePattern]);

    const handleClick = (action = "") => {
        let prtName = patterns[patternIndex];
        if (action === "next" && patternIndex < patterns.length) {
            setPatternIndex(patternIndex + 1);
            prtName = patterns[patternIndex + 1];
            setCurrentPattern(
                patternsArr.filter((pattern) => pattern.name === prtName)[0]
            );
        } else if (action === "prev" && patternIndex > -1) {
            setPatternIndex(patternIndex - 1);
            prtName = patterns[patternIndex - 1];
            setCurrentPattern(
                patternsArr.filter((pattern) => pattern.name === prtName)[0]
            );
        }

        handlePatternChange(
            patternsArr.filter((pattern) => pattern.name === prtName)[0]
        );
    };

    const getPatternName = (pattern) => {
        if(pattern === 'Single') return 'ANY SINGLE LINE';
        if(pattern === 'Double') return 'ANY 2 LINES';
        if(pattern === 'Postage Stamp') return 'BOX 4 ANYWHERE';
        return pattern;
    }

    return (
        <Row justify='center'>
            <Col span={24}>
                <Row align='middle' justify='center'>
                    {!gameId && <Col>
                        <Button
                            icon={<LeftOutlined />}
                            type='link'
                            size='large'
                            onClick={() => handleClick("prev")}
                            disabled={patternIndex <= 0}
                        />
                    </Col>}
                    <Col>{renderPatternGrid(activePattern, gameId)}</Col>
                    {!gameId && <Col>
                        <Button
                            icon={<RightOutlined />}
                            type='link'
                            size='large'
                            onClick={() => handleClick("next")}
                            disabled={patternIndex >= patterns.length - 1}
                        />
                    </Col>}
                </Row>
            </Col>
            <Col className='jam-deck-pattern-text' >
                <Text style={{color: gameId ? '#606060' : '#FFFFFF', textShadow: gameId && "none"}}>
                    {getPatternName(currentPattern?.name)}
                </Text>
            </Col>
        </Row>
    );
};

export default JamPattern;
