import React, { useState } from "react";
import { Row, Col } from "antd";
import Logout from "../../components/auth/Logout";

const LogoutContainer = ({ navigate }) => {
  const onStay = () => {
    navigate(-1);
  };

  const onLogout = () => {
    localStorage.clear();
    window.location = "/login";
  };
  return (
    <Row justify='center' align='stretch' className='action-container-wrapper'>
      <Col>
        <Logout onStay={onStay} onLogout={onLogout} />
      </Col>
    </Row>
  );
};

export default LogoutContainer;
