import * as Sentry from "@sentry/react";
import axios from "axios";
import {
    INITIALIZE_GAME_REQUREST,
    FAILED_GAME_REQUEST,
    FETCHED_GAMES,
    FETCHED_ACTIVE_GAME,
    RESET_GAME,
    FETCHED_ACTIVE_GAME_PLAYERS,
    FETCHED_ACTIVE_GAME_PLAYER_INFO,
    GO_TO_NEXT_GAME,
    JAM_ENDED,
    NUMBER_OF_PLAYERS,
    RESET_ACTIVE_GAME,
    IS_GAME_PAUSED,
    IS_GAME_RESUMED,
    CURRENT_SONG_FINISHED,
} from "../actionTypes/game";

import GameService from "../../services/GameService";
import { setNextSong, setPlayedSongs } from "./playedSongs";
import ShowToast from "../../utils/ShowToast";

const initializeGameRequest = () => {
    return {
        type: INITIALIZE_GAME_REQUREST,
    };
};

const failedGameRequest = (payload) => {
    ShowToast(payload.error, "error");
    return {
        type: FAILED_GAME_REQUEST,
        payload,
    };
};

const fetchedGames = (payload) => {
    return {
        type: FETCHED_GAMES,
        payload,
    };
};

const fetchedActiveGame = (payload) => {
    return {
        type: FETCHED_ACTIVE_GAME,
        payload,
    };
};

const fetchedActveGamePlayersInfo = (payload) => {
    return {
        type: FETCHED_ACTIVE_GAME_PLAYERS,
        payload,
    };
};

const fetchActiveGamePlayerInfo = (payload) => {
    return {
        type: FETCHED_ACTIVE_GAME_PLAYER_INFO,
        payload,
    };
};

export const getAnimationStatus = () => {
    return JSON.parse(localStorage.getItem("animationLoaded"));
};

export const setAnimationStatus = (payload) => {
    return localStorage.setItem("animationLoaded", payload);
};

export const removeAnimationStatus = () => {
    return localStorage.removeItem("animationLoaded");
};

export const resetGame = () => {
    return {
        type: RESET_GAME,
    };
};

export const goToNextGame = () => {
    return {
        type: GO_TO_NEXT_GAME,
    };
};

export const jamEnded = () => {
    return {
        type: JAM_ENDED,
    };
};

const setNumberOfPlayers = (payload) => {
    return {
        type: NUMBER_OF_PLAYERS,
        payload,
    };
};

export const resetActiveGame = () => {
    return {
        type: RESET_ACTIVE_GAME,
    };
};

export const gamePaused = (payload) => {
    return {
        type: IS_GAME_PAUSED,
        payload,
    };
};

export const gameResumed = (payload) => {
    return {
        type: IS_GAME_RESUMED,
        payload,
    };
};

export const currentSongFinished = (payload) => {
    return {
        type: CURRENT_SONG_FINISHED,
        payload,
    };
};

export const fetchGames = (jamId) => {
    return async (dispatch) => {
        try {
            dispatch(initializeGameRequest());
            const { data } = await GameService.fetchGames(jamId);
            const games = data.games.filter((game) => game.status != "SKIPPED");
            dispatch(fetchedGames(games));
        } catch (error) {
            dispatch(failedGameRequest(error?.response));
            Sentry.captureException(error)
        }
    };
};

export const fetchActiveGame = (activeGameInput) => {
    return async (dispatch) => {
        try {
            dispatch(initializeGameRequest());
            const { data } = await GameService.fetchActiveGame(activeGameInput);
            if (
                data.hasOwnProperty("activeGame") &&
                data.activeGame &&
                data.activeGame.hasOwnProperty("playedSongs")
            ) {
                data.activeGame.host = data.host;
                dispatch(fetchedActiveGame(data.activeGame));
                const { currentSong, playedSongs } =
                    data.activeGame.playedSongs;

                let ifCurrentSongExist = false;

                if (currentSong !== null) {
                    ifCurrentSongExist = playedSongs.some(
                        (song) => song.songId === currentSong.songId
                    );
                    if (ifCurrentSongExist) {
                        dispatch(setPlayedSongs(playedSongs));
                        dispatch(setNextSong(null));
                    } else {
                        dispatch(setPlayedSongs([...playedSongs, currentSong]));
                        dispatch(setNextSong(currentSong));
                    }
                }
            }
            if (data?.activeGame?.jamEnded) {
                dispatch(
                    failedGameRequest({
                        error:
                            data?.activeGame && data?.activeGame?.jamEnded
                                ? "Jam has ended"
                                : "Something went wrong",
                    })
                );
            }
        } catch (err) {
            if(axios.isAxiosError(err)) {
                let { error } = err.response.data;
                if (error.includes("Cannot")) {
                    error = "Invalid Jam Code";
                } else {
                    error = "Something went wrong";
                }
                dispatch(failedGameRequest({ error }));
                
            } else {
                dispatch(failedGameRequest({ error: 'Failed to fetch Jam' }));
            }
            Sentry.captureException(err)
        }
    };
};

export const updateJamGames = (updateJamGamesInput) => {
    return async (dispatch) => {
        try {
            dispatch(initializeGameRequest());
            await GameService.updateGames(updateJamGamesInput);
            dispatch(fetchGames(updateJamGamesInput.jamId));
        } catch (error) {
            if(axios.isAxiosError(error)) {
                dispatch(failedGameRequest(error?.response.data.error));
            }
            Sentry.captureException(error)
        }
    };
};

export const updateJamGame = (updateJamGameInput) => {
    return async (dispatch) => {
        try {
            dispatch(initializeGameRequest());
            await GameService.updateGame(updateJamGameInput);
        } catch (error) {
            if(axios.isAxiosError(error)) {
                dispatch(failedGameRequest(error?.response.data.error));
            }
            Sentry.captureException(error)
        }
    };
};

export const fetchGamePlayers = (gameId) => {
    return async (dispatch) => {
        try {
            dispatch(initializeGameRequest());
            const { data } = await GameService.fetchGamePlayersInfo(gameId);
            dispatch(fetchedActveGamePlayersInfo(data.gamePlayers));
        } catch (error) {
            if(axios.isAxiosError(error)) {
                dispatch(failedGameRequest(error?.response.data.error));
            }
            Sentry.captureException(error)
        }
    };
};

export const gameWinnerInfo = (playerInfo) => {
    return async (dispatch) => {
        try {
            return await GameService.gameWinner(playerInfo);
        } catch (error) {
            if(axios.isAxiosError(error)) {
                dispatch(failedGameRequest(error?.response.data.error));
            }
            
            Sentry.captureException(error)
        }
    };
};

export const getNumberOfPlayers = (numberOfPlayers) => {
    return async (dispatch) => {
        dispatch(setNumberOfPlayers(numberOfPlayers));
    };
};
