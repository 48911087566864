import React from "react";
import { JindoWhiteWithSplashIcon } from "../../../utils/Icons";
import { Row, Col, Typography, Divider } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;

const PostGame = ({ playerData, venmoLink, googleReviewLink }) => {

    return (
        <Row justify='center' className='post-game-container'>
            <Col span={24} style={{ textAlign: "center" }}>
                <JindoWhiteWithSplashIcon style={{ width: 200, height: 175 }} />
            </Col>
            <Col span={20}>
                <Text className='game-header-text'>
                    Thank you{" "}
                    <Text style={{ color: "#FFC930", textTransform: 'none' }}>{playerData?.name}</Text> for
                    playing jindo!
                </Text>
            </Col>
            <Col span={24}>
                <Text
                    className='game-helper-subtext'
                    style={{ margin: 0, fontSize: 19, marginTop: 20 }}
                >
                    Did you have a good time?
                </Text>
                <Text
                    className='game-helper-subtext'
                    style={{ margin: 0, fontSize: 19, marginBottom: 25 }}
                >
                    Show some love to today’s host:
                </Text>
            </Col>
            <Col span={20}>
                <a
                    className='high-priority-btn'
                    href={venmoLink}
                    target="_blank"
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    THANK HOST WITH A TIP
                </a>
            </Col>
            <Col span={24}>
                <Divider plain className='post-game-divider'>
                    or
                </Divider>
            </Col>
            <Col span={20}>
                <a
                    className='medium-priority-btn'
                    target="_blank"
                    href={googleReviewLink}
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    LEAVE A REVIEW
                </a>
            </Col>
            <Col span={20}>
                <a
                    className='post-game-transparent-btn'
                    href="https://www.playjindo.com/"
                    target="_blank"
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    no thank you...end game
                </a>
            </Col>
        </Row>
    );
};

export default PostGame;
