import {
    REQUEST_USER_AUTH,
    FAILED_USER_AUTH,
    SUCCESS_USER_AUTH,
    RESET_USER,
    SUCCESS_USER_VERIFY,
} from "../actionTypes/authUser";

import {
    REQUEST_USER_INFO,
    SUCCESS_USER_INFO,
    FAILED_USER_INFO,
    UPDATE_USER_INFO,
} from "../actionTypes/user";

const initialState = {
    userData: null,
    token: null,
    isLoading: true,
    error: null,
    isError: false,
    isUpdated: false
};

const authUser = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_USER_AUTH:
            return {
                ...state,
                error: null,
                isLoading: true,
            };

        case REQUEST_USER_INFO: {
            return {
                ...state,
                error: null,
                isLoading: true,
                isUpdated: false,
                isError: false
            };
        }

        case SUCCESS_USER_AUTH:
            const { token, user } = action.payload;
            return {
                ...state,
                token,
                userData: user,
                error: null,
                isLoading: false,
            };

        case SUCCESS_USER_INFO: {
            return {
                ...state,
                isLoading: false,
                userData: action.payload,
                error: null,
            };
        }

        case SUCCESS_USER_VERIFY: {
            return {
                ...state,
                isLoading: false,
                userData: action.payload,
                error: null,
            };
        }

        case FAILED_USER_AUTH:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case FAILED_USER_INFO: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                isError: true
            };
        }

        case UPDATE_USER_INFO:
            return {
                ...state,
                error: null,
                isLoading: false,
                isUpdated: true,
                isError: false
            };

        case RESET_USER:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default authUser;
