import { Row, Col, Typography } from "antd";
import React from "react";
import { ArrowIconSVG, UserIconSVG } from "../../utils/Icons";
import ActionButton from "../common/ActionButton";

const { Text } = Typography;

const ResendForgetPassword = ({onSubmit}) => {
  return (
    <Row justify='center'>
      <Col span={24} className='forget-password-form-container'>
        <Row justify='center'>
          <Col>
          </Col>
          <Col span={24} className='auth-form-sub-text'>
            <Text>Check your EMAIL or resend LINK</Text>
          </Col>
          <Col span={18} className='resend-code-btn-container'>
            <ActionButton
                className='high-priority-btn'
                prefixIconSVG={UserIconSVG}
                postfixIconSVG={ArrowIconSVG}
                title='RESEND LINK'
                onClick={onSubmit}
              />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ResendForgetPassword;
