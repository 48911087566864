import { Typography, Space } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { BackArrowIcon } from "../../utils/Icons";

const { Text } = Typography;

const BackButton = () => {
    const navigate = useNavigate();

    const onBack = () => navigate(-1);

    return (
        <Space onClick={onBack} size={5} className="app-header-back-btn">
            <BackArrowIcon className="back-btn-icon" />
            <Text className="back-btn-text">BACK</Text>
        </Space>
    );
};

export default BackButton;
