import { createContext, useContext } from "react";

export const AwarenessContext = createContext(undefined)

export function useAwareness() {
    const context = useContext(AwarenessContext)
  
    if(!context) {
      throw new Error('useAwareness must be used within a AwarenessContext.Provider')
    }
    
    return context
}