import { useEffect, useState } from 'react';
import useRound from './useRound';

export default function useRoundPlaylistId(roundId) {
    const [playlistId, _setPlaylistId] = useState(undefined)
    const {setFieldInRound, getFieldFromRound} = useRound(roundId)
    
    useEffect(() => {
      // sync playlistId with the value in rounds map in document
      _setPlaylistId(getFieldFromRound('playlistId'))
    }, [roundId])

    /**
     * Sets playlistId to a given value
     * @param {string} value - New value for the playlistId
     */
    const setPlaylistId = (value) => {
      setFieldInRound('playlistId', value)
      _setPlaylistId(value)
    }

    return {playlistId, setPlaylistId}
}