import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Typography, Button, Progress } from "antd";
import {
    JindoWhiteWithSplashIcon,
    PauseIcon,
    PlayIcon,
} from "../../../utils/Icons";
import { useInterval } from "react-use";
import MobileDetect from "mobile-detect";
import {isMobile} from 'react-device-detect';
import useActiveGame from "../../../hooks/useActiveGame";

import songPlayService from "../../../services/SongPlayService";
import PlaylistService from "../../../services/PlaylistService";

const { Text } = Typography;

const JamDeckMediaPlayer = ({
    activeGame,
    gameWinner,
    songPlayStatus,
    playPauseEffect,
    togglePlayPauseEffect,
    speakerVolume,
    lobbySoundRef,
    selectedSpeaker,
    onTogglePlayPause,
    onJamEnd,
    activeButton,
}) => {
    const mobileDevice = new MobileDetect(window.navigator.userAgent);
    const targetAppleDevices = mobileDevice.is("Iphone") || mobileDevice.is("iPad");

    const {nextSong, displaySong} = useActiveGame();
    const [playlist, setPlaylist] = useState(null);
    const [songProgress, setSongProgress] = useState(0);
    const [currentSong, setCurrentSong] = useState(null);
    const songsAudio = useRef();

    const gamePauseSoundRef = useRef(new Audio());
    const gameOnSoundRef = useRef(new Audio());

    const [halfSongPlayed, setHalfSongPlayed] = useState(false);

    useInterval(
        () => {
            if (songPlayStatus === "play") {
                if (
                    !isNaN(songsAudio.current.duration) &&
                    songsAudio.current.currentTime >
                        songsAudio.current.duration / 2 - 1.8
                ) {
                    setHalfSongPlayed(true);
                }
            }
        },
        songPlayStatus === "play" ? 1000 : null
    );

    useEffect(() => {
        if (
            !!window.chrome &&
            (!!window.chrome.webstore || !!window.chrome.runtime) &&
            !isMobile
        ) {
            gamePauseSoundRef.current.setSinkId(selectedSpeaker);
            gameOnSoundRef.current.setSinkId(selectedSpeaker);
            songsAudio.current.setSinkId(selectedSpeaker);
        }
    }, [selectedSpeaker]);

    useEffect(() => {
        gamePauseSoundRef.current.src = `${process.env.SOUND_EFFECTS_URL}gamePause.mp3`;
        gameOnSoundRef.current.src = `${process.env.SOUND_EFFECTS_URL}gameOn.mp3`;
    }, []);

    useEffect(() => {
        gamePauseSoundRef.current.volume = speakerVolume;
        gameOnSoundRef.current.volume = speakerVolume;
        songsAudio.current.volume = speakerVolume;
    }, [speakerVolume]);

    useEffect(() => {
        if (!playlist && activeGame?.hasOwnProperty("gameId") && !gameWinner?.isFinished) {
            PlaylistService.fetchPlaylist(
                activeGame.playlistId,
                activeGame.numberOfSongs,
                activeGame.key
            ).then((resp) => {
                setPlaylist(resp.data);
                setCurrentSong(null);
            })
        }
    }, [activeGame, playlist, gameWinner]);

    useEffect(() => {
        if (halfSongPlayed) {
            displaySong()
        }
    }, [halfSongPlayed]);

    useEffect(() => {
        if (
            playlist &&
            playlist.hasOwnProperty("songs") &&
            playlist.songs.length > 0 &&
            currentSong === null
        ) {
            setCurrentSong(nextSong(playlist?.songs));
        }
    }, [playlist, currentSong]);

    useEffect(() => {
        if (currentSong) {
            songPlayService.setAudioSource(
                songsAudio.current,
                currentSong.songId
            );
            setHalfSongPlayed(false);
            songPlayPauseAction();
        }
    }, [currentSong]);

    useEffect(() => {
        if(gameWinner && gameWinner?.isFinished) {
            songsAudio.current.pause();
            setPlaylist(null)
            setCurrentSong(null)
        }
    }, [gameWinner])

    useEffect(() => {
        songPlayPauseAction();
    }, [songPlayStatus]);

    useEffect(() => {
        songsAudio.current.onended = () => {
            setCurrentSong(nextSong(playlist?.songs));
        };
    }, [playlist]);

    const songPlayPauseAction = async () => {
        if (songPlayStatus === "play" && currentSong) {
            if (playPauseEffect) {
                if (!targetAppleDevices) {
                    lobbySoundRef.current.pause();
                    await gameOnSoundRef.current.play();
                    gameOnSoundRef.current.onended = () => {
                        togglePlayPauseEffect();
                        songPlayService.playSong(
                            songsAudio.current,
                            speakerVolume
                        );
                    };
                } else {
                    lobbySoundRef.current.pause();
                    songPlayService.playSong(songsAudio.current, speakerVolume);
                }
            } else {
                await playingSongWithCounter();
            }
        } else if (songPlayStatus === "pause") {
            if (playPauseEffect) {
                if (!targetAppleDevices) {
                    songPlayService.pauseSong(songsAudio.current);
                    gamePauseSoundRef.current.play();
                    gamePauseSoundRef.current.onended = () => {
                        lobbySoundRef.current.play();
                    };
                } else {
                    songPlayService.pauseSong(songsAudio.current, true);
                    lobbySoundRef.current.play();
                }
            }
        }
    };

    const playingSongWithCounter = async (counter = 1) => {
        const isSongPlaying = await songPlayService.playSong(
            songsAudio.current,
            speakerVolume
        );
        if (!isSongPlaying && counter < 4) {
            console.log(`playing song again ${counter} time`);
            songPlayService.setAudioSource(
                songsAudio.current,
                currentSong.songId
            );
            await playingSongWithCounter(counter + 1);
        }
    };

    const JamStatus = (status) => {
        switch (status) {
            case "play":
                return <PauseIcon />;
            case "pause":
                return <PlayIcon />;
            default:
                return "start";
        }
    };

    return (
        <>
            <audio
                ref={songsAudio}
                onTimeUpdate={(event) => {
                    setSongProgress(
                        (event.currentTarget.currentTime /
                            event.currentTarget.duration) *
                            100
                    );
                }}
                onError={(error) =>
                    console.log(error, "error while playing song")
                }
            />
            {activeButton === 'songStreaming' && <Row
                className='game-footer-container'
                justify='space-between'
                align='middle'
                style={{ paddingBottom: 0, position: 'relative', width: 400 }}
            >
                <Col span={18}>
                    <Row align='middle'>
                        <Col>
                            <JindoWhiteWithSplashIcon
                                style={{ width: 64, height: 64 }}
                            />
                        </Col>
                        <Col style={{overflowX: 'hidden'}} span={17} >
                            <Text className={`game-footer-song-title ${currentSong?.title?.length > 25 && 'animate-left-right'}`}>
                                {currentSong?.title}
                            </Text>
                            <Text className={`game-footer-song-meta  ${currentSong?.artist?.length > 25 && 'animate-left-right'}`}>
                                {currentSong?.artist}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <Row justify='space-between' align='middle' gutter={[15, 10]}>
                        <Col>
                            <Button
                                className='game-footer-jam-start-btn'
                                shape='circle'
                                onClick={onTogglePlayPause}
                            >
                                {JamStatus(songPlayStatus)}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className='game-footer-jam-end-btn'
                                shape='circle'
                                onClick={onJamEnd}
                            >
                                end
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Progress
                        className='song-progess'
                        percent={songProgress}
                        showInfo={false}
                    />
                </Col>
            </Row>}
        </>
    );
};

export default JamDeckMediaPlayer;
