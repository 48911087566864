import { useEffect, useState } from "react";
import { useJamProvider } from "../context/collaborationContext";
import JamService from "../services/JamService";

export default function useJam() {
    const [jam, _setJam] = useState(null)
    const { document } = useJamProvider()

    useEffect(() => {
      const jamMap = document.getMap('jam')

      const update = () => {
        // sync jam state with the changes to the document
        _setJam(Object.fromEntries(jamMap.entries()))
      }

      // observe changes to the jam map in document
      jamMap.observe(update);

      return () => {
        jamMap.unobserve(update);
        _setJam(null)
      };
    }, [document])

    /**
     * Sets a key in jam with a value
     * @param {string} key - The key in jam to update e.g name, time, etc.
     * @param {value} value - Value to update the key with.
     */
    const setKeyInJam = (key, value) => {
      const jamMap = document.getMap('jam')
      jamMap.set(key, value)
    }

    const endTheJam = async () => {
      return await JamService.updateJam({
          ...jam,
          isFinished: true,
          status: false,
          jamCode: "",
      });
    }

    return { jam, setKeyInJam, endTheJam }
}