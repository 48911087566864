import Http from "./HttpService";

export default class PlayerService {
    static async playerCreate(playerInputData) {
        return await Http.post("/player/create", playerInputData, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async getPlayerGame(playerId, game) {
        return await Http.post(`/player/fetchPlayerGame/${playerId}`, {game}, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async deactivatePlayer(playerId, gameId) {
        return await Http.put("/player/deactivate", {playerId, gameId});
    }

}