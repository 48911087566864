import React from "react";
import { Row, Col, Typography } from "antd";
import ActionButton from "../common/ActionButton";
import {
    ArrowIconSVG,
    MessageIconSVG,
    AuthIconSVG,
    JindoWhiteLogo,
} from "../../utils/Icons";

const { Text } = Typography;

const Logout = ({ onStay, onLogout }) => {
    return (
        <Row justify='center' align='top' gutter={[0, 22]}>
            <Col>
                <JindoWhiteLogo style={{ width: 130, height: 94, marginTop: 32 }} />
            </Col>
            <Col span={24} className='auth-form-sub-text'>
                <Text>STAY in Jindo or LOGOUT?</Text>
            </Col>
            <Col span={12} style={{marginTop: 75}} >
                <ActionButton
                    className='high-priority-btn'
                    title='stay'
                    htmlType='submit'
                    prefixIconSVG={MessageIconSVG}
                    postfixIconSVG={ArrowIconSVG}
                    onClick={onStay}
                />
                <Col span={24} className='auth-form-sub-text' style={{margin: 10}}>
                    <Text>OR</Text>
                </Col>
                <ActionButton
                    className='low-priority-btn'
                    title='logout'
                    htmlType='submit'
                    prefixIconSVG={AuthIconSVG}
                    postfixIconSVG={ArrowIconSVG}
                    onClick={onLogout}
                />
            </Col>
        </Row>
    );
};

export default Logout;
