import {
    SET_WINNER,
    RESET_WINNER,
} from "../actionTypes/gameWinner";

const initialState = {
    winnerName: null,
    amIWinner: false,
};

const gameWinner = (state = initialState, action) => {
    switch (action.type) {
        case SET_WINNER:
            return {
                amIWinner: action.payload.amIWinner,
                winnerName: action.payload.playerName,
            };
        case RESET_WINNER:
            return initialState;
        default:
            return state;
    }
};

export default gameWinner;
