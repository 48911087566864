import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { Col, Row, Form } from "antd";

import UpdatePasswordForm from "../../components/auth/UpdatePasswordForm";
import { resetAuthUser, updateNewPassword } from "../../redux/actions/authUser";

import ShowToast from "../../utils/ShowToast";

const decodedToken = (token) => {
    try {
        const { user } = jwtDecode(token);
        return user;
    } catch (ex) {
        return null;
    }
};

const UpdatePasswordFormContainer = ({ navigate }) => {
    const dispatch = useDispatch();

    const token = window.location.search.substring(1).split("=")[1];
    const [updatePasswordFormRef] = Form.useForm();

    const { error } = useSelector((state) => state.user);

    useEffect(() => {
        if (error) {
            ShowToast(error, "error");
            dispatch(resetAuthUser());
        }
    }, [error]);

    const handleFormSubmission = async (values) => {
        try {
            new Promise((resolve) => {
                resolve(
                    dispatch(
                        updateNewPassword({
                            resetToken: token,
                            newPassword: values.newPassword,
                            userInfo: decodedToken(token),
                        })
                    )
                );
            }).then(() => {
                navigate("/");
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Row
            justify='center'
            align='stretch'
            className='action-container-wrapper'
        >
            <Col span={20} style={{ display: "flex" }}>
                <UpdatePasswordForm
                    onSubmit={handleFormSubmission}
                    form={updatePasswordFormRef}
                />
            </Col>
        </Row>
    );
};

export default UpdatePasswordFormContainer;
