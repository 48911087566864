import React from "react";
import { Modal, Row, Col, Button, Space, Typography } from "antd";
import { QuitIcon, BigRedSplashIcon } from "../../utils/Icons";

const { Text } = Typography;

const FallBackErrorMessage = ({message = 'It appears you experienced a critical error.'}) => {
    return (
        <Modal
            className='game-splash-modal'
            visible={true}
            closable={false}
            footer={false}
            mask={true}
            destroyOnClose={true}
        >
            <Row justify='center' align='bottom'>
                <Col span={24} className='game-splash-title-container'>
                    <Row
                        justify='center'
                        align='middle'
                        className='game-play-splash-component'
                    >
                        <Col span={24}>
                            <BigRedSplashIcon
                                className='message-splash'
                                style={{
                                    opacity: 1,
                                }}
                            />
                        </Col>
                        <Col className='game-play-splash-container' span={24}>
                            <Space
                                direction='vertical'
                                style={{ textAlign: "center" }}
                            >
                                <QuitIcon className='message-splash-icon' />

                                <Text className={"message-splash-sub-text"}>
                                    {`${message} Please rejoin game by clicking Refresh button.`}
                                </Text>
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col style={{ position: "fixed", bottom: 85, zIndex: 1000 }}>
                    <Row className='jam-end-splash-footer' justify={'center'}>
                        <Col span={24}>
                            <Button
                                block
                                className='jam-end-primary-btn'
                                onClick={() => window.location.reload()}
                            >
                                Refresh
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default FallBackErrorMessage;
