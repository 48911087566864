import HttpService from "./HttpService";
import AuthService from "./AuthService";

export default class UserService {
    static async update(data, queryParam = false) {
        const { userId } = AuthService.getCurrentUser();
        return await HttpService.post(
            `/user/update/${userId}${queryParam ? "?type=" + queryParam : ""}`,
            data,
            { headers: { authorization: localStorage.getItem("auth-token") } }
        );
    }

    static async updatePassword(data) {
        return await HttpService.put(`/user/update/password`, data, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async updateUser(data) {
        return await HttpService.post(`/user/update/${data.userId}`, data, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async deleteUser(userId) {
        return await HttpService.delete(`/user/remove/${userId}`, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async fetchUser() {
        const { userId } = await AuthService.getCurrentDecodeToken();
        return await HttpService.get(`/user/fetch/${userId}`, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async fetchUserById(userId) {
        return await HttpService.get(`/user/fetch/${userId}`, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async fetchAllUsers(userId) {
        return await HttpService.get(`/user/fetch/all`, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }
}
