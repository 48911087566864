import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import JamService from "../../../services/JamService";
import { useParams } from "react-router";
import FallBackErrorMessage from "../../../components/common/FallBackErrorMessage";
import CollaborationProvider from "../../../components/CollaborationProvider";
import { UserContext } from "../../../context/userContext";
import GameContainer from "./GameContainer";

const GameProvider = () => {
    const { userName } = useParams();
    const [jam, setJam] = useState(null);
    const [playerData, setPlayerData] = useState(null);

    useEffect(() => {
      const player = JSON.parse(localStorage.getItem("player-data"));
      setPlayerData(player);
      if (!player) {
        window.location = `/playerSetup/#${userName}`;
      } else {
        JamService.fetchJamByJamId({userName}).then((response) => {
            if(response.status === 200) {
                setJam(response.data.jam);
            }
        })
      }
      
    }, []);

    useEffect(() => {
        if(jam !== null && !jam?.jamCode) {
            window.location = `/postGame/#${jam?.userId}`;
        }
    }, [jam])

    if (!jam?.jamCode) {
        return `System Is Looking for Host: ${userName} Active Jam. Please wait...`;
    }
    return jam && jam?.jamCode && (
        <Sentry.ErrorBoundary>
            <UserContext.Provider value={playerData}>
                <CollaborationProvider isHost={false} jamId={jam.jamId}>
                    <GameContainer playerId={playerData.playerId} />
                </CollaborationProvider>
            </UserContext.Provider>
        </Sentry.ErrorBoundary>
    );
};

export default GameProvider;
