import { useEffect, useState } from "react";
import useRound from "./useRound";

export default function useRoundWinningPattern(roundId) {
    const [patternName, _setPatternName] = useState('Single');
    const {setFieldInRound, getFieldFromRound} = useRound(roundId);

    useEffect(() => {
      // sync numberOfSongs with the value in rounds map in document
      _setPatternName(getFieldFromRound('patternName'))
    }, [roundId])

    /**
     * Sets patternName to a given value
     * @param {string} value - New value for the pattern
     */
    const setPatternName = (value) => {
      setFieldInRound('patternName', value)
      _setPatternName(value)
    }

    return {patternName, setPatternName}
}