import * as Sentry from "@sentry/react";
import {
    INITIALIZE_JAM_REQUREST,
    SUCCESS_JAM_REQUEST,
    FAILED_JAM_REQUEST,
    RESET_JAM
} from "../actionTypes/jam";

import JamService from "../../services/JamService";
import axios from "axios";

const initializeJamRequest = () => {
    return {
        type: INITIALIZE_JAM_REQUREST,
    };
};

const successJamRequest = (payload) => {
    return {
        type: SUCCESS_JAM_REQUEST,
        payload,
    };
};

const failedJamRequest = (payload) => {
    return {
        type: FAILED_JAM_REQUEST,
        payload,
    };
};

export const resetJam = ()=>{
    return {
        type: RESET_JAM
    }
}

export const fetchJam = (jamId) => {
    return async (dispatch) => {
        try {
            dispatch(initializeJamRequest());
            const { data } = await JamService.fetchJamByJamId({jamId});
            dispatch(successJamRequest(data.jam));

        } catch (error) {
            if(axios.isAxiosError(error)) {
                dispatch(failedJamRequest(error.response.data.error));
            }
            Sentry.captureException(error)
        }
    };
};

export const fetchJamByUserName = (userName) => {
    return async (dispatch) => {
        try {
            dispatch(initializeJamRequest());
            const { data } = await JamService.fetchJamByJamId({userName});
            dispatch(successJamRequest(data.jam));
        } catch (error) {
            if(axios.isAxiosError(error)) {
                dispatch(failedJamRequest(error.response.data.error));
            }
            
            Sentry.captureException(error)
        }
    };
};

export const createJam = (jamInput) => {
    return async (dispatch) => {
        try {
            dispatch(initializeJamRequest());
            const { data } = await JamService.createJam(jamInput);
            dispatch(successJamRequest(data.jam));
        } catch (error) {
            dispatch(failedJamRequest());
            Sentry.captureException(error)
        }
    };
};

export const fetchJamId = async (jamType) => {
    try {
        const { data } = await JamService.createJam(jamType);
        return data.jam;
    } catch (error) {
        return error;
    }
};

export const updateJam = (jamInput, fetchJamCall = true) => {
    return async (dispatch) => {
        try {
            dispatch(initializeJamRequest());
            await JamService.updateJam(jamInput)
            if(fetchJamCall){
                dispatch(fetchJam(jamInput.jamId))
            }
        } catch (error) {
            dispatch(failedJamRequest());
            Sentry.captureException(error)
        }
    };
};
