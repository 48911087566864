import React, { useEffect } from "react";
import { Col, Row } from "antd";

import ForgetPasswordForm from "../../components/auth/ForgetPasswordForm";
import { useSelector } from "react-redux";
import ShowToast from "../../utils/ShowToast";
import { resetAuthUser, resetPassword } from "../../redux/actions/authUser";
import { useDispatch } from "react-redux";

const ForgetPasswordFormContainer = ({ navigate }) => {
    const formRef = React.useRef();
    const dispatch = useDispatch();
    const { isLoading, error, userData } = useSelector((state) => state.user);

    useEffect(() => {
        if (!isLoading && error) {
            dispatch(resetAuthUser());
        }
    }, [isLoading, error, userData]);

    const onFormSubmit = async (values) => {
        try {
            await dispatch(resetPassword(values));
            navigate(`/resendForgetPassword?email=${values.email}`);
        } catch (error) {
            ShowToast(error.message, "error");
        }
    };

    return (
        <Row
            justify='center'
            align='stretch'
            className='action-container-wrapper'
        >
            <Col span={20} style={{ display: "flex" }}>
                <ForgetPasswordForm
                    onFormSubmit={onFormSubmit}
                    formRef={formRef}
                />
            </Col>
        </Row>
    );
};

export default ForgetPasswordFormContainer;
