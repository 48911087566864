import {
    SET_NEXT_SONG,
    SET_PLAYED_SONGS,
    SONGS_FINISHED,
    RESET_PLAYED_SONGS
} from "../actionTypes/playedSongs";




const songsFinished = () => {
    return {
        type: SONGS_FINISHED,
    }
}

export const setNextSong = (payload) => {
    return {
        type: SET_NEXT_SONG,
        payload
    }
}

export const setPlayedSongs = (payload) => {
    return {
        type: SET_PLAYED_SONGS,
        payload
    }
}

export const resetPlayedSongs = ()=>{
    return {
        type: RESET_PLAYED_SONGS
    }
}


export const fetchNextSong = (playlist, playedSongs=[]) => {
    return dispatch => {
        const playedSongIds = playedSongs.map(playedSong => playedSong.songId);
        const availableSongs = playlist.filter(playlistSong => !playedSongIds.includes(playlistSong.songId));
        if(availableSongs.length > 0) {
            dispatch(setNextSong(availableSongs[0]));
            if(playedSongs.length === 0) {
                dispatch(setPlayedSong(playedSongs, availableSongs[0]))
            }
        } else {
            dispatch(songsFinished())
        }
    }
}

export const setPlayedSong = (playedSongs = [], currentSong) => {
    return dispatch => {
        const matchedSong = playedSongs.filter(playedSong => playedSong.songId === currentSong.songId);
        if(matchedSong.length === 0) {
            dispatch(setPlayedSongs([currentSong,...playedSongs]))
        }
    }
}
