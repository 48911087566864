import { useEffect, useState } from "react";
import useRound from "./useRound";

export default function useRoundPatternId(roundId) {
    const [patternId, _setPatternId] = useState('Single');
    const {setFieldInRound, getFieldFromRound} = useRound(roundId);

    useEffect(() => {
      // sync numberOfSongs with the value in rounds map in document
      _setPatternId(getFieldFromRound('patternId'))
    }, [roundId])

    /**
     * Sets patternId to a given value
     * @param {string} value - New value for the pattern
     */
    const setPatternId = (value) => {
      setFieldInRound('patternId', value)
      _setPatternId(value)
    }

    return {patternId, setPatternId}
}