import AdminHeader from "../../components/admin/AdminHeader";

import React, { useState } from "react";
import { Row, Col, Tabs } from "antd";

import {
    HomeIcon,
    PlayersIcon,
    PlaylistIcon,
    SettingIcon,
} from "../../utils/Icons";

import PlaylistTableContainer from "./playlist/PlaylistTableContainer";
import PlaylistTableSidebar from "../../components/admin/playlist/PlaylistTableSidebar";
import PlaylistCreateContainer from "./playlist/PlaylistCreateContainer";
import PlaylistManagerSideBar from "../../components/admin/playlist/PlaylistManagerSideBar";
import ShowToast from "../../utils/ShowToast";

import {
    createPlaylist,
    updatePlaylist,
    updateSong,
} from "../../redux/actions/playlist";
import { Song } from "../../utils/PlaylistUtils";
import { useDispatch } from "react-redux";
import PlaylistEditContainer from "./playlist/PlaylistEditContainer";
import AdminHome from "../../components/admin/AdminHome";
import { fetchJamId } from "../../redux/actions/Jam";
import UserManagementContainer from "./UserManagementContainer";

const { TabPane } = Tabs;

const AdminContainer = ({ handleLogout }) => {
    const dispatch = useDispatch();

    const [adminActiveTab, setAdminActiveTab] = useState(1);
    const [selectedPlaylistId, setSelectedPlaylistId] = useState();
    const [playlistAction, setPlaylistAction] = useState("playlists");
    const [searchPlaylistTerm, setSearchPlaylistTerm] = useState("");
    const [playlistDuration, setPlaylistDuration] = useState("15");
    const [playlistName, setPlaylistName] = useState("");
    const [songs, setSongs] = useState([]);
    const [newSongIds, setNewSongIds] = useState([]);
    const [loadingScreen, setIsLoadingScreen] = useState(false);
    const [playlistSaveLoading, setPlaylistSaveLoading] = useState(false);
    const [filteredPlaylistCount, setFilteredPlaylistCount] = useState(0);

    const onTabChange = (key) => {
        setAdminActiveTab(key);
        setPlaylistAction("playlists");
        setSearchPlaylistTerm("");
    };

    const onCreatePlaylist = () => {
        setSongs([]);
        setPlaylistName("");
        setPlaylistAction("create");
        setSearchPlaylistTerm("");
    };

    const onEditPlaylist = (playlistId) => {
        setSongs([]);
        setSelectedPlaylistId(playlistId);
        setPlaylistAction("edit");
        setSearchPlaylistTerm("");
    };

    const onChangeFilterdPlaylistCount = (count) => {
        setFilteredPlaylistCount(count);
    };

    const addSong = (songObj) => {
        const { title, artist, path, songId, comment } = songObj.response.song;
        const newSongs = [...songs];
        newSongs.push(
            new Song(songs.length + 1, title, artist, path, songId, comment)
        );
        if (playlistAction === "edit") {
            setNewSongIds([...newSongIds, songId]);
        }
        setSongs(newSongs);
    };

    const handleCreatePlaylist = async () => {
        try {
            if (!playlistName) {
                return ShowToast("Missing Playlist Name", "error", null, 2);
            }
            if (songs.length < 24) {
                return ShowToast(
                    "Please add minimum 24 songs",
                    "error",
                    null,
                    2
                );
            }
            setPlaylistSaveLoading(true);
            const songIds = songs.map((song) => song.songId);
            new Promise((resolve) => {
                resolve(
                    dispatch(
                        createPlaylist({
                            title: playlistName,
                            speed: playlistDuration,
                            songIds,
                        })
                    )
                );
            }).then(() => {
                Promise.all(
                    songs
                        .filter((song) => song.isUpdated)
                        .map((song) => {
                            const { title, artist, comment } = song;
                            return new Promise((resolve) =>
                                resolve(
                                    dispatch(
                                        updateSong(song.songId, {
                                            title,
                                            artist,
                                            comment,
                                        })
                                    )
                                )
                            );
                        })
                ).then(() => {
                    setPlaylistSaveLoading(false);
                    setPlaylistAction("playlists");
                });
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdatePlaylist = () => {
        try {
            if (!playlistName) {
                return ShowToast("Missing Playlist Name", "error", null, 2);
            }
            if (songs.length < 24) {
                return ShowToast(
                    "Please add minimum 24 songs",
                    "error",
                    null,
                    2
                );
            }
            setPlaylistSaveLoading(true);
            new Promise((resolve) => {
                resolve(
                    dispatch(
                        updatePlaylist(selectedPlaylistId, {
                            name: playlistName,
                            speed: playlistDuration,
                            newSongs: newSongIds,
                        })
                    )
                );
            }).then(() => {
                Promise.all(
                    songs
                        .filter((song) => song.isUpdated)
                        .map((song) => {
                            const { title, artist, comment } = song;
                            return new Promise((resolve) =>
                                resolve(
                                    dispatch(
                                        updateSong(song.songId, {
                                            title,
                                            artist,
                                            comment,
                                        })
                                    )
                                )
                            );
                        })
                ).then(() => {
                    setPlaylistSaveLoading(false);
                    setPlaylistAction("playlists");
                });
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleHostJam = async () => {
        try {
            setIsLoadingScreen(true);
            const response = await fetchJamId();
            if (response && response.hasOwnProperty("jamId")) {
                setIsLoadingScreen(false);
                window.location = `/jamDeck/#${response.jamId}`;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getSideBarJSX = (key) => {
        switch (key) {
            case "2":
                if (playlistAction === "playlists") {
                    return (
                        <PlaylistTableSidebar
                            searchPlaylistTerm={searchPlaylistTerm}
                            setSearchPlaylistTerm={setSearchPlaylistTerm}
                            onCreatePlaylist={onCreatePlaylist}
                            playlistCount={filteredPlaylistCount}
                        />
                    );
                } else if (playlistAction === "create") {
                    return (
                        <PlaylistManagerSideBar
                            onAddSong={addSong}
                            searchPlaylistTerm={searchPlaylistTerm}
                            onChangeSearchPlaylistTerm={(value) =>
                                setSearchPlaylistTerm(value)
                            }
                            numberOfSongs={songs.length}
                            onCreatePlaylist={handleCreatePlaylist}
                            playlistSaveLoading={playlistSaveLoading}
                        />
                    );
                } else if (playlistAction === "edit") {
                    return (
                        <PlaylistManagerSideBar
                            onAddSong={addSong}
                            searchPlaylistTerm={searchPlaylistTerm}
                            onChangeSearchPlaylistTerm={(value) =>
                                setSearchPlaylistTerm(value)
                            }
                            numberOfSongs={songs.length}
                            onCreatePlaylist={handleUpdatePlaylist}
                            playlistSaveLoading={playlistSaveLoading}
                        />
                    );
                }

            default:
                return (
                    <AdminHeader
                        onHost={handleHostJam}
                        isLoading={loadingScreen}
                        showTotalUsers={key === "3"}
                    />
                );
        }
    };

    const getPlaylistJSX = () => {
        switch (playlistAction) {
            case "playlists":
                return (
                    <PlaylistTableContainer
                        searchPlaylistTerm={searchPlaylistTerm}
                        onEditPlaylist={onEditPlaylist}
                        onFilterCountChange={onChangeFilterdPlaylistCount}
                    />
                );
            case "create":
                return (
                    <PlaylistCreateContainer
                        playlistName={playlistName}
                        setPlaylistName={setPlaylistName}
                        playlistDuration={playlistDuration}
                        setPlaylistDuration={setPlaylistDuration}
                        searchPlaylistTerm={searchPlaylistTerm}
                        songs={songs}
                        setSongs={setSongs}
                    />
                );
            case "edit":
                return (
                    <PlaylistEditContainer
                        playlistName={playlistName}
                        setPlaylistName={setPlaylistName}
                        playlistDuration={playlistDuration}
                        setPlaylistDuration={setPlaylistDuration}
                        searchPlaylistTerm={searchPlaylistTerm}
                        songs={songs}
                        setSongs={setSongs}
                        playlistId={selectedPlaylistId}
                    />
                );
        }
    };

    return (
        <Row justify="center" align="stretch">
            <Col lg={6}>{getSideBarJSX(adminActiveTab)}</Col>
            <Col lg={18}>
                <Row justify="center">
                    <Col span={24}>
                        <Tabs
                            type="card"
                            defaultActiveKey="1"
                            className="ant-tabs-bottom host-custom-tabs"
                            tabPosition="bottom"
                            onChange={onTabChange}
                        >
                            <TabPane tab={<HomeIcon />} key="1">
                                <AdminHome onLogout={handleLogout} />
                            </TabPane>
                            <TabPane tab={<PlaylistIcon />} key="2">
                                {getPlaylistJSX()}
                            </TabPane>
                            <TabPane tab={<PlayersIcon />} key="3">
                                <UserManagementContainer />
                            </TabPane>
                            <TabPane tab={<SettingIcon />} key="4"></TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default AdminContainer;
