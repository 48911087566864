import React from "react";
import { Row, Col, Typography, Space } from "antd";

const { Text } = Typography;

const renderGridLines = (index, pattern) => {
    let row = [];
    for (const col of Array(5).keys()) {
        if (pattern?.includes(index++)) {
            row.push(<Col key={index} className="active-box"></Col>);
        } else {
            row.push(<Col key={index} className="normal-box"></Col>);
        }
    }
    return <Row key={index} justify="center">{row}</Row>;
};

const renderPatternGrid = (pattern = [2, 3, 5, 7, 22, 10, 4, 8, 1]) => {
    let songsGrid = [];
    for (let index of [1, 6, 11, 16, 21]) {
        songsGrid.push(renderGridLines(index, pattern));
    }
    return songsGrid;
};

const PlayerWiningPattern = ({ playerName, selectedPattern}) => {

    return (
        <Row justify="center" style={{marginTop: 27}}>
            
            <Space direction="vertical">
                <Row justify="center" style={{margin: '0 5px'}}>
                    <Text className="player-name">{playerName}</Text>
                </Row>
                <Row justify={'center'}>
                    <Col>{renderPatternGrid(selectedPattern)}</Col>
                </Row>
            </Space>          
            
        </Row>
    );
};
export default PlayerWiningPattern;
