import React from 'react';

console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  const useJamHook = require('./hooks/useJam');
  whyDidYouRender(React, {
    // trackAllPureComponents: true,
    // trackExtraHooks: [useJamHook, 'useJam'],
    trackHooks: true,
    logOnDifferentValues: true
  });
}