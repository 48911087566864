import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import JamDeckCreateForm from "../../../components/host/jamDeck/JamDeckCreateForm";
import ShowToast from "../../../utils/ShowToast";
import JamService from "../../../services/JamService";
import GameService from "../../../services/GameService";
import {createNewGame, getJamTime} from "../../../utils/JamCreateUtils";

const JamDeckCreateContainer = ({
    jamId,
    jam,
    tabActive,
    games,
    setGames,
    saveGames,
    setSaveGames,
    setActiveTab,
    activeGame,
    setJamEditableMode,
    setKeyInJam,
    onAutoTabSwitch
}) => {

    const [editableRound, setEditableRound] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [onCancelActiveRndKey, setOnCancelActiveRndKey] = useState(false);

    useEffect(() => {
        return () => {
            setEditableRound(false);
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
        if (games.length === 0 && (!jam.startingSoonGames && !jam.startedGame)) {
            gameAddHandler();
        }
    }, [games]);

    useEffect(() => {
        if (saveGames) {
            handleJamSubmit(true);
        }
    }, [saveGames]);

    const validateForm = (game) => {
        if (!game.playlistId) {
            if (!editableRound) {
                setSaveGames(false);
            }
            ShowToast(
                `Playlist Missing in Rd ${game.key + 1}`,
                "error",
                null,
                2
            );
            return true;
        }
        return false;
    };

    const handleJamSubmit = (autoSwitchOn = false) => {
        if (!jam?.title) {
            setSaveGames(false);
            ShowToast(`Jam name missing`, "error", null, 2);
            return;
        }
        const inValidGames = games.filter(validateForm);
        if (inValidGames.length > 0) return;
        setIsLoading(true);
        
        JamService.updateJam({...jam, title: jam?.title.trim(), jamTime: getJamTime(games)})
        .then(async (response) => {
            if(response.status === 200) {
                await GameService.updateGames({jamId, games})
                if (!editableRound) {
                    setSaveGames(false);
                    setActiveTab("4");
                }
            }
        })
        .finally(() => {
            JamService.fetchJamByJamId({jamId}).then((response) => {
                if (response.status === 200) {
                    const {jam: savedJam, games} = response.data;
                    let fetched = games.map(game => {
                        game.patternName = game.winningPattern.name
                        if (game.gameId === activeGame?.gameId) {
                            return activeGame;
                        }
                        return game;
                    })
                    setKeyInJam('jamTime', getJamTime(games));
                    setGames(fetched);
                    setKeyInJam('jamCode', savedJam.jamCode);
                    setKeyInJam('startingSoonGames', true);
                }
            })
            setEditableRound(false);
            setJamEditableMode(false);
            setIsLoading(false);
            if(autoSwitchOn) {
                onAutoTabSwitch()
            }
        })
                
    };

    const gameAddHandler = () => {
        if (jam && jam?.jamCode) {
            setEditableRound(true);
            setJamEditableMode(true);
        }
        const newGames = [...games];
        newGames.push(createNewGame(games.length, []));
        setGames([...newGames]);
    };

    const gameDeleteHandler = (updatedGames, refetch = false) => {
        if (jam && jam.hasOwnProperty("jamId")) {
            fetchGames(updatedGames, refetch);
        }
    };

    const gameInfoUpdateHandler = (updatedGames) => {
        setGames(updatedGames);
    };

    const handleEditableRound = () => {
        setEditableRound(true);
        setJamEditableMode(true);
    };

    const handleCancelEditRound = () => {
        setEditableRound(false);
        setJamEditableMode(false);
        setOnCancelActiveRndKey(true);
        if (jam.hasOwnProperty("jamId")) {
            setKeyInJam('jamTime', jam.jamTime);
            fetchGames([], true);
        }
    };

    const fetchGames = (updatedGames = [], refetch = false) => {
        if(!refetch) {
            setGames(updatedGames);
            setKeyInJam('jamTime', getJamTime(updatedGames));
            return;
        }

        GameService.fetchGames(jam?.jamId).then((response) => {
            if(response.status === 200) {
                const fetchedGames = response.data.games.map(game => {
                    game.patternName = game.winningPattern.name
                    if (game.gameId === activeGame?.gameId) {
                        return activeGame;
                    }
                    return game;
                })
                if(fetchedGames.length > 0) {
                    setGames(fetchedGames);
                    setKeyInJam('jamTime', getJamTime(fetchedGames));
                }
            }
        })
    }

    return (
        <Row justify='center'>
            <Col span={22}>
                <JamDeckCreateForm
                    games={games}
                    gameInfoUpdateHandler={gameInfoUpdateHandler}
                    onGameDelete={gameDeleteHandler}
                    onGameAdd={gameAddHandler}
                    jamTime={getJamTime(games)}
                    jamName={jam?.title}
                    jamCode={jam?.jamCode}
                    updateJamName={(e) => {
                        setKeyInJam('title', e.target.value.trimStart())
                    }}
                    tabActive={tabActive}
                    handleEditableRound={handleEditableRound}
                    editableRound={editableRound}
                    activeGame={activeGame}
                    onCancelActiveRndKey={onCancelActiveRndKey}
                    setOnCancelActiveRndKey={() =>
                        setOnCancelActiveRndKey(false)
                    }
                />
            </Col>
            {editableRound && (
                <Col span={24}>
                    <Row
                        className='jam-submit-btn-container'
                        style={{
                            flexDirection: "row",
                            // position: "fixed",
                            maxWidth: 768,
                            bottom: 0,
                            minWidth: 400,
                            height: 97,
                            width: 400,
                            zIndex: 20,
                            transform: "translateX(-3%)",
                        }}
                    >
                        <Col span={12}>
                            <Button
                                className='jam-deck-create-secondary-button'
                                block
                                onClick={handleCancelEditRound}
                                loading={isLoading}
                            >
                                CANCEL
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                className='jam-deck-create-button'
                                style={{ height: "100%" }}
                                block
                                onClick={() => handleJamSubmit()}
                                loading={isLoading}
                            >
                                SAVE ROUND
                            </Button>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
};

export default JamDeckCreateContainer;
