import { Row, Col, Typography, Form, Input, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { OpenLockIcon, JindoWhiteLogo } from "../../utils/Icons";

const { Text } = Typography;

const UpdatePasswordForm = ({ onSubmit, form }) => {
    return (
        <Row justify='center'>
            <Col span={24} className='forget-password-form-container'>
                <Row justify='center' gutter={[22, 22]}>
                    <Col style={{ margin: 35 }}>
                        <Link to='/'>
                            <JindoWhiteLogo
                                style={{ width: 130, height: 94 }}
                            />
                        </Link>
                    </Col>
                    <Col className='auth-form-sub-text' span={24}>
                        <Text>CREATE A NEW PASSWORD</Text>
                    </Col>
                    <Col className='auth-form-sub-text'>
                        <Text>Enter and verify your new password below.</Text>
                    </Col>
                    <Col className='forget-form-email-container' span={24}>
                        <Form form={form} onFinish={onSubmit}>
                            <Row justify='center'>
                                <Col span={24}>
                                    <Form.Item
                                        name='newPassword'
                                        className='custom-input'
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter new password",
                                            },
                                        ]}
                                    >
                                        <Input placeholder='NEW PASSWORD' />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <OpenLockIcon
                                        style={{
                                            width: 30,
                                            height: 35,
                                            marginTop: 14,
                                            fill: "white",
                                        }}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name='confirmPassword'
                                        className='custom-input'
                                        dependencies={["newPassword"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Confirm new password",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (
                                                        !value ||
                                                        getFieldValue(
                                                            "newPassword"
                                                        ) === value
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            "The two passwords that you entered do not match!"
                                                        )
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input placeholder='RE-ENTER PASSWORD' />
                                    </Form.Item>
                                </Col>
                                <Col span={24} style={{ marginTop: 27 }}>
                                    <Button
                                        className='high-priority-btn'
                                        htmlType='submit'
                                        style={{ height: 46 }}
                                        block
                                    >
                                        SET NEW PASSWORD
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
                <Text className='need-account-text'>
                    Need to create an account?
                </Text>
                <br />
                <a
                    href='https://www.playjindo.com/store/p/vip-subscribe-monthly-jindo'
                    target='_blank'
                >
                    <Text className='singup-link'>
                        Sign Up Today
                    </Text>
                </a>
                <Text className='copyright-text'>
                    ©Copyright 2022 Jindo LLC
                </Text>
            </Col>
        </Row>
    );
};

export default UpdatePasswordForm;
