import React from "react";
import { Row, Col, Form, Input, Typography, Button } from "antd";
import { WinnerCelebrationIcon } from "../../../utils/Icons";

const { Text } = Typography;

const JindoWinnerForm = ({
    winnerFormRef,
    winnerFormSubmit,
    handleWinnerFormlessNavigate,
    prize,
    claimPrizeLoader
}) => {
    return (
        <Form
            name='game-winner-form'
            className='game-winner-form'
            ref={winnerFormRef}
            onFinish={(args) => {
                winnerFormSubmit(args);
            }}
        >
            <Row justify='center' gutter={[10, 0]}>
                <Col span={24}>
                    <Text
                        className='message-splash-text'
                        style={{ fontFamily: "Bangers", fontStyle: "normal" }}
                    >
                        WINNER!
                    </Text>
                </Col>
                <Col span={24}>
                    <Text
                        className='message-splash-helper-text'
                        style={{ fontSize: 24, padding: 15, paddingBottom: 0 }}
                    >
                        Let the Host know your contact info to claim your prize:
                    </Text>
                </Col>
                <Col span={24}>
                    <Text
                        className='message-splash-helper-text'
                        style={{ fontSize: 24, padding: 15 }}
                    >
                        {`${prize ? prize : 0}`}
                    </Text>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: "Please input your name!",
                            },
                        ]}
                        style={{ margin: 0 }}
                    >
                        <Input
                            placeholder='ENTER YOUR NAME'
                            className='game-input-field'
                            style={{ margin: "5px 0" }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name='email'
                        rules={[
                            {
                                required: true,
                                message: "Please Enter Email",
                            },
                            {
                                type: "email",
                                message: "The input is not a valid E-mail!",
                            },
                        ]}
                        style={{ margin: 0 }}
                    >
                        <Input
                            placeholder='Email'
                            className='game-input-field'
                            style={{ margin: "5px 0" }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name='phoneNumber'
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: true,
                                message: "Please Enter Phone Number",
                            },
                            {
                                validator: (rule, value) => {
                                    if (value !== undefined && value !== null) {
                                        value = value.toString();
                                        if (value.length < 9 || !Number(value))
                                            return Promise.reject(
                                                "Please enter a valid phone number"
                                            );
                                        return Promise.resolve();
                                    }
                                    return Promise.reject("");
                                },
                            },
                        ]}
                    >
                        <Input
                            type={'number'}
                            className={'game-input-field'}
                            placeholder='Phone Number'
                            style={{ margin: "5px 0" }}
                        />
                        {/* <PhoneNumberInput
                            onChange={(value) => {
                                winnerFormRef.current?.setFieldsValue({
                                    phoneNumber: value,
                                });
                            }}
                            value={winnerFormRef.current?.getFieldValue(
                                "phoneNumber"
                            )}
                            style={{ margin: "5px 0" }}
                        /> */}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Button
                        className='winner-secondary-btn'
                        block
                        onClick={handleWinnerFormlessNavigate}
                    >
                        NO THANK YOU
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        className='winner-primary-btn'
                        block
                        htmlType='submit'
                        loading={claimPrizeLoader}
                        disabled={claimPrizeLoader}
                    >
                        CLAIM PRIZE
                    </Button>
                </Col>
                <Col span={24}>
                    <WinnerCelebrationIcon className='winner-form-celebration-icon' />
                </Col>
            </Row>
        </Form>
    );
};

export default JindoWinnerForm;
