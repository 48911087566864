import {
    SET_NEXT_SONG,
    SET_PLAYED_SONGS,
    SONGS_FINISHED,
    RESET_PLAYED_SONGS,
} from "../actionTypes/playedSongs";

const initialState = {
    songsPlayed: [],
    currentSong: null,
    songsFinished: false,
};

const playedSongs = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEXT_SONG:
            return {
                ...state,
                currentSong: action.payload,
            };
        case SET_PLAYED_SONGS:
            return {
                ...state,
                songsPlayed: action.payload,
            };

        case RESET_PLAYED_SONGS:
            return initialState;

        case SONGS_FINISHED:
            return {
                ...state,
                currentSong: null,
                songsFinished: true,
            };
        default:
            return state;
    }
};

export default playedSongs;
