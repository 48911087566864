import React from "react";
import { Col, Row, Typography, Space } from "antd";

const { Text } = Typography;

const PlayedSongs = ({ playedSongs = [] }) => {
    const RenderSongBytes = (songByte) => {
        const songBytes = songByte.split(". ");
        return (
            <Space direction='vertical' size={15}>
                {songBytes.map((songByte, index) => {
                    return (
                        <Text key={index} className='song-byte-text'>
                            {songByte}
                        </Text>
                    );
                })}
            </Space>
        );
    };
    return (
        <Row
            style={{
                marginTop: 17,
                overflow: "scroll",
                height: 570,
                overflowX: "hidden",
            }}
            align={"top"}
        >
            {playedSongs &&
                playedSongs.map((song, index) => (
                    <Row key={index} style={{ width: "100%" }}>
                        <Col
                            className={`song-played-title-col`}
                            span={24}
                            style={{ height: 40, overflowX: "hidden" }}
                        >
                            <Text
                                className={`song-played-title ${
                                    song?.title?.length > 25 &&
                                    "animate-left-right"
                                }`}
                                style={{ display: "block" }}
                            >
                                {song.title}
                            </Text>
                        </Col>
                        <Col
                            span={24}
                            style={{ padding: "10px 28px 31px 28px" }}
                        >
                            {RenderSongBytes(song.comment)}
                        </Col>
                    </Row>
                ))}
        </Row>
    );
};

export default PlayedSongs;
