import React from "react";
import { Upload, Space, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";


const { Dragger } = Upload;
const { Text } = Typography;
import { SongsUploadIcon, SongIconSVG, ArrowIconSVG } from "../../../utils/Icons";
import ActionButton from "../../common/ActionButton";

const SongsUploader = ({addSong}) => {
  const DragProps = {
    name: "upload",
    accept: "audio/*",
    action: `${
        process.env.BASE_URL
    }song/add?id=${uuidv4()}&duration=${15}&type=song`,
    multiple: true,
    showUploadList: false,
    onChange({ file, fileList }) {
        const { status } = file;
        fileList = fileList.filter(
            (fileUpload) => fileUpload.status !== "done"
        );
        if (status !== "uploading") {
            console.log("Uploading ", file);
        }
        if (status === "done") {
            addSong(file);
        }
        if (status === "error") {
            console.log(`${file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log("Dropped files", e.dataTransfer.files);
    },
};
  return (
    <Dragger {...DragProps} className="song-upload-dragger">
      <Space direction="vertical" className="songs-upload-container" size={[0, 15]}>
        <SongsUploadIcon  className="songs-upload-icon"/>
        <Text className="songs-upload-text">Drag and drop your songs here.</Text>
        <Text className="songs-upload-text">OR</Text>
        <ActionButton
          title="upload songs"
          className="high-priority-btn "
          onClick={() => console.log("save")}
          prefixIconSVG={SongIconSVG}
          postfixIconSVG={ArrowIconSVG}
        />
      </Space>
    </Dragger>
  );
};

export default SongsUploader;
