import {
    INITIALIZE_PLAYLIST_REQUREST,
    FETCHED_PLAYLIST,
    FETCHED_PLAYLISTS,
    FAILED_PLAYLIST_REQUEST,
} from "../actionTypes/playlist";

const initialState = {
    playlist: null,
    playlists: [],
    searchPlaylistCount: null,
    isLoading: false,
    error: null,
};

const playlist = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_PLAYLIST_REQUREST:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case FETCHED_PLAYLISTS:
            return {
                ...state,
                playlists: action.payload,
                isLoading: false,
                error: null,
            };
        case FETCHED_PLAYLIST:
            return {
                ...state,
                playlist: action.payload,
                isLoading: false,
                error: null,
            };

        case FAILED_PLAYLIST_REQUEST:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default playlist;
