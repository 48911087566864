import {
    REQUEST_USER_INFO,
    FAILED_USER_INFO,
    SUCCESS_USER_INFO,
    UPDATE_USER_INFO,
} from "../actionTypes/user";

import UserService from "../../services/UserService";
import AuthService from "../../services/AuthService";

const requestUserInfo = () => {
    return {
        type: REQUEST_USER_INFO,
    };
};

const failedUserInfo = (payload) => {
    return {
        type: FAILED_USER_INFO,
        payload,
    };
};

const userInfoUpdated = () => {
    return {
        type: UPDATE_USER_INFO,
    };
};

export const successUserInfo = (payload) => {
    return {
        type: SUCCESS_USER_INFO,
        payload,
    };
};

export const fetchUser = () => {
    return async (dispatch) => {
        try {
            dispatch(requestUserInfo());
            const { data, status } = await UserService.fetchUser();
            if (status === 200) {
                dispatch(successUserInfo(data));
                await AuthService.setCurrentUser(data);
                return true;
            }
        } catch ({ response }) {
            dispatch(failedUserInfo(response));
        }
    };
};

export const updateUser = (updateUserInfo, fetchUserUpdate = false) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserInfo());
            const { status } = await UserService.update(updateUserInfo);
            if (status === 200) {
                dispatch(userInfoUpdated());
                if (fetchUserUpdate) {
                    dispatch(fetchUser());
                }
            }
        } catch ({ response }) {
            dispatch(failedUserInfo(response));
        }
    };
};

export const updateUserById = (updateUserInfo) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserInfo());
            const { status } = await UserService.updateUser(updateUserInfo);
        } catch ({ response }) {
            dispatch(failedUserInfo(response));
        }
    };
};

export const deleteUserById = (userId) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserInfo());
            const { status } = await UserService.deleteUser(userId);
        } catch ({ response }) {
            dispatch(failedUserInfo(response));
        }
    };
};

export const fetchUserById = (userId) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserInfo());
            const { data, status } = await UserService.fetchUserById(userId);
            if (status === 200) {
                dispatch(successUserInfo(data));
            }
        } catch ({ response }) {
            dispatch(failedUserInfo(response));
        }
    };
};

export const changePassword = (changePasswordInfo) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserInfo());
            const { data, status } = await UserService.updatePassword(
                changePasswordInfo
            );
            if (status === 200) {
                dispatch(userInfoUpdated());
            }
        } catch (error) {
            if (error.response) {
                const {
                    response: { data },
                } = error;
                dispatch(failedUserInfo(data.error));
            }
        }
    };
};

export const updateUserImage = (image) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserInfo());
            const formData = new FormData();
            formData.append("uploadImage", image.file);
            const { status } = await UserService.update(formData, "profilePic");
            if (status === 200) {
                dispatch(userInfoUpdated());
                dispatch(fetchUser());
            }
        } catch (error) {
            if (error.response) {
                const {
                    response: { data },
                } = error;
                dispatch(failedUserInfo(data.error));
            }
            throw new Error(error);
        }
    };
};
