import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Typography, Space } from "antd";
import { OnNoIcon, WinnerCelebrationIcon, QuitIcon } from "../../utils/Icons";
const { Text } = Typography;

const isCloseSplashVisible = (message) => message === "OH NO!";

const GameEventsModal = ({
    visible,
    splashWinnerName,
    splashMessage,
    splashMessageHeader,
    splashMessageSub,
    splashMessageHelper,
    onCloseModal,
    BigRedSplashIcon,
}) => {
    const [flip, setFlip] = useState(false);

    useEffect(() => {
        let intervalId;
        if (splashMessage?.includes("WINNER!!")) {
            intervalId = setInterval(() => {
                setFlip(prevFlip => !prevFlip);
            }, 1500);
        }

        return () => {
            setFlip(false);
            clearInterval(intervalId)
        }

    }, [splashMessage])

    return (
        <Modal
            className='game-splash-modal'
            open={visible}
            closable={false}
            footer={false}
            mask={true}
            destroyOnClose={true}
        >
            <Row
                justify='center'
                align='bottom'
                onClick={isCloseSplashVisible(splashMessage) ? onCloseModal : undefined}
            >
                <Col span={24} className='game-splash-title-container'>
                    <Row
                        justify='center'
                        align='middle'
                        className='game-play-splash-component'
                    >
                        <Col span={24}>
                            <BigRedSplashIcon
                                className='message-splash'
                                style={{ opacity: 1 }}
                            />
                        </Col>

                        <Col
                            className='game-play-splash-container'
                            style={{ minHeight: 250 }}
                            span={24}
                        >
                            <Space
                                direction='vertical'
                                style={{ textAlign: "center" }}
                            >
                                {splashMessageHeader && (
                                    <Text
                                        className={"message-splash-header-text"}
                                    >
                                        {splashMessageHeader}
                                    </Text>
                                )}
                                {splashMessage?.includes("OH NO") && (
                                    <OnNoIcon className='message-splash-icon' />
                                )}
                                {splashMessage?.includes("WINNER!!") && (
                                    <WinnerCelebrationIcon
                                        className={
                                            flip
                                                ? "message-splash-winner-icon message-splash-icon-flip"
                                                : "message-splash-winner-icon"
                                        }
                                    />
                                )}
                                {splashMessageSub?.includes("QUIT") && (
                                    <QuitIcon className='message-splash-icon' />
                                )}
                                {splashMessage && !splashWinnerName && (
                                    <Text
                                        className={"message-splash-text"}
                                        style={{
                                            textTransform: "none",
                                            width: 250,
                                            opacity: splashMessageSub.includes(
                                                "VERIFY"
                                            )
                                                ? 0.5
                                                : 1,
                                        }}
                                    >
                                        {splashMessage}
                                    </Text>
                                )}
                                {splashMessage && splashWinnerName && (
                                    <>
                                        <Text
                                            className={"message-splash-text"}
                                            style={{
                                                fontSize: splashWinnerName.split(" ")[0].length > 7 ? 38 : 72,
                                                textTransform: "none",
                                                width: 250,
                                                opacity: 1,
                                            }}
                                        >
                                            {splashWinnerName.split(" ")[0].length > 10 ? 
                                            `${splashWinnerName.split(" ")[0].substring(0,10)}...` :
                                             splashWinnerName.split(" ")[0]}
                                        </Text>
                                        <Text
                                            className={"message-splash-text"}
                                            style={{
                                                textTransform: "none",
                                                width: 250,
                                                opacity: 1,
                                            }}
                                        >
                                            {splashMessage}
                                        </Text>
                                    </>
                                )}
                                {splashMessageSub && (
                                    <Text className={"message-splash-sub-text"}>
                                        {splashMessageSub}
                                    </Text>
                                )}
                                {splashMessageHelper && (
                                    <Text
                                        className={"message-splash-helper-text"}
                                    >
                                        {splashMessageHelper}
                                    </Text>
                                )}
                            </Space>
                        </Col>
                        {isCloseSplashVisible(splashMessage) && (
                            <Col
                                span={20}
                                sm={20}
                                xs={24}
                                className='splash-close-text-container'
                                onClick={onCloseModal}
                            >
                                <Text className='splash-close-text'>
                                    CLICK ANYWHERE TO GO BACK TO YOUR GAME CARD
                                </Text>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default GameEventsModal;
