import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";

import LoginForm from "../../../components/auth/LoginForm";
import { login, resetAuthUser } from "../../../redux/actions/authUser";
import AuthService from "../../../services/AuthService";
import ShowToast from "../../../utils/ShowToast";

const LoginContainer = ({ navigate }) => {
    const formRef = React.createRef();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(true);
    const [selfLogin, setSelfLogin] = useState(true);

    const { isLoading, error, userData } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(resetAuthUser());
    }, []);

    useEffect(() => {
        // const user = AuthService.getCurrentUser();
        if (userData && !error) {
            if (userData) {
                navigate("/userHome");
            }
            //  else {
            //     navigate("/confirmCode");
            // }
        }

        if (!isLoading && error) {
            ShowToast(error, "error");
            // dispatch(resetAuthUser());
        }
    }, [isLoading, error, userData]);

    const handleSubmit = async (e) => {
        try {
            const formValues = await formRef.current.validateFields();
            dispatch(login({ ...formValues, selfLogin }));
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleChange = (e) => {
        let values = formRef.current?.getFieldsValue();
        values = Object.values(values).filter((val) => val);
        if (values.length === 2) setIsDisabled(false);
        else setIsDisabled(true);
    };

    return (
        <Row
            justify='center'
            align='stretch'
            className='action-container-wrapper'
        >
            <Col span={20} style={{ display: "flex" }}>
                <LoginForm
                    formRef={formRef}
                    handleSubmit={handleSubmit}
                    isDisabled={isDisabled}
                    handleChange={handleChange}
                />
            </Col>
        </Row>
    );
};

export default LoginContainer;
