import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Modal, Space, Button } from "antd";
import {JamEndJindoLogo, PrizeIcon, GroupIcon} from "../../utils/Icons";

const { Text } = Typography;

const JamDeckWinnerModal = ({
    visible,
    handlePlay,
    handleNextRound,
    handleEndJam,
    activeGame,
    winnerName,
    games,
    isWinnerSongEnded
}) => {
    const [buttonText, setButtonText] = useState('NEXT ROUND');

    useEffect(() => {
        getButtonText();
    }, [games, visible])

    const getButtonText = () => {
        const filteredGames = games.filter(game => game.gameId !== activeGame?.gameId && game.status === 'STARTING_SOON');

        if(filteredGames.length > 0) {
            setButtonText('NEXT ROUND')
        } else {
            setButtonText('END JAM')
        }
    }

    return (
        <Modal
            className="jam-deck-winner-modal"
            title={false}
            open={visible}
            footer={false}
            onOk={handlePlay}
            onCancel={null}
            bodyStyle={null}
            closable={false}
            width={'100%'}
            mask={false}
            style={{margin: 0, top: 0, right: 0, height: '100%', padding: 0}}
        >
            <Row justify="center" style={{width: '100%'}}>
                <Row style={{position: 'absolute', top: 0}} >
                    <JamEndJindoLogo style={{width: 241, height: 129}} />
                </Row>
                <Row justify="center" style={{marginTop: 118}}>
                    <Space direction="vertical" size={37} style={{textAlign: 'center', alignItems: 'center'}}>
                        <Text className="game-over-title" style={{marginBottom: 37}}>ROUND OVER</Text>
                        <Row style={{flexDirection: 'column', alignItems: 'center'}}>
                            <Text className="game-over-sub-heading">And the winner of</Text>
                            <Text className="game-over-sub-heading">Round {activeGame?.key + 1} ...</Text>
                        </Row>
                        
                    </Space>
                </Row>
               
                <Col span={24} style={{display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: 56.98}}>
                    <Space direction="vertical" size={9} style={{textAlign: 'center', alignItems: 'center'}}>
                        <GroupIcon style={{width: 78.93, height: 62, fill: 'white'}} />
                        <Text className="game-winner-name">{winnerName || 'No Winner'}</Text>
                        <PrizeIcon style={{fill: '#FFFFFF', fontSize: 23.23}} />
                        <Text className="game-over-prize-text-modal" style={{textAlign: 'center'}}>{activeGame?.prize}</Text>
                    </Space>
                </Col>
              
                <Col 
                    span={24} 
                    style={{
                        marginTop: 38,
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'fixed',
                        bottom: 0,
                        maxWidth: 768,
                        width: '100%'
                    }}
                >
                    <Button block className="jam-end-primary-btn"  
                        onClick={() => {
                            if(buttonText === 'NEXT ROUND') {
                                handleNextRound();
                            } 
                            if(buttonText === 'END JAM') {
                                handleEndJam();
                            }
                            
                        }}
                        disabled={!isWinnerSongEnded}
                    >
                        <Row>
                            <Col span={24}>
                                {buttonText}
                            </Col>
                        </Row>
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default JamDeckWinnerModal;
