import React from "react";
import { Modal, Row, Col } from "antd";
import JindoWinnerForm from "../player/gamePlay/JindoWinnerForm";

const GameWinnerFormModal = ({
    visible,
    winnerFormRef,
    winnerFormSubmit,
    handleWinnerFormlessNavigate,
    prize,
    BigRedSplashIcon,
    claimPrizeLoader
}) => {
    return (
        <Modal
            className='game-splash-modal'
            open={!visible}
            closable={false}
            footer={false}
            mask={true}
            destroyOnClose={true}
        >
            <Row justify='center' align='bottom'>
                <Col span={24} className='game-splash-title-container'>
                    <Row
                        justify='center'
                        align='middle'
                        className='game-play-splash-component'
                    >
                        <Col span={24}>
                            <BigRedSplashIcon
                                className='message-splash'
                                style={{
                                    opacity: 1,
                                }}
                            />
                        </Col>
                        <Col className='game-play-splash-container' span={24}>
                            <JindoWinnerForm
                                winnerFormRef={winnerFormRef}
                                winnerFormSubmit={winnerFormSubmit}
                                handleWinnerFormlessNavigate={
                                    handleWinnerFormlessNavigate
                                }
                                prize={prize}
                                claimPrizeLoader={claimPrizeLoader}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default GameWinnerFormModal;
