import React from "react";
import { Row, Col, Typography } from "antd";

import { PlayersJoinedIcon } from "../../../utils/Icons";
import PlayerWiningPattern from "../../../components/host/jamDeck/PlayerWiningPattern";
import usePlayers from "../../../hooks/usePlayers";

const { Text } = Typography;

const PlayerPatternInfoContainer = ({gameId}) => {

    const { players, activePlayersCount } = usePlayers(gameId, true)

    return (
        <Row style={{ marginLeft: 40, marginRight: 18, marginBottom: 110 }}>
            <Col span={24} style={{ marginTop: 34, paddingRight: 22 }}>
                <Row justify='center'>
                    <Col style={{ position: "absolute" }}>
                        <PlayersJoinedIcon style={{ width: 70, height: 65 }} />
                    </Col>

                    <Text className='players-count-text'>
                        {activePlayersCount}
                    </Text>
                </Row>
            </Col>
            {players &&
                players.map((player, index) => (
                    <Col span={6} key={index} style={{display: 'flex', alignItems: 'end', justifyContent: 'center'}}>
                        <PlayerWiningPattern
                            key={index}
                            playerName={player.name}
                            selectedPattern={player.selectedPattern}
                        />
                        {player.leave && (<p style={{color: 'red'}}>Left Game</p>)}
                    </Col>
                ))}
        </Row>
    );
};

export default PlayerPatternInfoContainer;
