import React from "react";

import { Row, Col } from "antd";

import GameJamEnd from "../../../components/player/gameJamEnd/GameJamEnd";

const GameJamEndContainer = ({ navigate }) => {
    return (
        <Row justify='center' align='top' className='game-container'>
            <Col span={20}>
                <GameJamEnd navigate={navigate} />
            </Col>
        </Row>
    );
};

export default GameJamEndContainer;
