const MUSIC_QUOTES = [
    "“Where words fail, music speaks.” /atn — Hans Christian Andersen",
    "“Music is the language of the spirit. It opens the secret of life bringing peace, abolishing strife.” /atn — Kahlil Gibran",
    "“Where words leave off, music begins.” /atn — Heinrich Heine",
    "“Music, once admitted to the soul, becomes a sort of spirit, and never dies.” /atn — Edward Bulwer Lytton",
    "“Music produces a kind of pleasure which human nature cannot do without.” /atn — Confucius",
    "“Music is the universal language of mankind.” /atn — Henry Wadsworth Longfellow",
    "“Music, when soft voices die, vibrates in the memory.” /atn — Percy Bysshe Shelley",
    "“Music is healing. Music holds things together.” /atn — Prince",
    "“Music can change the world.” /atn — Ludwig van Beethoven",
    "“The true beauty of music is that it connects people. It carries a message, and we, the musicians, are the messengers.” /atn — Roy Ayers",
    "“The best music is essentially there to provide you something to face the world with.” /atn — Bruce Springsteen",
    "“He took his pain and turned it into something beautiful. Into something that people connect to. And that's what good music does. It speaks to you. It changes you.” /atn — Hannah Harrington, Saving June",
    "“Music is the strongest form of magic.” /atn — Marilyn Manson",
    "“There is no feeling, except the extremes of fear and grief, that does not find relief in music.” /atn — George Eliot",
    "“Music is the great uniter. An incredible force. Something that people who differ on everything and anything else can have in common.” /atn — Sarah Dessen, Just Listen",
    "“If you’ve lost your faith in love and music, then the end won’t be long.” /atn — Pete Doherty",
    "“I've found that no matter what life throws at me, music softens the blow.” /atn — Bryce W. Anderson",
    "“Music acts like a magic key, to which the most tightly closed heart opens.” /atn — Maria von Trapp",
    "“When I hear music, I fear no danger. I am invulnerable. I see no foe. I am related to the earliest times, and to the latest.” /atn — Henry David Thoreau",
    "“Music is the art which is most nigh to tears and memory.” /atn — Oscar Wilde",
    "“My heart, which is so full to overflowing, has often been solaced and refreshed by music when sick and weary.” /atn — Martin Luther",
    "“Music is the wine that fills the cup of silence.” /atn — Robert Fripp",
    "“Musicians want to be the loud voice for so many quiet hearts.” /atn — Billy Joel",
    "“Music is an outburst of the soul.” /atn — Frederick Delius",
    "“A great song should lift your heart, warm the soul and make you feel good.” /atn — Colbie Caillat",
    "“Those who wish to sing always find a song.” /atn — Unknown",
    "“For me, there is something primitively soothing about this music, and it went straight to my nervous system, making me feel ten feet tall.” /atn — Eric Clapton",
    "“Music is the divine way to tell beautiful, poetic things to the heart.” /atn — Pablo Casals",
    "“Can a mere song change a people's minds? I doubt that it is so. But a song can infiltrate your heart and the heart may change your mind.” /atn — Elvis Costello",
    "“Music is life itself.” /atn — Louis Armstrong",
    "“How is it that music can, without words, evoke our laughter, our fears, our highest aspirations?” /atn — Jane Swan",
    "“Music is the moonlight in the gloomy night of life.” /atn — Jean Paul Friedrich Richter",
    "“Once again, she was free. Once again, she found peace. It was music that freed her soul from the dungeon of her mind.” /atn — Wiss Auguste, The Illusions of Hope",
    "“I’ve always said music should make you laugh, make you cry or make you think.” /atn — Kenny Rogers",
    "“Life is one grand, sweet song so start the music.” /atn — Ronald Reagan",
    "“Music can heal the wounds which medicine cannot touch.” /atn — Debasish Mridha",
    "“Music replays the past memories, awakens our forgotten worlds and makes our minds travel.” /atn — Michael Bassey Johnson",
    "“Without music, life would be a blank to me.” /atn — Jane Austen, Emma",
    "“Music is the literature of the heart; it commences where speech ends.” /atn — Alphonse de Lamartine",
    "“Music is nothing else but wild sounds civilized into time and tune.” /atn — Thomas Fuller",
    "“One good thing about music, when it hits you, you feel no pain.” /atn — Bob Marley",
    "“Music kept me off the streets and out of trouble and gave me something that was mine that no one could take away from me.” /atn — Eddie Van Halen",
    "“Music is the soundtrack of your life.” /atn — Dick Clark",
    "“Music is your own experience, your thoughts, your wisdom. If you don’t live it, it won’t come out of your horn.” /atn — Charlie Parker",
    "“Music expresses feeling and thought, without language; it was below and before speech, and it is above and beyond all words.” /atn — Robert G. Ingersoll",
    "“Music is the only thing I’ve ever known that doesn’t have any rules at all.” /atn — Josh Homme",
    "“Music is love in search of a word.” /atn — Sidney Lanier",
    "“Music cleanses the understanding; inspires it, and lifts it into a realm which it would not reach if it were left to itself.” /atn — Henry Ward Beecher",
    "“The easiest way to avoid wrong notes is to never open your mouth and sing. What a mistake that would be.” /atn — Pete Seeger",
    "“Music… can name the unnameable and communicate the unknowable.” /atn — Leonard Bernstein",
    "“Music is the shorthand of emotion.” /atn — Leo Tolstoy",
    "“Some days there won’t be a song in your heart. Sing anyway.” /atn — Emory Austin",
    "“Music gives color to the air of the moment.” /atn — Karl Lagerfeld",
    "“Even if you can’t sing well, sing. Sing to yourself. Sing in the privacy of your home. But sing.” /atn — Rebbe Nachman",
    "“A painter paints pictures on canvas. But musicians paint their pictures on silence.” /atn — Leopold Stokowski",
    "“Music is what tells us that the human race is greater than we realize.” /atn — Napoleon Bonaparte",
    "“He who sings frightens away his ills.” /atn — Miguel de Cervantes",
    "“Music washes away from the soul the dust of everyday life.” /atn — Berthold Auerbach",
    "“When we listen to music we are not listening to the past, we are not listening to the future, we are listening to an expanded present.” /atn — Alan Watts",
    "“Music expresses that which cannot be put into words and that which cannot remain silent.” /atn — Victor Hugo",
    "“Music gives a soul to the universe, wings to the mind, flight to the imagination and life to everything.” /atn — Plato",
    "“Music has a poetry of its own, and that poetry is called melody.” /atn — Joshua Logan",
    "“There are two means of refuge from the misery of life music and cats.” /atn — Albert Schweitzer",
    "“Music is a language that doesn’t speak in particular words. It speaks in emotions, and if it’s in the bones, it’s in the bones.” /atn — Keith Richards, According to the Rolling Stones",
    "“The world’s most famous and popular language is music.” /atn — PSY",
    "“Music is feeling. You can try to verbalize it. It really just hits you or it doesn’t.” /atn — Gene Simmons",
    "“If I were not a physicist, I would probably be a musician. I often think in music. I live my daydreams in music. I see my life in terms of music.” /atn — Albert Einstein",
    "“To stop the flow of music would be like the stopping of time itself, incredible and inconceivable.” /atn — Aaron Copland",
    "“Take a music bath once or twice a week for a few seasons. You will find it is to the soul what a water bath is to the body.” /atn — Oliver Wendell Holmes",
    "“Music has healing power. It has the ability to take people out of themselves for a few hours.” /atn — Elton John",
    "“I haven’t understood a bar of music in my life, but I have felt it.” /atn — Igor Stravinsky",
    "“Music is the tool to express life—and all that makes a difference.” /atn — Herbie Hancock",
    "“After silence, that which comes nearest to expressing the inexpressible is music.” /atn — Aldous Huxley, Music at Night and Other Essays",
    "“Music is forever; music should grow and mature with you, following you right on up until you die.” /atn — Paul Simon",
    "“If you cannot teach me to fly, teach me to sing.” /atn — J.M. Barrie, Peter Pan",
    "“Music is a weapon in the war against unhappiness.” /atn — Jason Mraz",
    "“Music is the emotional life of most people.” /atn — Leonard Cohen",
    "“Life, he realized, was much like a song. In the beginning, there is mystery, in the end, there is confirmation, but it's in the middle where all the emotion resides to make the whole thing worthwhile.” /atn — Nicholas Sparks, The Last Song",
    "“Music is a great natural high and a great natural escape.” /atn — Shania Twain",
    "“The music is not in the notes, but in the silence between.” /atn — Wolfgang Amadeus Mozart",
    "“Music is my religion.” /atn — Jimi Hendrix",
    "“Life and love go on, let the music play.” /atn — Johnny Cash",
    "“Music is powerful. As people listen to it, they can be affected. They respond.” /atn — Ray Charles",
    "“People haven’t always been there for me, but music always has.” /atn — Taylor Swift",
    "“Without music to decorate it, time is just a bunch of boring production deadlines or dates by which bills must be paid.” /atn — Frank Zappa",
    "“My music isn’t just music—it’s medicine.” /atn — Kanye West",
    "“People ask me how I make music. I tell them I just step into it. It’s like stepping into a river and joining the flow. Every moment in the river has its song.” /atn — Michael Jackson",
    "“Music in the soul can be heard by the universe.” /atn — Lao Tzu",
    "“Without music, life would be a mistake.” /atn — Friedrich Nietzsche, Twilight of the Idols",
    "“If I should ever die, God forbid, let this be my epitaph: ‘The only proof he needed for the existence of God was music.’” /atn — Kurt Vonnegut",
    "“Music does a lot of things for a lot of people. It’s transporting, for sure. It can take you right back, years back, to the very moment certain things happened in your life. It’s uplifting, it’s encouraging, it’s strengthening.” /atn — Aretha Franklin",
    "“If music be the food of love, play on.” /atn — William Shakespeare, Twelfth Night",
    "“Softly, deftly, music shall caress you. Hear it, feel it, secretly possess you.” /atn — Andrew Lloyd Webber, The Phantom of the Opera",
    "“I think there is a song out there to describe just about any situation.” /atn — Criss Jami, Killisophy",
    "“Why not just live in the moment, especially if it has a good beat?” /atn — Goldie Hawn",
    "“Live your truth. Express your love. Share your enthusiasm. Take action towards your dreams. Walk your talk. Dance and sing to your music. Embrace your blessings. Make today worth remembering.” /atn — Steve Maraboli",
    "“The more you love music, the more music you love.” /atn — Tom Moon",
    "“Such sweet compulsion doth in music lie.” /atn — John Milton",
    "“Music is very spiritual, it has the power to bring people together.” /atn — Edgar Winter",
    "“A bird does not sing because it has an answer. It sings because it has a song.” /atn — Chinese Proverb",
    "“We are the music-makers, and we are the dreamers of dreams.” /atn — Arthur O’Shaughnessy",
    "“Music can change the world because it can change people.” /atn — Bono",
    "“Music isn’t just a pleasure, a transient satisfaction. It’s a need, a deep hunger; and when the music is right, it’s joy. Love. A foretaste of heaven.” /atn — Orson Scott Card",
    "“Music is the soundtrack to every good and bad time we will ever have.” /atn — Alex Gaskarth",
    "“Music doesnt get in. Music is already in. Music simply uncovers what is there, makes you feel emotions that you didn't necessarily know you had inside you and runs around waking them all up. A rebirth of sorts.” /atn — Matt Haig, How to Stop Time",
    "“When you're happy, you enjoy the music but when you're sad, you understand the lyrics.” /atn — Frank Ocean",
    "“That’s one of the great things about music. You can sing a song to 85,000 people and they’ll sing it back for 85,000 different reasons.” /atn — Dave Grohl",
    "“Music is everywhere. It’s in the air between us, waiting to be sung.” /atn — David Levithan, How They Met and Other Stories",
    "“Music is nothing else but wild sounds civilized into time and tune.” /atn — Thomas Fuller",
    "“Music is well said to be the speech of angels; in fact, nothing among the utterances allowed to man is felt to be so divine. It brings us near to the infinite.” /atn — Thomas Carlyle",
];

export default MUSIC_QUOTES;
