import React, { useRef, useState } from "react";
import { Col, Row } from "antd";

import PlaylistManagerHeader from "../../../components/admin/playlist/PlaylistManagerHeader";
import SongsTable from "../../../components/admin/playlist/SongsTable";
import { useDispatch } from "react-redux";
import { updateSong, removeSong } from "../../../redux/actions/playlist";
import { searchedSongs } from "../../../utils/PlaylistUtils";
import WarningModal from "../../../components/modals/WarningModal";
import ShowToast from "../../../utils/ShowToast";

const PlaylistCreateContainer = ({
    playlistName,
    setPlaylistName,
    playlistDuration,
    setPlaylistDuration,
    searchPlaylistTerm,
    songs,
    setSongs,
}) => {
    const dispatch = useDispatch();
    const refCurrentSong = useRef(null);

    const [deleteSongId, setDeleteSongId] = useState(null);
    const [deleteSongLoading, setDeleteSongLoading] = useState(false);

    const handleSongPlayAction = (songId) => {
        const updateSongs = [...songs];
        const index = updateSongs.findIndex((song) => song.songId === songId);
        updateSongs.forEach((element, position) => {
            if (position !== index) {
                element.song.audio.pause();
                element.song.audio.currentTime = 0;
                element.song.isPlaying = false;
            }
        });
        updateSongs[index].song.isPlaying = !updateSongs[index].song.isPlaying;
        updateSongs[index].song.isPlaying
            ? updateSongs[index].song.audio.play()
            : updateSongs[index].song.audio.pause();
        updateSongs[index].song.isPlaying
            ? (refCurrentSong.current = updateSongs[index].song.audio)
            : null;
        setSongs(updateSongs);
        updateSongs[index].song.audio.onended = () => {
            const updateSongs = [...songs];
            updateSongs[index].song.isPlaying = false;
            setSongs(updateSongs);
        };
    };

    const updateSongRecord = (value, songId, attr) => {
        const index = songs.findIndex((song) => song.songId === songId);
        const updateSongs = [...songs];
        updateSongs[index][attr] = value;
        const { title, artist, comment } = updateSongs[index];
        updateSongs[index].isUpdated = true;
        dispatch(updateSong(songId, { title, artist, comment }));
        setSongs(updateSongs);
    };

    const onDeleteSong = (songId) => {
        setDeleteSongId(songId);
    };

    const handleDeleteSong = () => {
        setDeleteSongLoading(true);
        const updateSongs = [...songs];
        const index = updateSongs.findIndex(
            (song) => song.songId === deleteSongId
        );
        updateSongs.splice(index, 1);
        setSongs(updateSongs);

        new Promise((resolve) =>
            resolve(dispatch(removeSong(deleteSongId)))
        ).then(() => {
            setDeleteSongLoading(false);
            setDeleteSongId(null);
            ShowToast("Deleted Song Successfully!","success",null,2)

        });
    };

    return (
        <Row className="playlist-manager-songs-container">
            <WarningModal
                onCancel={()=> setDeleteSongId(null)}
                visible={deleteSongId}
                onDelete={handleDeleteSong}
                isLoading={deleteSongLoading}
            />

            <Col span={24}>
                <PlaylistManagerHeader
                    playlistName={playlistName}
                    onChangePlaylistName={(value) => setPlaylistName(value)}
                    playlistDuration={playlistDuration}
                    onChangePlaylistDuration={setPlaylistDuration}
                />
            </Col>
            <Col span={24}>
                <SongsTable
                    songs={searchedSongs(songs, searchPlaylistTerm)}
                    handleSongPlayAction={handleSongPlayAction}
                    handleDeleteSong={onDeleteSong}
                    updateSong={updateSongRecord}
                />
            </Col>
        </Row>
    );
};

export default PlaylistCreateContainer;
