import React from "react";
import { Form, Input, Typography, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

import { JindoWhiteLogo } from "../../utils/Icons";

const { Text } = Typography;

const ForgetPasswordForm = ({ onFormSubmit, formRef }) => {
    return (
        <>
            <Row
                justify='space-between'
                align='middle'
                className='forget-password-form-container'
            >
                <Col>
                    <Row justify='center' align='middle' gutter={[22, 22]}>
                        <Col style={{ margin: 35 }}>
                            <Link to='/'>
                                <JindoWhiteLogo
                                    style={{ width: 130, height: 94 }}
                                />
                            </Link>
                        </Col>
                        <Col className='auth-form-sub-text' span={24}>
                            <Text>FORGOT YOUR PASSWORD?</Text>
                        </Col>
                        <Col className='auth-form-sub-text' span={22}>
                            <Text>
                                Enter the email address connected to your
                                account
                            </Text>
                        </Col>
                        <Col className='forget-form-email-container' span={24}>
                            <Form
                                ref={formRef}
                                name='forgetPassword'
                                onFinish={onFormSubmit}
                            >
                                <Row justify='center'>
                                    <Col span={24}>
                                        <Form.Item
                                            name='email'
                                            className='custom-input'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please Enter Email",
                                                },
                                                {
                                                    type: "email",
                                                    message:
                                                        "The input is not a valid E-mail!",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Email' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} style={{ marginTop: 27 }}>
                                        <Button
                                            className='high-priority-btn'
                                            htmlType='submit'
                                            style={{ height: 46 }}
                                            block
                                        >
                                            VERIFY EMAIL
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                    <Text className='need-account-text'>
                        Need to create an account?
                    </Text>
                    <br />
                    <a
                        href='https://www.playjindo.com/store/p/vip-subscribe-monthly-jindo'
                        target='_blank'
                    >
                        <Text className='singup-link'>
                            Sign Up Today
                        </Text>
                    </a>
                    <Text className='copyright-text'>
                        ©Copyright 2022 Jindo LLC
                    </Text>
                </Col>
            </Row>
        </>
    );
};

export default ForgetPasswordForm;
