import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "antd";
import { fetchActiveGame } from "../../../redux/actions/game";
import { playerCreate } from "../../../redux/actions/player";
import GameCode from "../../../components/player/gameCode/GameCode";

const GameCodeContainer = () => {
    const formRef = Form.useForm();
    const dispatch = useDispatch();

    const { activeGame, isLoading } = useSelector((state) => state.game);
    const navigate = useNavigate();
    const jamCode = window.location.hash.substring("1");
    const [playerData, setPlayerData] = useState({});

    useEffect(() => {
        // if (jamCode) {
        //     dispatch(fetchActiveGame({ jamCode }));
        // }
        setPlayerData(JSON.parse(localStorage.getItem("player-data")));
    }, []);

    useEffect(() => {
        if (activeGame && activeGame.hasOwnProperty("jamId")) {
            navigate(`/${activeGame.host.userName}`);
        }
    }, [activeGame]);

    useEffect(() => {
        formRef.current.setFieldsValue({
            userName: playerData?.name,
        });
    }, [playerData]);

    const handleSubmit = (values) => {
        dispatch(fetchActiveGame({ jamCode: values.jamCode }));
        if (playerData?.name !== values.userName) {
            dispatch(
                playerCreate({
                    name: values.userName,
                    email: "",
                    userId: "",
                    phoneNumber: null,
                })
            );
        }
    };

    return (
        <Row justify='center' align='top' className='game-container'>
            <Col span={20}>
                <GameCode
                    formRef={formRef}
                    handleSubmit={handleSubmit}
                    jamCode={jamCode}
                    isLoading={isLoading}
                />
            </Col>
        </Row>
    );
};

export default GameCodeContainer;
