import React from "react";
import { Col, Button } from "antd";
import usePlayers from "../../../hooks/usePlayers";

const JamDeckFooter = ({
    onJamStart,
    onJamSave,
    activeButton,
    gameId
}) => {
    let activePlayersCount = 0;
    if(gameId) {
        const {activePlayersCount: activePlayer} = usePlayers(gameId)
        activePlayersCount = activePlayer
    }
    
    return (
        <>
            {activeButton === 'saveJam' &&
            <Col span={24} className='jam-submit-btn-container' style={{position: 'relative'}} >
                <Button
                    className='jam-deck-create-button'
                    block
                    onClick={onJamSave}
                >
                    SAVE JAM + INVITE PLAYERS
                </Button>
            </Col>}
            {activeButton === 'jamStart' &&
            <Col span={24} className='jam-submit-btn-container' style={{position: 'relative'}} >
                <Button
                    className='jam-deck-create-button'
                    block
                    onClick={onJamStart}
                    disabled={activePlayersCount === 0}
                >
                    LET’S START JAMMING!
                </Button>
            </Col>}
        </>
    );
};

export default JamDeckFooter;
