import {
    REQUEST_FETCH_USERS,
    FETCHED_USERS,
    FAILED_FETCH_USERS,
} from "../actionTypes/users";

import UserService from "../../services/UserService";

const requestFetchUsers = () => {
    return {
        type: REQUEST_FETCH_USERS,
    };
};

const fetchedUsers = (data) => {
    return {
        type: FETCHED_USERS,
        payload: data,
    };
};

const failedToGetUsers = (error) => {
    return {
        type: FAILED_FETCH_USERS,
        payload: error,
    };
};

export const fetchUsers = () => {
    return async (dispatch) => {
        try {
            dispatch(requestFetchUsers());
            const { data } = await UserService.fetchAllUsers();
            const users = data.map((user) => {
                user.key = user.userId;
                return user;
            });
            dispatch(fetchedUsers(users));
        } catch ({ response }) {
            dispatch(failedToGetUsers(response));
        }
    };
};
