import React from "react";
import { Modal, Row, Col, Button, Space, Typography } from "antd";
import { QuitIcon } from "../../utils/Icons";

const { Text } = Typography;

const GameQuitModal = ({
    visible,
    onCloseModal,
    handleQuitGame,
    BigRedSplashIcon,
}) => {
    return (
        <Modal
            className='game-splash-modal'
            open={visible}
            closable={false}
            footer={false}
            mask={true}
            destroyOnClose={true}
        >
            <Row justify='center' align='bottom'>
                <Col span={24} className='game-splash-title-container'>
                    <Row
                        justify='center'
                        align='middle'
                        className='game-play-splash-component'
                    >
                        <Col span={24}>
                            <BigRedSplashIcon
                                className='message-splash'
                                style={{
                                    opacity: 1,
                                }}
                            />
                        </Col>
                        <Col className='game-play-splash-container' span={24}>
                            <Space
                                direction='vertical'
                                style={{ textAlign: "center" }}
                            >
                                <QuitIcon className='message-splash-icon' />

                                <Text className={"message-splash-sub-text"}>
                                    ARE YOU SURE YOU WANT TO QUIT PLAYING?
                                </Text>
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col style={{ position: "fixed", bottom: 85, zIndex: 1000 }}>
                    <Row className='jam-end-splash-footer'>
                        <Col span={12}>
                            <Button
                                block
                                className='jam-end-secondary-btn'
                                onClick={onCloseModal}
                            >
                                NO, KEEP PLAYING
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                block
                                className='jam-end-primary-btn'
                                onClick={handleQuitGame}
                            >
                                YES, QUIT GAME
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default GameQuitModal;
