import { useEffect, useState} from 'react';
import { useJamProvider } from '../context/collaborationContext';

export default function useJindoCall() {
    const { document } = useJamProvider();
    const [jindoCall, _setJindoCall] = useState(null);

    useEffect(() => {
        const jindoCallMap = document.getMap('jindoCall');

        const update = () => {
            _setJindoCall(Object.fromEntries(jindoCallMap.entries()))
        };

        jindoCallMap.observe(update);   

        return () => {
            jindoCallMap.unobserve(update);
        };
    }, [document]);

    return { jindoCall };
}