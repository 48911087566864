import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CollaborationProvider from "../../../components/CollaborationProvider";
import { UserContext } from "../../../context/userContext";
import { fetchUser } from '../../../redux/actions/user';
import JamDeckContainer from './JamDeckContainer';

const JamDeckProvider = () => {
    const dispatch = useDispatch();
    const jamId = window.location.hash.substring("1");

    const { userData } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(fetchUser());
    }, []);


    return ( 
        userData && 
        <UserContext.Provider value={userData}>
            <CollaborationProvider isHost={true} jamId={jamId} >
                <JamDeckContainer jamId={jamId} />
            </CollaborationProvider>
        </UserContext.Provider>
    );
};
JamDeckProvider.whyDidYouRender = true
export default JamDeckProvider;
