import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form, Row, Col } from "antd";

import UserProfileHome from "../../components/userProfile/UserProfileHome";
import {
    fetchUser,
    updateUser,
    updateUserImage,
    changePassword,
} from "../../redux/actions/user";
import ShowToast from "../../utils/ShowToast";
import moment from "moment";

const UserProfileHomeContainer = () => {
    const dispatch = useDispatch();
    const [userPersonalForm] = Form.useForm();
    const [updatedText, setUpdatedText] = useState('')
    const { userData, isLoading, error, isError, isUpdated } = useSelector(
        (state) => state.user
    );

    useEffect(() => {
        dispatch(fetchUser());
    }, []);

    useEffect(() => {
        if (isError) {
            ShowToast(
                typeof error === "string" ? error : "Update Failed!",
                "error",
                null,
                2
            );
        }
        if (isUpdated) {
            ShowToast(updatedText, "success", null, 2);
        }
    }, [isError, isUpdated]);
    useEffect(() => {
        if (userData) {
            userPersonalForm.setFieldsValue({
                ...userData,
                dob: userData.dob ? moment(userData.dob) : moment('01/01/2000', 'MM/DD/YYYY'),
            });
        }
    }, [userData]);

    const handleSaveUser = (values) => {
        setUpdatedText('Profile Updated!')
        let data = {
            ...values,
            dob: values.dob ? moment(values?.dob).format("YYYY-MM-DD") : "01 / 01/ 2000",
        }
        dispatch(updateUser(data, true));
    };

    const handleChangePassword = async (values) => {
        setUpdatedText('Password Updated!')
        dispatch(changePassword(values));
    };

    const handleUpdateUserImage = async (image) => {
        setUpdatedText('Image Updated!')
        dispatch(updateUserImage(image));
    };

    const handleUpdateLinks = async (links) => {
        setUpdatedText('Links Updated!')
        dispatch(updateUser(links));
    };

    return (
        <Row
            justify='center'
            align='stretch'
            className='action-container-wrapper'
            style={{ width: 375 }}
        >
            <Col span={24}>
                <UserProfileHome
                    onSaveUser={handleSaveUser}
                    onPasswordChange={handleChangePassword}
                    onChangeImage={handleUpdateUserImage}
                    displayImage={userData ? userData.profileImage : null}
                    isLoading={isLoading}
                    userData={userData}
                    userPersonalForm={userPersonalForm}
                    handleUpdateLinks={handleUpdateLinks}
                />
            </Col>
        </Row>
    );
};

export default UserProfileHomeContainer;
