import {
    REQUEST_PLAYER_INFO,
    FETCHED_PLAYER_INFO,
    RESET_PLAYER_INFO,
} from "../actionTypes/player";

const initialState = {
    playerInfo: {},
    error: null,
    isLoading: false,
    errorMessage: null,
};

const player = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_PLAYER_INFO:
            return {
                ...state,
                error: null,
                isLoading: true,
            };

        case FETCHED_PLAYER_INFO:
            return {
                ...state,
                isLoading: false,
                playerInfo: action.payload,
                error: null,
            };

        case RESET_PLAYER_INFO:
            return {
                ...state,
                isLoading: false,
                playerInfo: {},
            };
        default:
            return state;
    }
};

export default player;
