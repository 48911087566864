import React from "react";
import { Row, Col, Typography, Modal } from "antd";
import ActionButton from "../common/ActionButton";
import { ArrowIconSVG, CrossBorderdIcon, UserIconSVG } from "../../utils/Icons";

const { Text } = Typography;

const WarningModal = ({
    visible,
    onDelete,
    onCancel,
    isLoading = false,
    okText = "delete",
    cancelText = "cancel",
}) => {
    return (
        <Modal
            className="warning-modal"
            title={false}
            open={visible}
            footer={false}
            onOk={onDelete}
            onCancel={null}
            bodyStyle={null}
            closable={false}
            centered={true}
        >
            <Row justify="center">
                <Col className="warning-splash-container">
                    {["Are", "you", "Sure?"].map((text, index) => (
                        <Text className="warning-splash-text" key={index}>
                            {text}
                        </Text>
                    ))}
                </Col>

                <Col
                    span={24}
                    className="jam-deck-close-modal-play-btn-container"
                >
                    <ActionButton
                        title={okText}
                        className="high-priority-btn"
                        onClick={onDelete}
                        prefixIconSVG={UserIconSVG}
                        postfixIconSVG={ArrowIconSVG}
                        isLoading={isLoading}
                    />
                </Col>
                <Col
                    span={24}
                    className="jam-deck-close-modal-quit-btn-container"
                >
                    <ActionButton
                        title={cancelText}
                        className="low-priority-btn"
                        onClick={onCancel}
                        prefixIconSVG={CrossBorderdIcon}
                        postfixIconSVG={ArrowIconSVG}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default WarningModal;
