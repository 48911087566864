import {useEffect, useState} from 'react';
import {useJamProvider} from '../context/collaborationContext';
import jsonPatterns from '../assets/winningPatterns'

const { winningPatterns } = jsonPatterns

export default function useRounds() {
  const { document } = useJamProvider();
  const [rounds, setRounds] = useState([]);

  useEffect(() => {
    const roundsMap = document.getMap('rounds');

    const update = (event) => {
      // sync rounds state with the changes to the document
      setRounds(Array.from(roundsMap.values()))
    };

    // observe changes to the rounds map in document
    roundsMap.observe(update);   

    return () => {
        roundsMap.unobserve(update);
    };
  }, [document]);

  /**
   * Replace rounds array with a new array
   * @param {Array<Round>} rounds - New array for rounds
   */
  const updateRounds = (rounds) => {
    const roundsMap = document.getMap('rounds')
    
    // clear the rounds map if a round is deleted
    if (rounds.length < Array.from(roundsMap.keys()).length) {
      roundsMap.clear()
    }
    
    rounds.forEach((round) => {
      // set winning patterns if round is started
      // TODO: Fetch patterns from API call and use them here, rather than using JSON data.
      if (round.status === 'STARTED') {
        round.winningPattern.pattern = winningPatterns
            .filter((pattern) => pattern.name === round.winningPattern?.name)
            .map((item) => item.pattern)
      }

      // TODO: Replace round.key with UUID
      roundsMap.set(round.key.toString(), round)
    })
  }

  return { rounds, updateRounds };
}