export const INITIALIZE_GAME_REQUREST = "INITIALIZE_GAME_REQUREST";
export const FAILED_GAME_REQUEST = "FAILED_GAME_REQUEST";
export const SUCCESS_GAME_REQUEST = "SUCCESS_GAME_REQUEST";
export const FETCHED_GAMES = "FETCHED_GAMES";
export const FETCHED_ACTIVE_GAME = "FETCHED_ACTIVE_GAME";
export const RESET_GAME = "RESET_GAME";
export const FETCHED_ACTIVE_GAME_PLAYERS = "FETCHED_ACTIVE_GAME_PLAYERS";
export const FETCHED_ACTIVE_GAME_PLAYER_INFO = "FETCHED_ACTIVE_GAME_PLAYER_INFO";
export const GO_TO_NEXT_GAME = "GO_TO_NEXT_GAME";
export const JAM_ENDED = "JAM_ENDED";
export const NUMBER_OF_PLAYERS = "NUMBER_OF_PLAYERS";
export const RESET_ACTIVE_GAME = "RESET_ACTIVE_GAME";
export const IS_GAME_PAUSED = "IS_GAME_PAUSED";
export const IS_GAME_RESUMED = "IS_GAME_RESUMED";
export const CURRENT_SONG_FINISHED = "CURRENT_SONG_FINISHED";