import React, { useState } from "react";
import { Row, Col, Typography, Image, Upload } from "antd";

const { Text } = Typography;

import AvatarPic from "../../assets/media/avatar.png";

const UploadImageSplash = ({
    onCustomRequest,
    displayImage = null,
    isLoading,
}) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const Overlay = () => (
        <Row  className='upload-splash-img-overlay' justify="center" align="middle">
            <Col span={14}>
                <Text className='upload-splash-img-text'>edit profile pic</Text>
            </Col>
        </Row>
    );

    return (
        <Row justify='center' style={{ width: "100%", height: "100%" }}>
            <Col span={24}>
                <Upload
                    showUploadList={false}
                    accept='image/*'
                    customRequest={onCustomRequest}
                    className='upload-splash-input'
                    onMouseEnter={() => setShowOverlay(true)}
                    onMouseLeave={() => setShowOverlay(false)}
                >
                    <Row>
                        <Col className='upload-splash-img-container' span={24}>
                            <Image
                                src={
                                    isLoading
                                        ? ''
                                        : displayImage
                                        ? `${displayImage}`
                                        : ''
                                }
                                width={143}
                                height={143}
                                className='upload-splash-img'
                                preview={false}
                                style={displayImage ? { opacity: 1 } : {}}
                            />
                            {(!displayImage || showOverlay) && <Overlay />}
                        </Col>
                    </Row>
                </Upload>
            </Col>
        </Row>
    );
};

export default UploadImageSplash;
