import {
    REQUEST_USER_AUTH,
    FAILED_USER_AUTH,
    SUCCESS_USER_AUTH,
    RESET_USER,
} from "../actionTypes/authUser";

import AuthService from "../../services/AuthService";
import { fetchUser } from "./user";

const requestUserAuth = () => {
    return {
        type: REQUEST_USER_AUTH,
    };
};

const failedUserAuth = (payload) => {
    return {
        type: FAILED_USER_AUTH,
        payload,
    };
};

const successUserAuth = (payload) => {
    return {
        type: SUCCESS_USER_AUTH,
        payload,
    };
};

export const resetAuthUser = () => {
    return {
        type: RESET_USER,
    };
};

export const signup = (signupInput) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserAuth());
            const { status, data } = await AuthService.signup(signupInput);
            if (status === 200) {
                dispatch(successUserAuth(data));
            }
            return true
        } catch (error) {
            const {
                response: { data },
            } = error;
            if (data.error) {
                dispatch(failedUserAuth(data.error));
            } else {
                dispatch(failedUserAuth(data));
            }
            throw new Error(error);
        }
    };
};

export const login = (loginInput) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserAuth());
            const { status, data } = await AuthService.login(loginInput);
            if (status === 200) {
                dispatch(successUserAuth(data));
            }
        } catch (error) {
            if (error.response) {
                const {
                    response: { data },
                } = error;
                dispatch(failedUserAuth(data.error));
            }
            console.log(error);
        }
    };
};

export const sendConfirmCode = (sendConfirmCodeInput) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserAuth());
            const { status, data } = await AuthService.sendConfirmCode(
                sendConfirmCodeInput
            );
        } catch (error) {
            const {
                response: { data },
            } = error;
            dispatch(failedUserAuth(data));
            throw new Error(error);
        }
    };
};

export const verifyUser = (verifyInput) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserAuth());
            const status = await AuthService.verifyuser(verifyInput);
            if (status === 200) {
                dispatch(fetchUser());
            }
        } catch (error) {
            const {
                response: { data },
            } = error;
            dispatch(failedUserAuth(data.error.message));
            throw new Error(data.error.message);
        }
    };
};

export const resetPassword = (formData) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserAuth());
            const { status, data } = await AuthService.resetPassword(formData);
            if (status === 200) {
                dispatch(successUserAuth(data));
            }
        } catch (error) {
            const {
                response: { data },
            } = error;
            dispatch(failedUserAuth(data.error));
            throw new Error(data.error);
        }
    };
};

export const updateNewPassword = (formData) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserAuth());
            const { status, data } = await AuthService.updateNewPassword(
                formData
            );
            if (status === 200) {
                dispatch(successUserAuth(data));
            }
        } catch (error) {
            if (error.response) {
                const {
                    response: { data },
                } = error;
                dispatch(failedUserAuth(data.error));
            }
            console.log(error);
        }
    };
};

export const sendContactUsInfo = (contactUsInfo) => {
    return async (dispatch) => {
        try {
            dispatch(requestUserAuth());
            const { status, data } = await AuthService.contactUs(contactUsInfo);
            if (status === 200) {
                dispatch(successUserAuth(data));
            }
        } catch (error) {
            if (error.response) {
                const {
                    response: { data },
                } = error;
                dispatch(failedUserAuth(data.error));
            }
            console.log(error);
        }
    };
};
