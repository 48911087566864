import React from "react";
import QRCode from "qrcode";
import { Row, Col } from "antd";
import InviteFriends from "../../../components/player/inviteFriends/InviteFriends";
import ShowToast from "../../../utils/ShowToast";
import usePlayers from "../../../hooks/usePlayers";

const InvitePlayerContainer = ({ jam, activeGame }) => {
    const { activePlayersCount } = usePlayers(activeGame?.gameId)
    
    const handleCopyJamLink = async () => {
        if (jam) {
            await navigator.clipboard.writeText(
                `${window.location.origin}/${jam.host.name}`
            );
            ShowToast("Link Copied!", "success");
        } else {
            ShowToast("Jam Not Available Yet", "error");
        }
    };

    const generateQR = async (text) => {
        
        try {
            return await QRCode.toDataURL(text, {
                width: 800,
                color: {
                    dark: '#FF14BD'
                }
            })
          } catch (err) {
            console.error(err)
          }
    }
    const download = async () => {
        const QR = await generateQR(`${window.location.origin}/${jam?.host?.name}`)
        const newCanvas = document.createElement("img");
        newCanvas.height = 800;
        newCanvas.width = 800;
        newCanvas.src = QR;
        
        let downloadLink = document.createElement("a");
        downloadLink.href = QR;
        downloadLink.download = `JINDO_QR.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <Row justify='center' align='middle'>
            <Col>
                <InviteFriends
                    handleCopyJamLink={handleCopyJamLink}
                    jamCode={jam?.jamCode}
                    fromJamDeck={true}
                    hostName={jam?.host?.name}
                    numOfPlayers={activePlayersCount}
                    downLoadQR={download}
                />
            </Col>
        </Row>
    );
};

export default InvitePlayerContainer;
