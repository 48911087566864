export const getEstimatedGameTime = (pace, pattern) => {
    switch (pattern) {
        case "Postage Stamp":
            return pace === 15 ? 7 : 12;
        case "4 Corners":
            return pace === 15 ? 8 : 13;
        case "Single":
            return pace === 15 ? 6 : 11;
        case "Double":
            return pace === 15 ? 11 : 16;
        case "X":
            return pace === 15 ? 12 : 24;
        case "Blackout":
            return pace === 15 ? 16 : 32;
    }
};

export const createNewGame = (index) => {
    return {
        key: index,
        gameId: undefined,
        pace: 15,
        patternName: 'Single',
        patternId: '0b867d34-6d90-41dd-9ec9-30e38eda4cdc',
        playlistId: undefined,
        status: 'STARTING_SOON',
        prize: null,
        displaySong: true,
        numberOfSongs: 24,
        gameTime: getEstimatedGameTime(15, 'Single'),
    };
};

export const getJamTime = (rounds) => {
    return rounds.reduce((n, {gameTime}) => n + gameTime, 0)
};

export const updateGames = (index, attr, value, games, playlists, patterns) => {
    const updatedGames = [...games];
    if (attr === "pace") {
        updatedGames[index][attr] = value;
        updatedGames[index]["playlistId"] = undefined;
        updatedGames[index]["playlists"] = playlists.filter(
            (playlist) => playlist.speed === value
        );
        if (updatedGames[index].pace && updatedGames[index].pattern) {
            updatedGames[index].gameTime = getEstimatedGameTime(
                updatedGames[index].pace,
                updatedGames[index].patternName
            );
        }
    } else if (attr === "pattern") {
        const selectedPatterns = patterns.filter(
            (pat) => pat.name === value
        );
        const randomSelectedPattern =
            selectedPatterns[
                Math.floor(Math.random() * selectedPatterns.length)
            ];
        updatedGames[index].patternName = randomSelectedPattern.name;
        updatedGames[index].patternId = randomSelectedPattern.patternId;
        if (updatedGames[index].pace && updatedGames[index].patternId) {
            updatedGames[index].gameTime = getEstimatedGameTime(
                updatedGames[index].pace,
                updatedGames[index].patternName
            );
        }
    } else {
        updatedGames[index][attr] = value;
    }
    return updatedGames;
};

export const makeTransformation = (games = []) => {
    return games.map((game, index) => {
        return {
            ...game,
            title: `Game ${index + 1}`,
            patternName: game.patternName || game.winningPattern.name,
            playlist: game.playlist
        };
    });
};

export const validateGamesFields = (games) =>
games.every((game) => game.pace && game.patternId && game.playlistId);