import React from "react";
import { Row, Col, Typography } from "antd";

import Setting from "../../../components/host/jamDeck/Setting";

const { Text } = Typography;

const SettingContainer = ({
  speakerList,
  setSpeaker,
  selectedSpeaker,
  speakerVolume,
  setSpeakerVolume,
}) => {
  const handleSpeakerChange = (val) => {
    setSpeaker(val);
  };

  const handleVolumeUp = () => setSpeakerVolume(v => v === 1 ? v : Number(Number(v + 0.1).toFixed(1)));
  const handleVolumeDown = () => setSpeakerVolume(v => v === 0 ? v : Number(Number(v - 0.1).toFixed(1)));

  return (
    <Row justify="center" className="jam-deck-create-container">
      <Col span={24} style={{ marginTop: 34 }}>
        <Row justify="center">
          <Text className="song-played-count">ADJUST YOUR AUDIO SETTINGS</Text>
        </Row>
      </Col>
      <Setting
        speakerList={speakerList}
        handleSpeakerChange={handleSpeakerChange}
        speaker={selectedSpeaker}
        speakerVolume={speakerVolume}
        setSpeakerVolume={setSpeakerVolume}
        handleVolumeUp={handleVolumeUp}
        handleVolumeDown={handleVolumeDown}
      />

      {/* {jam?.jamCode && (
        <>
          <Col span={24} style={{ marginTop: 34 }}>
            <Row justify="center">
              <Text className="song-played-count">JAM SETTINGS</Text>
            </Row>
          </Col>
          <Row style={{ margin: "45px 40px", flex: 1 }}>
            <ActionButton
              title="end jam"
              buttonText="END JAM"
              className="end-jam-btn"
              onClick={onEndJam}
            />
          </Row>
        </>
      )} */}
    </Row>
  );
};

export default SettingContainer;
