import { useEffect, useState } from "react";
import useRound from "./useRound";

export default function useRoundNumberOfSongs(roundId) {
    const [numberOfSongs, _setNumberOfSongs] = useState(true)
    const {setFieldInRound, getFieldFromRound} = useRound(roundId)  

    useEffect(() => {
      // sync numberOfSongs with the value in rounds map in document
      _setNumberOfSongs(getFieldFromRound('numberOfSongs'))
    }, [roundId])

    /**
     * Update numberOfSongs to a given value
     * @param {number} value - New value of numberOfSongs to set
     */
    const setNumberOfSongs = (value) => {
      setFieldInRound('numberOfSongs', value)
      _setNumberOfSongs(value)
    }

    return {numberOfSongs, setNumberOfSongs}
}