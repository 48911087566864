import React from "react";
import Icon from "@ant-design/icons";

import JindoIconSVG from "../assets/media/jindoIcon.svg";
import JindoWithABeatSVG from "../assets/media/jindoWithABeat.svg";
import LockIconSVG from "../assets/media/lockIcon.svg";
import BackArrowSVG from "../assets/media/backArrowIcon.svg";
import CrossBorderdSVG from "../assets/media/crossBorderd.svg";
import JindoHostLogoSVG from "../assets/media/jindoHostLogo.svg";
import EditInputSVG from "../assets/media/editInputIcon.svg";

import TimeIconSVG from "../assets/media/timeIcon.svg";
import DropDownIconSVG from "../assets/media/dropDownIcon.svg";
import JamIconSVG from "../assets/media/jamIcon.svg";
import SpeakerIconSVG from "../assets/media/speakerIcon.svg";
import MicIconSVG from "../assets/media/micIcon.svg";
import SettingIconSVG from "../assets/media/settingIcon.svg";
import ShareIconSVG from "../assets/media/shareIcon.svg";
import PlaylistIconSVG from "../assets/media/playlistIcon.svg";
import PrizeIconSVG from "../assets/media/prizeIcon.svg";
import DetailsIconSVG from "../assets/media/detailsIcon.svg";
import PlayersIconSVG from "../assets/media/playersIcon.svg";
import HomeIconSVG from "../assets/media/homeIcon.svg";
import JindoCoinIconSVG from "../assets/media/jindoCoinIcon.svg";
import AttachmentIconSVG from "../assets/media/attachmentIcon.svg";
import ContactInfoIconSVG from "../assets/media/contactInfoIcon.svg";
import HostIconSVG from "../assets/media/userIcon.svg";
import AuthIconSVG from "../assets/media/authIcon.svg";
import BlackoutPatternIconSVG from "../assets/media/blackoutPatternIcon.svg";
import DoubleLinePatternIconSVG from "../assets/media/doubleLinePatternIcon.svg";
import FourCornerPatternIconSVG from "../assets/media/fourCornersPatternIcon.svg";
import PostageStampPatternIconSVG from "../assets/media/postageStampPatternIcon.svg";
import SingleLinePatternIconSVG from "../assets/media/singleLinePatternIcon.svg";
import XPatternIconSVG from "../assets/media/xPatternIcon.svg";
import SongsUploadIconSVG from "../assets/media/songsUploadIcon.svg";
import PlaylistSpeedIconSVG from "../assets/media/playlistSpeedIcon.svg";
import PlaylistStandardIconSVG from "../assets/media/playlistStandardIcon.svg";
import PlayIconSVG from "../assets/media/playIcon.svg";
import PauseIconSVG from "../assets/media/pauseIcon.svg";
import JindoHostDesktopIconSVG from "../assets/media/jindoHostDesktopIcon.svg";
import WebsiteLinkSVG from "../assets/media/websiteLink.svg"
import CreateJamSVG from "../assets/media/createJam.svg";
import InPersonJamTypeSVG from "../assets/media/inPersonJamType.svg";
import StreamingJamTypeSVG from "../assets/media/streamingJamType.svg";
import PlaylistBlackSVG from "../assets/media/playlistBlack.svg";
import PrizeBlackSVG from "../assets/media/prizeBlack.svg";






// Action buttons SVG
export {default as PlayIconSVG } from "../assets/media/playIcon.svg";
export {default as PauseIconSVG } from "../assets/media/pauseIcon.svg";
export {default as NextIconSVG } from "../assets/media/nextIcon.svg";
export {default as PlayJamIconSVG} from "../assets/media/playJam.svg";
export {default as PlayFingerIconSVG} from "../assets/media/playFinger.svg";
export {default as HostIconSVG} from "../assets/media/hostIcon.svg";
export {default as UserIconSVG} from "../assets/media/userIcon.svg";
export {default as AuthIconSVG} from "../assets/media/authIcon.svg";
export {default as ArrowIconSVG} from "../assets/media/arrowIcon.svg";
export {default as PhoneIconSVG} from "../assets/media/phoneIcon.svg";
export {default as MailIconSVG} from "../assets/media/mailIcon.svg";
export {default as GoogleWhiteIconSVG} from "../assets/media/googleWhiteIcon.svg";
export {default as FacebookWhiteIconSVG} from "../assets/media/facebookWhiteIcon.svg";
export {default as LinkSVG} from "../assets/media/linkIcon.svg";
export {default as MessageIconSVG} from "../assets/media/messageIcon.svg";
export {default as QrCodeIconSVG} from "../assets/media/qrCodeIcon.svg";

export {default as TextIconSVG} from "../assets/media/textIcon.svg";
export {default as AttachmentIconSVG} from "../assets/media/attachmentIcon.svg";
export {default as HomeIconSVG} from "../assets/media/homeIcon.svg";
export {default as CrossBorderdSVG} from "../assets/media/crossBorderd.svg";
export {default as JindoCoinIconSVG} from "../assets/media/jindoCoinIcon.svg";

export {default as SongIconSVG} from "../assets/media/songIcon.svg";
export {default as SettingIconSVG} from "../assets/media/settingIcon.svg";
export {default as RectangleIconSVG} from "../assets/media/rectangleIcon.svg";



import EditPenIconSVG from "../assets/media/editPenIcon.svg";
import InfoCardSVG from "../assets/media/infoCard.svg";
import PhoneSVG from "../assets/media/phoneDarkIcon.svg";
import EmailSVG from "../assets/media/emailIcon.svg";
import OpenLockSVG from "../assets/media/openLockIcon.svg";
import InfoSVG from "../assets/media/infoIcon.svg";
import HelpSVG from "../assets/media/helpIcon.svg";
import ShareSVG from "../assets/media/shareIcon.svg";
import RecentSVG from "../assets/media/recentIcon.svg";
import MusicNodeSVG from "../assets/media/musicNodeIcon.svg";
import PlayJamAntSVG from "../assets/media/playJam.svg";
import WinnerBadgeSVG from "../assets/media/winnerBadgeIcon.svg";
import GroupSVG from "../assets/media/groupIcon.svg";
import DiskSVG from "../assets/media/diskIcon.svg";
import SearchSVG from "../assets/media/searchIcon.svg";
import PlayFingerAntSVG from "../assets/media/playFinger.svg";
import ClickSVG from "../assets/media/clickIcon.svg";
import JindoPlaySVG from "../assets/media/jindoPlayIcon.svg";
import UserAntSVG from "../assets/media/userIcon.svg";
import JindoWhiteLogoSVG from "../assets/media/jindoWhiteLogo.svg";
import JindoProfileLogoSVG from "../assets/media/jindoProfileLogo.svg";
import JamEndJindoLogoSVG from "../assets/media/jamEndJindoLogo.svg";
import PlayersBlackSVG from "../assets/media/playersBlack.svg";
import PrizeTransparentBlackSVG from "../assets/media/prizeTransparentBlack.svg";
import JoinJamSVG from "../assets/media/joinJam.svg"
import GetHelpSVG from "../assets/media/getHelp.svg"
import AdminButtonSVG from "../assets/media/adminButton.svg"
import RedSplashSVG from "../assets/media/redSplash.svg";
import YellowSplashSVG from "../assets/media/yellowSplash.svg";
import GreenSplashSVG from "../assets/media/greenSplash.svg";
import CallFaceSVG from "../assets/media/callFaceIcon.svg";
import FaceBookSVG from "../assets/media/facebookIcon.svg";
import SpeedSVG from "../assets/media/15sIcon.svg";
import StandardSVG from "../assets/media/30sIcon.svg";
import ViewSVG from "../assets/media/eyeIcon.svg";
import DeleteIconSVG from "../assets/media/deleteIcon.svg";
import Disk2SVG from "../assets/media/disk2Icon.svg";
import PhoneHandleSVG from "../assets/media/phoneHandleIcon.svg";
import JamDeckSVG from '../assets/media/jamDeckIcon.svg';
import MusicBookSVG from '../assets/media/bookMusicIcon.svg';
import PrizePinkSVG from '../assets/media/prizePinkIcon.svg';
import Settings3WheelSVG from '../assets/media/settings3Wheel.svg'
import PlusSVG from '../assets/media/plusIcon.svg';
import MinusSVG from '../assets/media/minusIcon.svg';
import CrossSVG from '../assets/media/crossIcon.svg'
import JindoWhiteWithSplashSVG from '../assets/media/jindoWhiteWithSplash.svg';
import PlayersJoinedSVG from '../assets/media/playersJoinedIcon.svg';
import CopySVG from '../assets/media/copyIcon.svg';
import BigRedSplashSVG from '../assets/media/bigRedSplash.svg';
import OhNoSVG from '../assets/media/onNoIcon.svg';
import WinnerCelebrationSVG from '../assets/media/winnerCelebration.svg';

import QuitSVG from '../assets/media/quitIcon.svg'
import PlayersCountSVG from '../assets/media/playersCountIcon.svg'

import InstagramSVG from '../assets/media/instagramIcon.svg'
import SpotifySVG from '../assets/media/soptifyIcon.svg';
import YellowSplash1SVG from '../assets/media/yellowSplash1.png'
import YellowSplash2SVG from '../assets/media/yellowSplash2.png'
import YellowSplash3SVG from '../assets/media/yellowSplash3.png'
import ThreeNormalSVG from '../assets/media/3NormalSVG.svg' 
import SettingMicSVG from '../assets/media/settingMicIcon.svg' 
import SettingMusicSVG from '../assets/media/settingMusicIcon.svg' 


// ICONS
export const JindoIcon = (props) => <Icon component={() => (<JindoIconSVG {...props} />)} />;
export const JindoWithABeatIcon = (props) => <Icon component={() => (<JindoWithABeatSVG {...props} />)} />;

export const LockIcon = (props) => <Icon component={() => (<LockIconSVG {...props} />)} />;
export const BackArrowIcon = (props) => <Icon component={() => (<BackArrowSVG {...props} />)} />;
export const CrossBorderdIcon = (props) => <Icon component={() => (<CrossBorderdSVG {...props} />)} />;

export const EditPenIcon = (props) => <Icon component={() => (<EditPenIconSVG {...props} />)} />;
export const InfoCardIcon = (props) => <Icon component={() => (<InfoCardSVG {...props} />)} />;
export const PhoneIcon = (props) => <Icon component={() => (<PhoneSVG {...props} />)} />;
export const EmailIcon = (props) => <Icon component={() => (<EmailSVG {...props} />)} />;
export const OpenLockIcon = (props) => <Icon component={() => (<OpenLockSVG {...props} />)} />;

export const InfoIcon = (props) => <Icon component={() => (<InfoSVG {...props} />)} />;
export const HelpIcon = (props) => <Icon component={() => (<HelpSVG {...props} />)} />;
export const RecentIcon = (props) => <Icon component={() => (<RecentSVG {...props} />)} />;
export const MusicNodeIcon = (props) => <Icon component={() => (<MusicNodeSVG {...props} />)} />;
export const PlayJamAntIcon = (props) => <Icon component={() => (<PlayJamAntSVG {...props} />)} />;
export const WinnerBadgeIcon = (props) => <Icon component={() => (<WinnerBadgeSVG {...props} />)} />;
export const GroupIcon = (props) => <Icon component={() => (<GroupSVG {...props} />)} />;
export const DiskIcon = (props) => <Icon component={() => (<DiskSVG {...props} />)} />;
export const SearchIcon = (props) => <Icon component={() => (<SearchSVG {...props} />)} />;
export const PlayFingerAntIcon = (props) => <Icon component={() => (<PlayFingerAntSVG {...props} />)} />;
export const ClickIcon = (props) => <Icon component={() => (<ClickSVG {...props} />)} />;
export const JindoPlayIcon = (props) => <Icon component={() => (<JindoPlaySVG {...props} />)} />;
export const UserAntIcon = (props) => <Icon component={() => (<UserAntSVG {...props} />)} />;
export const JindoWhiteLogo = (props) => <Icon component={() => (<JindoWhiteLogoSVG {...props} />)} />;
export const JindoProfileLogo = (props) => <Icon component={() => (<JindoProfileLogoSVG {...props} />)} />;
export const JamEndJindoLogo = (props) => <Icon component={() => (<JamEndJindoLogoSVG {...props} />)} />;
export const PlayersBlackLogo = (props) => <Icon component={() => (<PlayersBlackSVG {...props} />)} />;
export const PrizeTransparentBlackLogo = (props) => <Icon component={() => (<PrizeTransparentBlackSVG {...props} />)} />;
export const CreateJamSVGIcon = (props) => <Icon component={() => (<CreateJamSVG {...props} />)} />;
export const JoinJamSVGIcon = (props) => <Icon component={() => (<JoinJamSVG {...props} />)} />;
export const GetHelpSVGIcon = (props) => <Icon component={() => (<GetHelpSVG {...props} />)} />;
export const AdminButtonSVGIcon = (props) => <Icon component={() => (<AdminButtonSVG {...props} />)} />;
export const RedSplashIcon = (props) => <Icon component={() => (<RedSplashSVG {...props} />)} />;
export const YellowSplashIcon = (props) => <Icon component={() => (<YellowSplashSVG {...props} />)} />;
export const GreenSplashIcon = (props) => <Icon component={() => (<GreenSplashSVG {...props} />)} />;
export const CallFaceIcon = (props) => <Icon component={() => (<CallFaceSVG {...props} />)} />;
export const JindoHostLogoIcon = (props) => <Icon component={() => (<JindoHostLogoSVG {...props} />)} />;
export const EditInputIcon = (props) => <Icon component={() => (<EditInputSVG {...props} />)} />;
export const TimeIcon = (props) => <Icon component={() => (<TimeIconSVG {...props} />)} />;
export const DropDownIcon = (props) => <Icon component={() => (<DropDownIconSVG {...props} />)} />;
export const JamIcon = (props) => <Icon component={() => (<JamIconSVG {...props} />)} />;
export const SpeakerIcon = (props) => <Icon component={() => (<SpeakerIconSVG {...props} />)} />;
export const MicIcon = (props) => <Icon component={() => (<MicIconSVG {...props} />)} />;
export const SettingIcon = (props) => <Icon component={() => (<SettingIconSVG {...props} />)} />;
export const ShareIcon = (props) => <Icon component={() => (<ShareIconSVG {...props} />)} />;
export const PlaylistIcon = (props) => <Icon component={() => (<PlaylistIconSVG {...props} />)} />;
export const PrizeIcon = (props) => <Icon component={() => (<PrizeIconSVG {...props} />)} />;
export const DetailsIcon = (props) => <Icon component={() => (<DetailsIconSVG {...props} />)} />;
export const PlayersIcon = (props) => <Icon component={() => (<PlayersIconSVG {...props} />)} />;
export const HomeIcon = (props) => <Icon component={() => (<HomeIconSVG {...props} />)} />;
export const JindoCoinIcon = (props) => <Icon component={() => (<JindoCoinIconSVG {...props} />)} />;
export const AttachmentIcon = (props) => <Icon component={() => (<AttachmentIconSVG {...props} />)} />;
export const ContactInfoIcon = (props) => <Icon component={() => (<ContactInfoIconSVG {...props} />)} />;
export const HostIcon = (props) => <Icon component={() => (<HostIconSVG {...props} />)} />;
export const AuthIcon = (props) => <Icon component={() => (<AuthIconSVG {...props} />)} />;
export const SingleLinePatternIcon = (props) => <Icon component={() => (<SingleLinePatternIconSVG {...props} />)} />;
export const DoubleLinePatternIcon = (props) => <Icon component={() => (<DoubleLinePatternIconSVG {...props} />)} />;
export const PostageStampPatternIcon = (props) => <Icon component={() => (<PostageStampPatternIconSVG {...props} />)} />;
export const BlackoutPatternIcon = (props) => <Icon component={() => (<BlackoutPatternIconSVG {...props} />)} />;
export const XPatternIcon = (props) => <Icon component={() => (<XPatternIconSVG {...props} />)} />;
export const FourCornerPatternIcon = (props) => <Icon component={() => (<FourCornerPatternIconSVG {...props} />)} />;
export const SongsUploadIcon = (props) => <Icon component={() => (<SongsUploadIconSVG {...props} />)} />;
export const PlaylistSpeedIcon = (props) => <Icon component={() => (<PlaylistSpeedIconSVG {...props} />)} />;
export const PlaylistStandardIcon = (props) => <Icon component={() => (<PlaylistStandardIconSVG {...props} />)} />;
export const DeleteIcon = (props) => <Icon component={() => (<DeleteIconSVG {...props} />)} />;
export const PlayIcon = (props) => <Icon component={() => (<PlayIconSVG {...props} />)} />;
export const PauseIcon = (props) => <Icon component={() => (<PauseIconSVG {...props} />)} />;
export const FacebookIcon = (props) => <Icon component={() => (<FaceBookSVG {...props} />)} />;
export const Speed15sIcon = (props) => <Icon component={() => (<SpeedSVG {...props} />)} />;
export const Speed30sIcon = (props) => <Icon component={() => (<StandardSVG {...props} />)} />;
export const ViewIcon = (props) => <Icon component={() => (<ViewSVG {...props} />)} />;
export const Disk2Icon = (props) => <Icon component={() => (<Disk2SVG {...props} />)} />;
export const PhoneHandleIcon = (props) => <Icon component={() => (<PhoneHandleSVG {...props} />)} />;
export const JindoHostDesktopIcon = (props) => <Icon component={() => (<JindoHostDesktopIconSVG {...props} />)} />;
export const WebsiteLinkIcon = (props) => <Icon component={() => (<WebsiteLinkSVG {...props} />)} />;
export const JamDeckIcon = (props) => <Icon component={() => (<JamDeckSVG {...props} />)} />;
export const InPersonJamTypeIcon = (props) => <Icon component={() => (<InPersonJamTypeSVG {...props} />)} />;
export const StreamingJamTypeIcon = (props) => <Icon component={() => (<StreamingJamTypeSVG {...props} />)} />;
export const MusicBookIcon = (props) => <Icon component={() => (<MusicBookSVG {...props} />)} />;
export const PrizePinkIcon = (props) => <Icon component={() => (<PrizePinkSVG {...props} />)} />;
export const Settings3WheelIcon = (props) => <Icon component={() => (<Settings3WheelSVG {...props} />)} />;
export const PlusIcon = (props) => <Icon component={() => (<PlusSVG {...props} />)} />;
export const MinusIcon = (props) => <Icon component={() => (<MinusSVG {...props} />)} />;
export const CrossIcon = (props) => <Icon component={() => (<CrossSVG {...props} />)} />;
export const JindoWhiteWithSplashIcon = (props) => <Icon component={() => (<JindoWhiteWithSplashSVG {...props} />)} />;
export const PlayersJoinedIcon = (props) => <Icon component={() => (<PlayersJoinedSVG {...props} />)} />;
export const CopyIcon = (props) => <Icon component={() => (<CopySVG {...props} />)} />;
export const BigRedSplashIcon = (props) => <Icon component={() => (<BigRedSplashSVG {...props} />)} />;
export const OnNoIcon = (props) => <Icon component={() => (<OhNoSVG {...props} />)} />;
export const WinnerCelebrationIcon = (props) => <Icon component={() => (<WinnerCelebrationSVG {...props} />)} />;
export const QuitIcon = (props) => <Icon component={() => (<QuitSVG {...props} />)} />;
export const InstagramIcon = (props) => <Icon component={() => (<InstagramSVG {...props} />)} />;
export const SoptifyIcon  = (props) => <Icon component={() => (<SpotifySVG {...props} />)} />;
export const YellowSplash1Icon = (props) => <Icon component={() => (<YellowSplash1SVG {...props} />)} />;
export const YellowSplash2Icon = (props) => <Icon component={() => (<YellowSplash2SVG {...props} />)} />;
export const YellowSplash3Icon = (props) => <Icon component={() => (<YellowSplash3SVG {...props} />)} />;
export const ThreeNormalIcon = (props) => <Icon component={() => (<ThreeNormalSVG {...props} />)} />;
export const PlaylistBlackIcon = (props) => <Icon component={() => (<PlaylistBlackSVG {...props} />)} />;

export const PrizeBlackIcon = (props) => <Icon component={() => (<PrizeBlackSVG {...props} />)} />;
export const PlayersCountIcon = (props) => <Icon component={() => (<PlayersCountSVG {...props} />)} />;

export const SettingMicIcon = (props) => <Icon component={() => (<SettingMicSVG {...props} />)} />;
export const SettingMusicIcon = (props) => <Icon component={() => (<SettingMusicSVG {...props} />)} />;


