import {
    INITIALIZE_JAM_REQUREST,
    SUCCESS_JAM_REQUEST,
    FAILED_JAM_REQUEST,
    RESET_JAM
} from "../actionTypes/jam";

const initialState = {
    jam: {},
    isLoading: false,
    error: null,
};

const jam = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_JAM_REQUREST:
            return {
                ...state,
                isLoading: true,
            };
        case SUCCESS_JAM_REQUEST:
            return {
                ...state,
                isLoading: false,
                jam: action.payload,
            };
        case RESET_JAM:
            return initialState;
        case FAILED_JAM_REQUEST:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default jam;
