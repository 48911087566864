import {applyMiddleware, compose, createStore} from "redux";
import { persistStore, persistReducer,createMigrate } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import migrations from "../utils/migrations";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler:autoMergeLevel2,
  migrate:createMigrate(migrations,{debug:true}),
  whitelist:[""]
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(thunk))
);

export const persistor = persistStore(store);