import React from "react";
import { Row, Col, Typography, Select, Input, Radio } from "antd";

import {
    DropDownIcon,
    EditPenIcon,
    Speed15sIcon,
    Speed30sIcon,
    Disk2Icon,
} from "../../../utils/Icons";

const { Text } = Typography;

const getPaceJSX = (paceValue) => (
    <Radio.Button value={paceValue}>
        <Text className="custom-playlist-table-radio-button">
            {paceValue == "15" ? <Speed15sIcon /> : <Speed30sIcon />}
            {paceValue == "15" ? "speed" : "standard"}
        </Text>
    </Radio.Button>
);

const getPaceOptions = (isEditMode, paceValue) => {
    if (isEditMode) {
        return getPaceJSX(paceValue, paceValue == "15" ? "speed" : "standard");
    }
    return ["15", "30"].map((value) => getPaceJSX(value));
};

const PlaylistManagerHeader = ({
    playlistName,
    onChangePlaylistName,
    playlistDuration,
    onChangePlaylistDuration,
    isEditMode = false,
}) => {
    return (
        <Row
            justify="space-between"
            className="playlist-manager-header-container"
        >
            <Col>
                <Row>
                    <Radio.Group
                        defaultValue="all"
                        optionType="button"
                        buttonStyle="outline"
                        onChange={(event) =>
                            onChangePlaylistDuration(event.target.value)
                        }
                        value={playlistDuration}
                        className="custom-playlist-table-radio-group"
                    >
                        {getPaceOptions(isEditMode, playlistDuration)}
                    </Radio.Group>
                </Row>
            </Col>
            <Col span={12}>
                <Input
                    className="playlist-search-input"
                    placeholder="Playlist Name"
                    value={playlistName}
                    onChange={(event) =>
                        onChangePlaylistName(event.target.value)
                    }
                    prefix={<EditPenIcon style={{ fill: "#999999" }} />}
                />
            </Col>
            <Col span={6}>
                <Select
                    suffixIcon={<DropDownIcon />}
                    className="host-custom-drop-down playlist-select-user-drop-down"
                    style={{ width: "100%", height: "100%" }}
                    placeholder={
                        <Row justify="space-between" className="center-items">
                            <Col span={3}>
                                {" "}
                                <Disk2Icon className="host-custom-drop-down-icon" />
                            </Col>
                            <Col span={20}>
                                <Text className="host-custom-drop-down-text">
                                    User Level
                                </Text>
                            </Col>
                        </Row>
                    }
                >
                    {[
                        { label: "FREE", value: "free" },
                        { label: "ADMIN", value: "admin" },
                    ].map(({ label, value }, key) => (
                        <Select.Option value={value} key={key}>
                            <Row
                                justify="space-between"
                                align="middle"
                                className="center-items"
                            >
                                <Col span={3}>
                                    <Disk2Icon className="host-custom-drop-down-icon-active" />
                                </Col>
                                <Col span={20}>
                                    <Text className="host-custom-drop-down-text">
                                        {label}
                                    </Text>
                                </Col>
                            </Row>
                        </Select.Option>
                    ))}
                </Select>
            </Col>
        </Row>
    );
};

export default PlaylistManagerHeader;
