import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AppSharedLayout from "../components/common/AppSharedLayout";
import AuthService from "../services/AuthService";

const GuestRoute = (props) => {
    let navigate = useNavigate();
    const {
        component: Component,
        isJamDeckRoute,
        playerRoute = false,
        ...rest
    } = props;

    if (window.location.pathname === "/") {
        window.location = 'https://www.playjindo.com';
        return null;
    }

    const handleAppClose = () => {
        window.location = "/";
    };
    // if (user) return <Navigate to='/userHome' />;
    return (
        <AppSharedLayout
            isJamDeckRoute={isJamDeckRoute}
            playerRoute={playerRoute}
            onAppClose={handleAppClose}
        >
            <Component {...rest} navigate={navigate} />
        </AppSharedLayout>
    );
};

export default GuestRoute;
