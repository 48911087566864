import React from "react";
import {Row, Col, Typography} from 'antd';

import PlayedSongComponent from "../../../components/host/jamDeck/PlayedSongs";

const {Text} = Typography;

const PlayedSongsContainer = ({activeGame}) => {

    return (
        <Row justify="center">
            <Col span={24} style={{marginTop: 34}}>
                <Row justify="center">
                    <Text className="song-played-count">
                        SONGS PLAYED  =  {`${activeGame?.playedSongs?.playedSongs.length}/${activeGame?.numberOfSongs}`}
                        </Text>
                </Row>
            </Col>
            <Col span={20}>
                <PlayedSongComponent playedSongs={activeGame?.playedSongs?.playedSongs} />
            </Col>
        </Row>
        
    );
};

export default PlayedSongsContainer;
