export const isReadOnly = (providerType, isHost) => {
    if (providerType === 'jam') {
      return isHost ? false : true
    } else {
      return isHost ? true : false
    }
}

export const isProviderTypeAvailable = (providers, type) => {
  for (const providerName in providers) {
    const existingType = providerName.split('.')[0];
    if (existingType === type) {
      return true;
    }
  }
  return false;
}

export const isProviderNameMatching = (providers, name) => {
  return providers.hasOwnProperty(name);
}