import React, { useEffect } from "react";

import usePlayer from "../../../hooks/usePlayer";
import PlayerService from "../../../services/PlayerService";

const CreatePlayerGameContainer = ({ game, playerId }) => {
    const {player, updateCardLayout} = usePlayer(game.gameId);

	useEffect(() => {
        
		if(player && player?.cardLayout?.length === 0) {
			createPlayer();
		}
	}, [player]);

	const createPlayer = () => {
		PlayerService.getPlayerGame(playerId, game)
		.then((response) => {
			const {cardLayout} = response.data;
			updateCardLayout(cardLayout)
		})
	}

    return <></>;
};

export default CreatePlayerGameContainer;
