import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";

import PlaylistTable from "../../../components/admin/playlist/PlaylistTable";

import { deletePlaylist, fetchPlaylists } from "../../../redux/actions/playlist";

import WarningModal from "../../../components/modals/WarningModal";

const makePlaylitsTableData = (playlists) =>
    playlists.map((playlist) => {
        playlist.key = playlist.playlistId;
        return playlist;
    });

const PlaylistTableContainer = ({ searchPlaylistTerm, onEditPlaylist,onFilterCountChange }) => {
    const dispatch = useDispatch();
    const [playlistDuration, setPlaylistDuration] = useState(null);
    const [deletedPlaylistId, setDeletedPlaylistId] = useState(null);

    const { playlists, isLoading } = useSelector((state) => state.playlist);

    useEffect(() => {
        dispatch(fetchPlaylists());
    }, []);

    const handlePlaylistDurationChange = (e) => {
        setPlaylistDuration(e.target.value);
    };

    const onDeletePlaylist = (playlistId) => {
        setDeletedPlaylistId(playlistId);
    };

    const handleDeletePlaylist = () => {
        new Promise((resolve) =>
            resolve(dispatch(deletePlaylist(deletedPlaylistId)))
        ).then(() => setDeletedPlaylistId(null));
    };

    const filteredPlaylists = (
        playlists,
        searchPlaylistTerm = "",
        duration
    ) => {
        const filteredPlaylists = playlists.filter(
            (playlist) =>
                playlist.name
                    .toLowerCase()
                    .includes(searchPlaylistTerm.toLowerCase()) &&
                (playlist.speed === parseInt(duration) || !duration)
        );
        onFilterCountChange(filteredPlaylists.length)
        return filteredPlaylists;
    };

    return (
        <Row justify="center">
            <WarningModal
                onCancel={() => setDeletedPlaylistId(null)}
                onDelete={handleDeletePlaylist}
                visible={deletedPlaylistId}
            />

            <Col span={24}>
                <PlaylistTable
                    handlePlaylistDurationChange={handlePlaylistDurationChange}
                    playlistDuration={playlistDuration}
                    playlists={makePlaylitsTableData(
                        filteredPlaylists(
                            playlists,
                            searchPlaylistTerm,
                            playlistDuration
                        )
                    )}
                    isLoading={isLoading}
                    onDeletePlaylist={onDeletePlaylist}
                    onEditPlaylist={onEditPlaylist}
                    isAdmin={true}
                />
            </Col>
        </Row>
    );
};

export default PlaylistTableContainer;
