import React from "react";
import { Row, Col, Typography, Input } from "antd";

import SongsUploader from "./SongsUploader";

import ActionButton from "../../common/ActionButton";

import {
    DiskIcon,
    PlayJamIconSVG,
    ArrowIconSVG,
    SearchIcon,
} from "../../../utils/Icons";

const { Text } = Typography;

const PlaylistManagerSideBar = ({
    onAddSong,
    searchPlaylistTerm,
    numberOfSongs,
    onChangeSearchPlaylistTerm,
    onCreatePlaylist,
    playlistSaveLoading
}) => {
    return (
        <Row
            justify="center"
            align="top"
            gutter={[0, 18]}
            className="playlist-table-sidebar-container"
        >
            <Col span={24}>
                <Row justify="center" align="top" gutter={[40, 40]}>
                    <Col>
                    </Col>
                    <Col
                        span={18}
                        className="center-items playlist-manager-title-container"
                    >
                        <Text className="playlist-manager-title">
                            Playlist Manager
                        </Text>
                    </Col>
                </Row>
            </Col>

            <Col span={19} className="songs-uploader-container">
                <SongsUploader addSong={onAddSong} />
            </Col>
            <Col span={10} className="center-items">
                <Row className="songs-count-container">
                    <Col span={12} className="songs-count-icon-container">
                        <DiskIcon className="songs-count-icon" />
                    </Col>
                    <Col span={12} className="songs-count-text-container">
                        <Text className="songs-count-text">
                            {numberOfSongs}
                        </Text>
                    </Col>
                </Row>
            </Col>
            <Col span={18}>
                <Input
                    className="playlist-search-input"
                    placeholder="Search"
                    value={searchPlaylistTerm}
                    onChange={(event) =>
                        onChangeSearchPlaylistTerm(event.target.value)
                    }
                    prefix={<SearchIcon style={{ fill: "#606060" }} />}
                />
            </Col>
            <Col span={18}>
                <ActionButton
                    title="save"
                    className="high-priority-btn"
                    onClick={onCreatePlaylist}
                    prefixIconSVG={PlayJamIconSVG}
                    postfixIconSVG={ArrowIconSVG}
                    isLoading={playlistSaveLoading}
                />
            </Col>
        </Row>
    );
};

export default PlaylistManagerSideBar;
