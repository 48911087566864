import React from "react";
import { Col, Row, Typography, Slider, Select, Button } from "antd";

const { Text } = Typography;
import {
    SettingMusicIcon,
    MinusIcon,
    PlusIcon
} from "../../../utils/Icons";

const Setting = ({ speakerList, handleSpeakerChange, speaker, speakerVolume, setSpeakerVolume, handleVolumeUp, handleVolumeDown}) => {
    return (
        <Row className='jam-deck-input-container' style={{margin: '45px 40px', border: 'none' }} justify="center">
            <Col span={24} style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                <SettingMusicIcon style={{width: 103.22, height: 105.78, position: 'absolute'}} />
                <Text className="settings-item-text-heading" style={{marginBottom: 17, marginTop: 54}}>Music Volume</Text>
            </Col>
            <Col span={24} style={{border: '1px solid #ffffff', borderRadius: 5}}>
                <Select
                    className='jam-deck-select'
                    placeholder='Select Audio Output'
                    onChange={handleSpeakerChange}
                    value={speaker}
                >
                    {speakerList.map((speaker) => (
                        <Select.Option
                            key={speaker.deviceId}
                            value={speaker.deviceId}
                        >
                            {speaker.label}
                        </Select.Option>
                    ))}
                </Select>
            </Col>
            <Col span={24} style={{display: 'flex',justifyContent: 'center',alignItems: 'center', marginTop: 20}}>
                <Button style={{paddingTop: 0, paddingBottom: 8}} type="text" onClick={handleVolumeDown}>
                    <MinusIcon style={{fontSize: 6}} />
                </Button>
                <Slider
                    min={0}
                    className='jam-settings-slider'
                    tooltip={{open: false}}
                    value={speakerVolume}
                    max={1}
                    step={0.1}
                    onChange={setSpeakerVolume}
                />
                <Button style={{paddingTop: 0, paddingBottom: 8}} type="text" onClick={handleVolumeUp}>
                    <PlusIcon style={{fontSize: 6}} />
                </Button>
            </Col>
        </Row>
    );
};

export default Setting;
