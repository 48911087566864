import { Col, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UsersManager from "../../components/admin/UsersManager";
import WarningModal from "../../components/modals/WarningModal";
import {
    fetchUser,
    updateUserById,
    deleteUserById,
} from "../../redux/actions/user";

import { fetchUsers } from "../../redux/actions/users";

const UserManagementContainer = () => {
    const dispatch = useDispatch();

    const { users } = useSelector((state) => state.users);

    const [userAdminId, setUserAdminId] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [activateHostId, setActivateHostId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { userData } = useSelector((state) => state.user);

    console.log(userData);

    useEffect(() => {
        dispatch(fetchUser());
        dispatch(fetchUsers());
    }, []);

    const onChangeHost = (userId) => {
        setUserAdminId(userId);
    };

    const onDeleteHost = (userId) => {
        setDeleteUserId(userId);
    };

    const onActivateHost = (userId) => {
        setActivateHostId(userId);
    };

    const handleDeleteHost = () => {
        setIsLoading(true);
        new Promise((resolve) =>
            resolve(dispatch(deleteUserById(deleteUserId)))
        ).then(() => {
            new Promise((resolve) => {
                resolve(dispatch(fetchUsers()));
            }).then(() => {
                setDeleteUserId(null);
                setIsLoading(false);
            });
        });
    };

    const handleActivateHost = () => {
        const user = users.filter((user) => user.userId === activateHostId)[0];
        setIsLoading(true);
        new Promise((resolve) =>
            resolve(
                dispatch(
                    updateUserById({
                        ...user,
                        active: true,
                        deletedAt: null,
                    })
                )
            )
        ).then(() => {
            new Promise((resolve) => {
                resolve(dispatch(fetchUsers()));
            }).then(() => {
                setActivateHostId(null);
                setIsLoading(false);
            });
        });
    };

    const handleUpdateAdmin = () => {
        const user = users.filter((user) => user.userId === userAdminId)[0];
        setIsLoading(true);
        new Promise((resolve) =>
            resolve(
                dispatch(
                    updateUserById({
                        ...user,
                        isAdmin: !user.isAdmin,
                    })
                )
            )
        ).then(() => {
            new Promise((resolve) => {
                resolve(dispatch(fetchUsers()));
            }).then(() => {
                setUserAdminId(null);
                setIsLoading(false);
            });
        });
    };

    return (
        <Row>
            <WarningModal
                onCancel={() => setUserAdminId(null)}
                visible={userAdminId}
                onDelete={handleUpdateAdmin}
                isLoading={isLoading}
                okText={"confirm"}
            />
            <WarningModal
                onCancel={() => setDeleteUserId(null)}
                visible={deleteUserId}
                onDelete={handleDeleteHost}
                isLoading={isLoading}
                okText={"delete"}
            />
            <WarningModal
                onCancel={() => setActivateHostId(null)}
                visible={activateHostId}
                onDelete={handleActivateHost}
                isLoading={isLoading}
                okText={"activate"}
            />

            <Col span={24}>
                <UsersManager
                    users={users}
                    onChangeAdmin={onChangeHost}
                    onDeleteHost={onDeleteHost}
                    onActivateHost={onActivateHost}
                    isSuperUser={userData && userData.superUser}
                />
            </Col>
        </Row>
    );
};

export default UserManagementContainer;
