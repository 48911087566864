import { useEffect, useState } from "react"
import throttle from 'lodash.throttle';
import { useGameProvider } from "../context/collaborationContext"
import { useAwareness } from "../context/awarenessContext";

export default function usePlayers(gameId, isHost = false) {
    const [players, setPlayers] = useState([]) 
    const [activePlayersCount, _setActivePlayersCount] = useState(0)
    const { document } = useGameProvider(gameId)
    const connections = useAwareness();

    useEffect(() => {
      const docPlayers = document.getMap('players')
      
      const update = () => {
        const playersValues = Array.from(docPlayers.values()).filter(player => !player?.leave)
        // sync players state with the changes in document
        setPlayers(playersValues)
        
        // sync active players state with the changes to connected players in document
        _setActivePlayersCount(playersValues?.length || 0)
      }
      
      const throttled = throttle(update, 15000, {leading: true})
      // observe changes on the players map in document
      docPlayers.observe(throttled)

      return () => {
        docPlayers.unobserve(throttled)
        setPlayers([])
        _setActivePlayersCount(0)
      }
    }, [])

    useEffect(() => {
      if (connections) {
        const docPlayers = document.getMap('players')
        
        // update the connection status in players map on awareness change
        Array.from(docPlayers.values()).forEach((player) => {
          const connected = connections.some((connectedPlayer) => connectedPlayer.id === player.id)
          docPlayers.set(player.id, {...player, connected})   
        });
      }
    }, [connections, document]);

    return { players, activePlayersCount }
}