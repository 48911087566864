import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ConfirmCodeForm from "../../../components/auth/ConfirmCodeForm";
import ShowToast from "../../../utils/ShowToast";

import AuthService from "../../../services/AuthService";

import { successUserInfo } from "../../../redux/actions/user";
import { verifyUser, sendConfirmCode } from "../../../redux/actions/authUser";

const ConfirmCodeContainer = ({ navigate }) => {
    const [confirmCode, SetConfirmCode] = useState();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const email = searchParams.get("email");
    const phoneNumber = searchParams.get("phoneNumber");

    const { error, userData } = useSelector((state) => state.user);

    useEffect(() => {
        if (!userData) {
            dispatch(successUserInfo(AuthService.getCurrentUser()));
        }
    }, []);

    useEffect(() => {
        if (error) {
            ShowToast(error, "error");
        }
        if (userData && userData.verified === "true") {
            navigate("/userHome");
        }
    }, [error, userData]);

    const handleConfirmCodeInput = (code) => {
        SetConfirmCode(code);
    };

    const handleSubmitCode = () => {
        let e = email.replace(/ /g, '+');
        dispatch(
            verifyUser({
                authCode: confirmCode,
                email: e,
                phoneNumber,
            })
        );
    };

    const handleResendCode = () => {
        if (email && phoneNumber) {
            let e = email.replace(/ /g, '+');
            new Promise((resolve, reject) => {
                resolve(
                    dispatch(
                        sendConfirmCode({
                            email: e,
                            phoneNumber,
                        })
                    )
                );
                reject(new Error("code error"));
            })
                .then(() => {
                    ShowToast("Code Sent!", "success");
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <Row justify='center' align='top' className='action-container-wrapper'>
            <Col span={20} style={{ display: "flex", marginTop: 55 }}>
                <ConfirmCodeForm
                    onCodeInput={handleConfirmCodeInput}
                    onCodeSubmit={handleSubmitCode}
                    handleResendCode={handleResendCode}
                />
            </Col>
        </Row>
    );
};

export default ConfirmCodeContainer;
