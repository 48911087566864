import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Form,
    Switch,
    Row,
    Col,
    Typography,
    Select,
    Slider,
    Input,
    Button,
} from "antd";
import JamPattern from "./JamPattern";
import ShowToast from "../../../utils/ShowToast";

import { fetchPlaylists } from "../../../redux/actions/playlist";
import { fetchPatterns } from "../../../redux/actions/patterns";

import {
    Speed15sIcon,
    Speed30sIcon,
    PrizePinkIcon,
    MusicBookIcon,
    TimeIcon,
    PlusIcon,
    MinusIcon,
    PlaylistBlackIcon,
    PrizeBlackIcon,
} from "../../../utils/Icons";
import { getEstimatedGameTime } from "../../../utils/JamCreateUtils";

import useRoundPace from "../../../hooks/useRoundPace";
import useRoundDisplaySong from "../../../hooks/useRoundDisplaySong";
import useRoundNumberOfSongs from "../../../hooks/useRoundNumberOfSongs";
import useRoundPrize from "../../../hooks/useRoundPrize";
import useRoundWinningPattern from "../../../hooks/useRoundWinningPattern";
import useRoundPlaylistId from '../../../hooks/useRoundPlaylistId';
import useRoundPatternId from '../../../hooks/useRoundPatternId';

const { Text } = Typography;
const patterns = [
    "Single",
    "Double",
    "Postage Stamp",
    "4 Corners",
    "X",
    "Blackout",
];

const getPlaylists = (playlists, pace) => {
    return playlists
        .filter((item) => item.speed === pace)
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })
        .map((playlist) => (
            <Select.Option
                key={playlist.playlistId}
                value={playlist.playlistId}
            >
                {playlist.name}
            </Select.Option>
        ));
};

const GameForm = ({
    index,
    game,
    update,
    jamTime,
    tabActive,
    handleEditableRound,
    editableRound,
    activeGame,
}) => {
    const dispatch = useDispatch();
    const { playlists } = useSelector((state) => state.playlist);
    const { patterns: allPatterns } = useSelector((state) => state.patterns);

    const [displayNumOfSongTooltip, setDisplayNumOfSongTooltip] =
        useState(true);
            
    /** TODO: Replace index/key with UUIDs to have consistent ids across multiple documents */
    const {pace, setPace} = useRoundPace(index.toString())
    const {patternName, setPatternName} = useRoundWinningPattern(index.toString())
    const {patternId, setPatternId} = useRoundPatternId(index.toString())
    const {playlistId, setPlaylistId} = useRoundPlaylistId(index.toString())
    const {prize, setPrize} = useRoundPrize(index.toString())
    const {displaySong, setDisplaySong} = useRoundDisplaySong(index.toString())
    const {numberOfSongs, setNumberOfSongs} = useRoundNumberOfSongs(index.toString())

    const [maxPlaylistSongs, setMaxPlaylistSongs] = useState(75);
    const [gameTime, setGameTime] = useState(0);
    const [fieldUpdated, setFieldUpdated] = useState(false);


    const { key, gameId } = game || { key: "", gameId: "" };
    useEffect(() => {
        dispatch(fetchPlaylists());
        dispatch(fetchPatterns());
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [displayNumOfSongTooltip]);

    const handleScroll = (event) => {
        const el = document.getElementById("numberOfSongsColumnId");
        let bounding = el.getBoundingClientRect();
        const inViewPort =
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <=
                (window.innerHeight - 95 ||
                    document.documentElement.clientHeight) &&
            bounding.right <=
                (window.innerWidth || document.documentElement.clientWidth);
        if (inViewPort != displayNumOfSongTooltip) {
            setDisplayNumOfSongTooltip(inViewPort);
        }
    };

    useEffect(() => {
        if (fieldUpdated) {
            updateParent();
            setFieldUpdated(false);
        }
    }, [fieldUpdated]);

    const handlePatternChange = (value) => {
        setPatternName(value.name);
        setGameTime(getEstimatedGameTime(pace, value.name));
        setPatternId(getPatternId(value.name));
        setFieldUpdated(true);
    };

    const getPatternId = (value) => {
        const filterPatterns = allPatterns.filter(
            (pattern) => pattern.name === value
        );
        return filterPatterns[0]?.patternId;
    };

    const handlePaceChange = () => {
        setPace(pace === 15 ? 30 : 15);
        setGameTime(getEstimatedGameTime(pace === 15 ? 30 : 15, patternName));
        setPlaylistId(undefined);
        setFieldUpdated(true);
    };
    const handlePaceIconClick = (val) => {
        setPace(val);
        setGameTime(getEstimatedGameTime(val, patternName));
        val !== pace && setPlaylistId(undefined);
        setFieldUpdated(true);
    };

    const handlePlaylistChange = (value) => {
        const totalSongs = playlists.filter(
            (playlist) => playlist.playlistId == value
        );
        setMaxPlaylistSongs(totalSongs[0].totalSongs);
        setPlaylistId(value);
        setNumberOfSongs(24);
        setFieldUpdated(true);
    };

    const handleDisplaySongChange = () => {
        setDisplaySong(!displaySong);
        setFieldUpdated(true);
    };
    const handleDisplaySongClick = (val) => {
        setDisplaySong(val);
        setFieldUpdated(true);
    };

    const handleNumberOfSongsChange = (value) => {
        setNumberOfSongs(value);
        setFieldUpdated(true);
    };

    const handlePrizeChange = (e) => {
        setPrize(e.target.value);
        setFieldUpdated(true);
    };

    const validateForm = () => {
        if (!playlistId) {
            ShowToast("Playlist Missing", "error", null, 1);
            return false;
        }
        return true;
    };

    const updateParent = () => {
        update({
            key,
            pace,
            patternName,
            playlistId,
            prize,
            displaySong,
            numberOfSongs,
            gameTime,
            gameId,
            patternId,
        });
    };

    const isEditAbleGame = () => {
        if (gameId === activeGame?.gameId) {
            return false;
        }
        if (editableRound) {
            return game.status === "STARTING_SOON";
        }
        return false;
    };

    if (!game) return null;
    return (
        Object.keys(game).length > 0 && (
            <Form className='jam-create-form' key={index}>
                <Row justify='center'>
                    <Col
                        span={11}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Text className='jam-create-form-helper-text'>
                            {gameId && !isEditAbleGame()
                                ? "Current pace of your tracks:"
                                : "How much of each song do you want to hear?"}
                        </Text>
                        {gameId && !isEditAbleGame() ? (
                            <Row
                                justify='center'
                                align='middle'
                                style={{
                                    width: 130,
                                }}
                            >
                                <Col span={8}>
                                    {pace === 15 ? (
                                        <Speed15sIcon
                                            style={{
                                                width: 45,
                                                height: 45,
                                            }}
                                            className={"jam-pace-icon-inactive"}
                                        />
                                    ) : (
                                        <Speed30sIcon
                                            style={{
                                                width: 45,
                                                height: 45,
                                            }}
                                            className={"jam-pace-icon-inactive"}
                                        />
                                    )}
                                </Col>
                            </Row>
                        ) : (
                            <Row
                                justify='space-between'
                                align='middle'
                                style={{
                                    width: 130,
                                }}
                            >
                                <Col span={8}>
                                    <Speed15sIcon
                                        style={{
                                            width: pace === 15 ? 45 : 30,
                                            height: pace === 15 ? 45 : 30,
                                            cursor: "pointer",
                                        }}
                                        className={
                                            pace === 15
                                                ? "jam-pace-icon"
                                                : "jam-pace-icon-inactive"
                                        }
                                        onClick={() => handlePaceIconClick(15)}
                                    />
                                </Col>
                                <Col span={7} style={{ padding: "0 2px" }}>
                                    <Switch
                                        onChange={handlePaceChange}
                                        className={
                                            pace
                                                ? "jam-create-form-switch"
                                                : "jam-create-form-switch-inactive"
                                        }
                                        checked={pace == 30 ? true : false}
                                        style={{ minWidth: 35 }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Speed30sIcon
                                        style={{
                                            width: pace === 30 ? 45 : 30,
                                            height: pace === 30 ? 45 : 30,
                                            cursor: "pointer",
                                        }}
                                        className={
                                            pace === 30
                                                ? "jam-pace-icon"
                                                : "jam-pace-icon-inactive"
                                        }
                                        onClick={() => handlePaceIconClick(30)}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Col>
                    <Col
                        span={11}
                        offset={1}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Text className='jam-create-form-helper-text'>
                            {gameId && !isEditAbleGame()
                                ? "Winning pattern:"
                                : "What pattern does a player need to win?"}
                        </Text>
                        <JamPattern
                            handlePatternChange={handlePatternChange}
                            patterns={patterns}
                            activePattern={patternName}
                            gameId={gameId && !isEditAbleGame()}
                        />
                    </Col>
                    {gameId && !isEditAbleGame() ? (
                        <Col span={24} style={{ margin: "21px 0" }}>
                            <Row align='middle' justify='center'>
                                <PlaylistBlackIcon
                                    style={{ width: 15, height: 17 }}
                                />
                                <Text className={"selected-playlist-text"}>
                                    {game?.playlist?.name}
                                </Text>
                            </Row>
                        </Col>
                    ) : (
                        <Col span={24}>
                            <Row
                                className='jam-deck-input-container'
                                align='middle'
                                justify='space-between'
                            >
                                <Col
                                    style={{
                                        textAlign: "center",
                                    }}
                                    span={3}
                                >
                                    <MusicBookIcon className='jam-deck-input-prefix' />
                                </Col>
                                <Col span={21}>
                                    <Select
                                        onChange={handlePlaylistChange}
                                        value={playlistId}
                                        className='jam-deck-select'
                                        placeholder='Select A Playlist'
                                        onFocus={() => {
                                            if (!pace) {
                                                ShowToast(
                                                    "Pace Missing",
                                                    "error",
                                                    null,
                                                    1
                                                );
                                            }
                                        }}
                                    >
                                        {getPlaylists(playlists, pace)}
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    <Col
                        span={10}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Text className='jam-create-form-helper-text'>
                            {gameId && !isEditAbleGame()
                                ? "Song Titles Are Set To:"
                                : "Display or Hide Songs for Players"}
                        </Text>
                        <Row
                            justify='center'
                            align='middle'
                            style={{
                                minWidth: 130,
                                height: 125,
                            }}
                        >
                            <Col>
                                <Text
                                    className={
                                        displaySong === true
                                            ? "jam-pace-icon"
                                            : "jam-pace-icon-inactive"
                                    }
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDisplaySongClick(true)}
                                >
                                    Display
                                </Text>
                            </Col>
                            <Col
                                span={24}
                                style={{
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                            >
                                <Switch
                                    onChange={handleDisplaySongChange}
                                    checked={displaySong}
                                    disabled={gameId && !isEditAbleGame()}
                                    className='jam-create-form-switch'
                                    style={{
                                        transform: "rotate(-90deg)",
                                    }}
                                />
                            </Col>
                            <Col>
                                <Text
                                    className={
                                        displaySong === false
                                            ? "jam-pace-icon"
                                            : "jam-pace-icon-inactive"
                                    }
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        handleDisplaySongClick(false)
                                    }
                                >
                                    hide
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        span={12}
                        offset={1}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Text className='jam-create-form-helper-text'>
                            {gameId && !isEditAbleGame()
                                ? "the number of songs to play from your playlist:"
                                : "set the number of songs to play from your playlist:"}
                        </Text>
                        <Row
                            id='numberOfSongsColumnId'
                            align='middle'
                            style={{
                                minWidth: 160,
                                height: 75,
                            }}
                            gutter={[2, 0]}
                            justify='space-between'
                        >
                            <Col>
                                <MinusIcon
                                    style={{ fontSize: 8, cursor: "pointer" }}
                                    onClick={() => {
                                        if ((!gameId || isEditAbleGame()) && numberOfSongs - 1 >= 24) {
                                            handleNumberOfSongsChange(
                                                numberOfSongs - 1
                                            );
                                        }
                                    }}
                                />
                            </Col>
                            <Col span={21}>
                                <Slider
                                    onChange={handleNumberOfSongsChange}
                                    value={numberOfSongs}
                                    min={24}
                                    className='jam-songs-slider'
                                    tooltip={{
                                        open: tabActive && displayNumOfSongTooltip,
                                        placement: 'bottom'
                                    }}
                                    
                                    max={maxPlaylistSongs}
                                    disabled={gameId && !isEditAbleGame()}
                                />
                            </Col>
                            <Col>
                                <PlusIcon
                                    style={{ fontSize: 8, cursor: "pointer" }}
                                    onClick={() => {
                                        if (
                                            (!gameId || isEditAbleGame()) &&
                                            numberOfSongs + 1 <=
                                            maxPlaylistSongs
                                        ) {
                                            handleNumberOfSongsChange(
                                                numberOfSongs + 1
                                            );
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    {gameId && !isEditAbleGame() ? (
                        <Col span={24} style={{ margin: "21px 0" }}>
                            <Row align='middle' justify='center'>
                                <PrizePinkIcon
                                    style={{
                                        width: 15,
                                        height: 17,
                                        fill: "#606060",
                                    }}
                                />
                                <Text className={"selected-playlist-text"}>
                                    {game.prize}
                                </Text>
                            </Row>
                        </Col>
                    ) : (
                        <Col span={24}>
                            <Row
                                className='jam-deck-input-container'
                                align='middle'
                                justify='space-between'
                            >
                                <Col
                                    style={{
                                        textAlign: "center",
                                    }}
                                    span={3}
                                >
                                    <PrizePinkIcon className='jam-deck-input-prefix' />
                                </Col>
                                <Col span={21}>
                                    <Input
                                        style={{ paddingRight: "12%" }}
                                        onChange={handlePrizeChange}
                                        className='jam-deck-number'
                                        placeholder='Enter A Prize'
                                        value={prize}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    )}
                    <Col>
                        {gameId &&
                            !isEditAbleGame() &&
                            game.status === "STARTING_SOON" &&
                            gameId !== activeGame?.gameId && (
                                <Button
                                    className='high-priority-btn'
                                    onClick={() => handleEditableRound(true)}
                                >
                                    EDIT ROUND
                                </Button>
                            )}
                    </Col>
                    <Col span={24}>
                        <Text className='jam-deck-summary'>
                            RD{Number(key) + 1} = {getEstimatedGameTime(game?.pace === 15 ? 30 : 15, game?.patternName)}m{" "}
                            <TimeIcon fill='#ffffff' /> JAM ={" "}
                            {jamTime ? jamTime : 0}m
                        </Text>
                    </Col>
                </Row>
            </Form>
        )
    );
};

export default GameForm;
