import React from "react";

import { Link } from "react-router-dom";
import { Row, Col, Typography, Collapse, Image, Divider } from "antd";
import { JindoProfileLogo, BackArrowIcon } from "../../utils/Icons";
import UserPersonalForm from "./UserPersonalForm";
import UserUpdatePassword from "./UserUpdatePassword";
import UserUpdateLinks from "./UserUpdateLinks";
import UploadImageSplash from "../common/UploadImageSplash";
import moment from "moment";

const { Text } = Typography;
const { Panel } = Collapse;

const getFormatterDate = (date) => {
    if (date) {
        return moment(date).format("MM / DD / YYYY");
    }
    return "01 / 01/ 2000";
};

const UserProfileHome = ({
    onSaveUser,
    onPasswordChange,
    onChangeImage,
    displayImage,
    isLoading,
    userData,
    userPersonalForm,
    handleUpdateLinks,
}) => {
    return (
        <Row justify='center'>
            <Col className='user-home-header' span={24}>
                <Link to={"/userHome"}>
                    <Text className='profile-header-logout-text'>
                        <BackArrowIcon
                            style={{
                                fill: "#ffffff",
                                fontSize: 28,
                                fontWeight: 600,
                                marginRight: 5,
                            }}
                        />{" "}
                        GO BACK TO YOUR DASHBOARD
                    </Text>
                </Link>
            </Col>
            <Col style={{ position: "absolute", left: 0, top: "-8%" }}>
                <Link to='/userHome'>
                    <JindoProfileLogo style={{ width: 190, height: 175 }} />
                </Link>
            </Col>
            <Col span={24}>
                <Row
                    justify='center'
                    style={{ width: "100%", height: 147, marginTop: 42 }}
                >
                    <Col
                        style={{
                            width: 147,
                            height: 147,
                            borderRadius: 100,
                            background: "#FFFFFF",
                            border: "3px solid #fff",
                        }}
                    >
                        <UploadImageSplash
                            onCustomRequest={onChangeImage}
                            displayImage={displayImage}
                            isLoading={isLoading}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <Text className='profile-info-header-text'>
                    MANAGE YOUR PROFILE
                </Text>
            </Col>
            <Col span={22}>
                <Collapse
                    accordion
                    className='profile-accordion'
                    bordered={false}
                >
                    <Panel showArrow={false} header='ACCOUNT OVERVIEW' key='1'>
                        <Row>
                            <Col span={8}>
                                <Text className='profile-accordian-heading'>
                                    User Name
                                </Text>
                            </Col>
                            <Col>
                                <Text className='profile-accordian-content-text'>
                                    {userData?.userName}
                                </Text>
                            </Col>
                            <Col span={24}>
                                <Divider
                                    style={{
                                        margin: 0,
                                        borderColor: "#ffffff80",
                                    }}
                                />
                            </Col>

                            <Col span={8}>
                                <Text className='profile-accordian-heading'>
                                    Email
                                </Text>
                            </Col>
                            <Col>
                                <Text className='profile-accordian-content-text'>
                                    {userData?.email}
                                </Text>
                            </Col>
                            <Col span={24}>
                                <Divider
                                    style={{
                                        margin: 0,
                                        borderColor: "#ffffff80",
                                    }}
                                />
                            </Col>

                            <Col span={8}>
                                <Text className='profile-accordian-heading'>
                                    Date of Birth
                                </Text>
                            </Col>
                            <Col>
                                <Text className='profile-accordian-content-text'>
                                    {getFormatterDate(userData?.dob)}
                                </Text>
                            </Col>
                            <Col span={24}>
                                <Divider
                                    style={{
                                        margin: 0,
                                        borderColor: "#ffffff80",
                                    }}
                                />
                            </Col>

                            <Col span={8}>
                                <Text className='profile-accordian-heading'>
                                    Country/Region
                                </Text>
                            </Col>
                            <Col>
                                <Text className='profile-accordian-content-text'>
                                    {userData?.country}
                                </Text>
                            </Col>
                            <Col span={24}>
                                <Divider
                                    style={{
                                        margin: 0,
                                        borderColor: "#ffffff80",
                                    }}
                                />
                            </Col>

                            <Col span={8}>
                                <Text className='profile-accordian-heading'>
                                    City/State/ZIP
                                </Text>
                            </Col>
                            <Col>
                                <Text className='profile-accordian-content-text'>
                                    {userData?.city}, {userData?.state}{" "}
                                    {userData?.zipCode}
                                </Text>
                            </Col>
                        </Row>
                    </Panel>

                    <Panel showArrow={false} header='EDIT PROFILE INFO' key='2'>
                        <UserPersonalForm
                            userPersonalForm={userPersonalForm}
                            onSaveUser={onSaveUser}
                        />
                    </Panel>
                    <Panel showArrow={false} header='CHANGE PASSWORD' key='3'>
                        <UserUpdatePassword
                            onPasswordChange={onPasswordChange}
                        />
                    </Panel>
                    <Panel
                        showArrow={false}
                        header='UPDATE LINKS'
                        key='4'
                        className='profile-accordion-end-item'
                    >
                        <UserUpdateLinks
                            userData={userData}
                            handleUpdateLinks={handleUpdateLinks}
                        />
                    </Panel>
                </Collapse>
            </Col>
        </Row>
    );
};

export default UserProfileHome;
