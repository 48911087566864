import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

import AppSharedLayout from "../components/common/AppSharedLayout";
import AuthService from "../services/AuthService";

const AuthenticatedRoute = (props) => {
    const {
        component: Component,
        isJamDeckRoute,
        playerRoute = false,
        ...rest
    } = props;

    let navigate = useNavigate();

    let user = AuthService.getCurrentUser();
    let decodedUser = AuthService.getCurrentDecodeToken();

    const handleLogout = async () => {
        const status = await AuthService.logout();
        if (status === 200) {
            window.location = "/login";
        }
    };

    if (!decodedUser) return <Navigate to="/" />;
    return (
        <AppSharedLayout
            isJamDeckRoute={isJamDeckRoute}
            playerRoute={playerRoute}
            handleLogout={handleLogout}
            authenticated={user}
            authenticatedRoute={true}
        >
            <Component {...props} navigate={navigate} />
        </AppSharedLayout>
    );
};

export default AuthenticatedRoute;
