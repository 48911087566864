import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const AppSharedLayout = (props) => {
    const { handleLogout, playerRoute } = props;

    return (
        <Layout
            className={`layout-container ${playerRoute && "player-layout"}`}
        >
            <Content style={{ width: "100%" }}>
                {React.cloneElement(props.children, {
                    loggedIn: props.authenticated,
                    handleLogout,
                })}
            </Content>
        </Layout>
    );
};

export default AppSharedLayout;
