import { useEffect, useState } from "react";
import useRound from "./useRound";

export default function useRoundDisplaySong(roundId) {
    const [displaySong, _setDisplaySong] = useState(true)
    const { setFieldInRound, getFieldFromRound } = useRound(roundId)

    useEffect(() => {
      // sync displaySong with the value in rounds map in document
      _setDisplaySong(getFieldFromRound('displaySong'))
    }, [roundId])

    /**
     * Update displaySong boolean to given value
     * @param {boolean} value - New value for the displaySong
     */
    const setDisplaySong = (value) => {
      setFieldInRound('displaySong', value)
      _setDisplaySong(value)
    }

    return {displaySong, setDisplaySong}
}