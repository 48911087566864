import React from "react";
import { Row, Col, Typography, Button, Tooltip } from "antd";
import GameGrid from "./GameGrid";
import GameFooter from "./GameFooter";
import { JindoWhiteWithSplashIcon } from "../../../utils/Icons";
import TilePatternGrid from "../../common/TilePatternGrid";
import GameSongsList from "./GameSongsList";

const { Text } = Typography;

const handleEllipse = (title) => {
    let til = title;
    let LIMIT = 10;
    const ellipse =
        title?.length > LIMIT ? title?.slice(0, LIMIT) + "..." : title;
    return (
        <Tooltip placement='bottomRight' title={title?.length > LIMIT && til}>
            {ellipse}
        </Tooltip>
    );
};

const GameCountdown = (props) => {
    const {
        songsList,
        handleTileSelect,
        animate,
        songsPlayed,
        onJindoCall,
        isCallJindoDisabled,
        onCallJamEnd,
        playerData,
        game,
        jamTitle,
    } = props;
    return (
        <Row justify='center' align='top'>
            <Col span={24} className='game-play-header'>
                <Row justify='center' align='middle' style={{ width: 352 }}>
                    <Col>
                        <Text className='game-play-header-text'>
                            {playerData.name.split(' ')[0].length > 10 ? 
                            `${playerData.name.split(' ')[0].substring(0, 9)}...` : playerData.name.split(' ')[0]}
                        </Text>
                        <Text className='game-play-header-text'>
                            {handleEllipse(jamTitle)}
                        </Text>
                        <Text className='game-play-header-text'>
                            Rd {game.key + 1}
                        </Text>
                    </Col>
                    <Col style={{ textAlign: "center" }} span={12}>
                        <JindoWhiteWithSplashIcon
                            style={{ width: 180, height: 150 }}
                        />
                    </Col>
                    <Col span={5}>
                        <TilePatternGrid
                            pattern={game?.winningPattern?.name || "Single"}
                            color='#FFC930'
                            animatePatterns={game?.winningPattern?.pattern}
                        />
                    </Col>
                </Row>
            </Col>
            <Col>
                <Button
                    block
                    className='game-play-call-jindo-btn'
                    onClick={onJindoCall}
                    disabled={isCallJindoDisabled}
                >
                    CALL JINDO!
                </Button>
            </Col>
            <Col md={16} sm={16} xs={24} className='game-grid-container'>
                <GameGrid
                    songsList={songsList}
                    handleTileSelect={handleTileSelect}
                    animate={animate}
                />
            </Col>
            {game?.displaySong && (
                <Col>
                    <GameSongsList songs={songsPlayed} />
                </Col>
            )}
            <Col
                span={24}
                style={{ display: "flex", justifyContent: "center" }}
            >
                <GameFooter
                    currentSong={game?.playedSongs?.currentSong}
                    onCallJamEnd={onCallJamEnd}
                    displaySong={game?.displaySong}
                />
            </Col>
        </Row>
    );
};

export default GameCountdown;
