import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneNumberInput = (props) => {
    const { onChange, value, ...rest } = props;
    return (
        <PhoneInput
            country={"us"}
            value={value+''}
            enableSearch
            onChange={onChange}
            containerClass="custom-phonenumber-container"
            inputClass="custom-phonenumber-field"
            buttonClass="custom-phonenumber-button"
            placeholder="PHONE"
            {...rest}
        />
    );
};

export default PhoneNumberInput;
